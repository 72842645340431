import * as dayjs from 'dayjs';
import { Column } from 'primereact/column';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useFilters from '@app/hooks/useFilters';
import usePermissions from '@app/hooks/usePermissions';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { routeTo } from '@app/helpers/RoutesHelper';

import ROUTES from '@app/router/Routes';
import { fetchOrders } from '@app/store/smart-repair/order/order.thunk';

import { CalendarFilter } from '@app/partials/content/CalendarFilter';
import DropdownFilter from '@app/partials/content/DropdownFilter';
import FiltersProvider from '@app/partials/content/FiltersProvider';
import HoCDataTable from '@app/partials/content/HoCDataTable';
import InputFilter from '@app/partials/content/InputFilter';
import Label from '@app/partials/content/Label';
import RefreshTable from '@app/partials/content/RefreshTable';
import SearchInputFilter from '@app/partials/content/SearchInputFilter';
import { Filters } from '@app/partials/layout/Filters';

import { getStateBySlug } from './Helpers/Status';

const OrderTable = ({ type }) => {
    const { hasPermission } = usePermissions();
    const { orders, totalRecords, filtersContent, loading } = useSelector((state) => state.smartRepair.order);
    const { items, start, filters, sortField, sortOrder } = useFilters({
        name: `smartrepair_orders_${type}`,
    });
    const { handleFilter, handlePagination, handleSort, refresh } = useTableHandlers({
        filterStore: `smartrepair_orders_${type}`,
        fetchAction: fetchOrders,
        paramsInterceptor: (params, filtersStore) => ({
            ...params,
            start: filtersStore.start,
            items: filtersStore.items,
            orderAppointmentStatus: params.orderAppointmentStatus,
            orderAppointmentType: type,
        }),
    });

    const selectStatusDropdown = () => (
        <DropdownFilter
            optionLabel="name"
            filterBy="key"
            name="orderAppointmentStatus"
            options={
                filtersContent?.orderAppointmentStatus &&
                Object.values(filtersContent.orderAppointmentStatus).map((elm) => ({
                    name: elm.name,
                    key: elm.slug,
                }))
            }
            onChange={handleFilter}
        />
    );

    const selectDealershipDropdown = () => (
        <DropdownFilter
            optionLabel="name"
            filterBy="key"
            name="dealership[refDealership]"
            options={
                filtersContent?.listDealership &&
                Object.values(filtersContent.listDealership).map((elm) => ({
                    name: elm.refDealershipUsualname,
                    key: elm.refDealershipId,
                }))
            }
            onChange={handleFilter}
        />
    );

    const inputFilter = (name) => <InputFilter name={name} onChange={handleFilter} />;

    const calendarFilter = (name) => <CalendarFilter name={name} onChange={handleFilter} />;

    const vehicleBodyTemplate = (order) => (
        <>
            {order.make} {order.model}
        </>
    );

    const totalBodyTemplate = (order) => (
        <NumberFormat
            value={order.totalAmount}
            thousandSeparator=" "
            decimalSeparator=","
            displayType="text"
            suffix=" €"
        />
    );

    const dealershipBodyTemplate = (order) => {
        const title = order?.dealership?.name || null;
        return (
            <>
                <span className={`${title && 'mr-3'}`}>{title}</span>
                {order?.dealership?.sendToCall && (
                    <Label variant="primary">
                        <i className="mr-2 fas fa-headphones" />
                        Call
                    </Label>
                )}
            </>
        );
    };

    const statusBodyTemplate = (order) => (
        <Label variant={getStateBySlug(order)}>
            {order?.orderAppointment?.orderAppointmentStatus?.name || order?.orderStatus?.name}
        </Label>
    );

    const appointmentBodyTemplate = (order) =>
        order?.appointmentDate ? dayjs(order.appointmentDate).format('DD/MM/YYYY [à] HH [h] mm') : '';

    const createdBodyTemplate = (order) => dayjs(order.created).format('DD/MM/YYYY [à] HH [h] mm');

    const detailsBodyTemplate = (order) => (
        <Link
            to={routeTo(ROUTES.SMARTREPAIR_ORDER.PATH, {
                id: order.id,
            })}
        >
            <Button className="p-2 px-3" variant="secondary">
                Voir
            </Button>
        </Link>
    );

    const cols = [
        {
            field: 'reference',
            header: Intl.formatMessage({ id: 'SR.ORDER.TABLE.HEAD.REFERENCE' }),
            style: { width: '8%' },
            filter: true,
            filterElement: inputFilter('reference'),
        },
        {
            field: 'customer',
            header: Intl.formatMessage({ id: 'SR.ORDER.TABLE.HEAD.CUSTOMER' }),
            style: { width: '10%' },
            filter: true,
            filterElement: inputFilter('customer[name]'),
            body: (order) => `${order?.customer?.firstName || ''} ${order?.customer?.lastName || ''}`,
        },
        {
            field: 'vehicleName',
            header: Intl.formatMessage({ id: 'SR.ORDER.TABLE.HEAD.VEHICLE' }),
            style: { width: '12%' },
            filter: true,
            filterElement: inputFilter('make_model'),
            body: vehicleBodyTemplate,
        },
        {
            field: 'total',
            header: Intl.formatMessage({ id: 'SR.ORDER.TABLE.HEAD.TOTAL' }),
            style: { width: '8%' },
            body: totalBodyTemplate,
        },
        {
            field: 'dealership',
            header: Intl.formatMessage({ id: 'SR.ORDER.TABLE.HEAD.DEALERSHIP' }),
            style: { width: '15%' },
            filter: true,
            filterElement: selectDealershipDropdown('dealership'),
            body: dealershipBodyTemplate,
        },
        {
            field: 'status',
            header: Intl.formatMessage({ id: 'SR.ORDER.TABLE.HEAD.STATUS' }),
            style: { width: '12%' },
            filter: true,
            filterElement: selectStatusDropdown(),
            body: statusBodyTemplate,
        },
        {
            field: 'appointment_date',
            header: Intl.formatMessage({ id: 'SR.ORDER.TABLE.HEAD.APPOINTMENT' }),
            style: { width: '10%' },
            filter: true,
            filterElement: calendarFilter('appointmentDate'),
            body: appointmentBodyTemplate,
        },
        {
            field: 'order_date',
            header: Intl.formatMessage({ id: 'SR.ORDER.TABLE.HEAD.ORDER' }),
            style: { width: '10%' },
            filter: true,
            filterElement: calendarFilter('created'),
            body: createdBodyTemplate,
        },
    ];

    if (hasPermission('SR_DETAILS_ORDER')) {
        cols.push({
            field: 'details',
            header: Intl.formatMessage({ id: 'SR.ORDER.TABLE.HEAD.DETAILS' }),
            style: { width: '5%' },
            filter: false,
            body: detailsBodyTemplate,
        });
    }

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <FiltersProvider value={filters}>
            <Filters>
                <Row>
                    <RefreshTable count={totalRecords[type]} onClick={refresh} />
                    <Col lg={5} offset={2}>
                        <SearchInputFilter
                            onChange={handleFilter}
                            name="q"
                            placeholder="Client, référence, véhicule…"
                        />
                    </Col>
                </Row>
            </Filters>
            <HoCDataTable
                value={orders}
                totalRecords={totalRecords[type]}
                lazy
                loading={loading}
                paginator
                onFilter={handleFilter}
                onPage={handlePagination}
                onSort={handleSort}
                paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                rows={items}
                first={start}
                sortField={sortField}
                sortOrder={sortOrder}
                removableSort
                filterDisplay="row"
                rowsPerPageOptions={[25, 50, 100]}
                emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
            >
                {dynamicColumns}
            </HoCDataTable>
        </FiltersProvider>
    );
};

export default OrderTable;
