import { FileUpload } from 'primereact/fileupload';
import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { FormattedHTMLMessage, useIntl } from 'react-intl';

import toast from '../Toast';

import ModalDefault from './Modal.default';

const ImportCsv = ({
    showModal,
    setShowModal,
    uploadDest,
    title,
    filename,
    prefix,
    description,
    successMessage,
    failedMessage,
    maxFileSize,
}) => {
    const Intl = useIntl();
    const [fileName, setFileName] = useState();

    const chooseOptions = {
        className: 'ml-2 btn btn-primary',
        label: Intl.formatMessage({ id: 'TRANSLATOR.CHOOSE' }),
    };
    const uploadOptions = {
        className: 'ml-2 btn btn-success',
        label: Intl.formatMessage({ id: 'TRANSLATOR.IMPORT.FILES' }),
    };
    const cancelOptions = {
        className: 'ml2 btn btn-danger d-none',
        label: Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' }),
    };

    const sendUpload = (params) => {
        uploadDest(params)
            .then((response) => {
                if (response?.result) {
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: successMessage }),
                        duration: 8000,
                    });
                    setShowModal(false);
                } else {
                    console.log(response);
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: failedMessage }),
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: failedMessage }),
                });
            });
    };

    const customUploadHandler = (event) => {
        for (const file of event.files) {
            const reader = new FileReader();

            reader.onloadend = function () {
                if (file.name.endsWith('.csv')) {
                    // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
                    const b64 = reader.result.replace(/^data:.+;base64,/, '');
                    let csvName = fileName || file.name;

                    csvName = prefix && !csvName.startsWith(prefix) ? prefix + csvName : csvName;
                    csvName = fileName && !csvName.endsWith('.csv') ? `${csvName}.csv` : csvName;

                    sendUpload({ file: b64, fileName: csvName });
                } else {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'TRANSLATOR.IMPORT.NOT_CSV' }),
                    });
                }
            };

            reader.readAsDataURL(file);
        }

        setShowModal(false);
    };

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="las la-2x text-primary la-file-import" />}
            title={Intl.formatMessage({ id: title })}
            body={
                <>
                    {description ? (
                        <div>
                            <FormattedHTMLMessage id={description} />
                        </div>
                    ) : (
                        ''
                    )}
                    <div>
                        <Form className="import mt-5">
                            <Row className="mb-5">
                                <Col lg={12}>
                                    <Card>
                                        <Card.Body>
                                            {filename && (
                                                <Form.Group controlId="file">
                                                    <Form.Label>{Intl.formatMessage({ id: filename })}</Form.Label>
                                                    <Form.Control
                                                        name="fileName"
                                                        value={fileName}
                                                        onChange={(e) => {
                                                            setFileName(e?.target?.value);
                                                        }}
                                                    />
                                                </Form.Group>
                                            )}
                                            <Form.Group controlId="file">
                                                <FileUpload
                                                    name="file"
                                                    accept=".csv"
                                                    maxFileSize={maxFileSize}
                                                    invalidFileSizeMessageDetail={Intl.formatMessage({
                                                        id: 'TRANSLATOR.IMPORT.MAX_FILE_SIZE.DETAIL',
                                                    })}
                                                    invalidFileSizeMessageSummary={Intl.formatMessage({
                                                        id: 'TRANSLATOR.IMPORT.MAX_FILE_SIZE.SUMMARY',
                                                    })}
                                                    emptyTemplate={
                                                        <p className="p-m-0">
                                                            {Intl.formatMessage({ id: 'TRANSLATOR.IMPORT.CSV' })}
                                                        </p>
                                                    }
                                                    chooseOptions={chooseOptions}
                                                    uploadOptions={uploadOptions}
                                                    cancelOptions={cancelOptions}
                                                    customUpload
                                                    uploadHandler={customUploadHandler}
                                                    multiple
                                                />
                                            </Form.Group>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </>
            }
            footer={
                <div className="d-flex justify-content-end">
                    <Button variant="outline-primary" style={{ width: '100px' }} onClick={() => setShowModal(false)}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.BACK' })}
                    </Button>
                </div>
            }
        />
    );
};

ImportCsv.defaultProps = {
    title: 'TRANSLATOR.IMPORT',
    filename: 'TRANSLATOR.IMPORT.FILENAME',
    successMessage: 'TRANSLATOR.IMPORT.SUCCESS',
    failedMessage: 'TRANSLATOR.ERROR',
    maxFileSize: 730000,
};

export default ImportCsv;
