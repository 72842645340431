import React, { Dispatch, SetStateAction } from 'react';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { isExpired } from '@app/helpers/DateHelper';

import { Vehicle, VehiclePricing } from '@app/store/stock/stock.type';

import Permission from '@app/partials/content/Permission';

import StockPriceDetails from './StockPriceDetails';
import StockPriceHistory from './StockPriceHistory';
import StockPriceRecommendation from './StockPriceRecommendation';

type StockPriceContentProps = {
    vehicle: Vehicle;
    vehiclePricing: VehiclePricing;
    showDetails: boolean;
    showHistory: boolean;
    setShowModal?: Dispatch<SetStateAction<boolean>>;
    setSubModalActive?: Dispatch<SetStateAction<boolean>>;
    setVehicleItem: Function;
    showTitleHistory?: boolean;
};

const StockPriceContent = ({
    vehicle,
    vehiclePricing,
    showDetails,
    showHistory,
    setShowModal,
    setSubModalActive,
    setVehicleItem,
    showTitleHistory = true,
}: StockPriceContentProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const displayPricing =
        vehicle?.has_pricing_reco && vehicle?.pricing?.expirationDate && !isExpired(vehicle.pricing.expirationDate);

    return (
        <div className="d-flex flex-column align-items-center h-100" style={{ minHeight: '10vh' }}>
            {displayPricing && showDetails && (
                <p className="text-primary mb-5" style={{ fontSize: '18px', marginTop: '-25px' }}>
                    <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.RECOMMENDATION" />
                    <strong>
                        <NumberFormat
                            displayType="text"
                            thousandSeparator=" "
                            suffix=" €"
                            value={vehiclePricing.recoPrice}
                        />
                    </strong>
                </p>
            )}
            {showDetails && (
                <Permission permissions={['STOCK_VIEW_PRICE_MANAGER']}>
                    <StockPriceDetails vehicle={vehicle} />
                </Permission>
            )}
            {displayPricing && showDetails && (
                <StockPriceRecommendation
                    sku={vehicle.sku}
                    price={vehicle.price}
                    pricing={vehiclePricing}
                    setShowModal={setShowModal}
                    setSubModalActive={setSubModalActive}
                    setVehicleItem={setVehicleItem}
                />
            )}
            {showHistory && (
                <StockPriceHistory
                    vehicle={vehicle}
                    dispatch={dispatch}
                    history={history}
                    showTitle={showTitleHistory}
                />
            )}
        </div>
    );
};

StockPriceContent.defaultProps = {
    showTitleHistory: true,
    setShowModal: null,
    setSubModalActive: null,
};

export default StockPriceContent;
