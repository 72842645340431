import React, { useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useFetch from '@app/hooks/useFetch';
import useModal from '@app/hooks/useModal';

import { getCampaign, getCampaignStats, updateCampaign } from '@app/crud/campaigns/campaign.crud';

import { actions } from '@app/store/modal/modal.store';

import Loader from '@app/partials/content/Loader';
import toast from '@app/partials/content/Toast';
import Modal from '@app/partials/HoC/Modal';

import { campaignDisplayStats, campaignDisplaySynthesis, campaignIsInFinalState } from './helpers/CampaignHelper';
import CampaignCancel from './modals/CampaignCancel';
import CampaignEdit from './modals/CampaignEdit';
import OperationSettings from './modals/OperationSettings';
import CampaignAssignment from './partials/CampaignAssignment';
import CampaignHistory from './partials/CampaignHistory';
import CampaignInfos from './partials/CampaignInfos';
import CampaignProgress from './partials/CampaignProgress';
import CampaignStats from './partials/CampaignStats';
import CampaignSynthesis from './partials/CampaignSynthesis/CampaignSynthesis';

const Campaign = ({ campaign, refresh }) => {
    const [showOperationSettings, setShowOperationSettings] = useModal(false, 'operation_settings');
    const [showCampaignCancel, setShowCampaignCancel] = useModal(false, 'campaign_cancel');
    const [editCampaignModalOpen, setEditCampaignModalOpen] = useModal(false, 'campaign_edit');
    const { userData } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const { fetch, data, loading } = useFetch({
        fetchAction: getCampaign,
        resultInterceptor: (response) => response,
    });

    const { result: campaignDetails, statistics, events } = data;

    const {
        fetch: fetchStats,
        data: statisticsDetails,
        loading: statsLoading,
    } = useFetch({
        fetchAction: getCampaignStats,
    });

    useEffect(() => {
        fetch(campaign.id).then((response) => {
            if (response?.result) {
                const { result } = response;
                if (campaignDisplaySynthesis(result?.campaignStatus) || campaignDisplayStats(result?.campaignStatus)) {
                    fetchStats(campaign.id).then();
                }
            }
        });
    }, []);

    const fetchData = () => {
        refresh();
    };

    const campaignCancel = () => {
        updateCampaign(campaign.id, {
            campaignStatus: 'canceled',
            user: {
                id: userData.id,
                firstname: userData.first_name,
                lastname: userData.last_name,
                email: userData.email,
            },
        })
            .then(() => {
                setShowCampaignCancel(false);
            })
            .catch(() => {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'CAMPAIGN.UPDATE.MESSAGE.FAILED' }),
                });
                return false;
            });
    };
    const updateCampaignData = (campaignData) => {
        updateCampaign(campaign.id, {
            ...campaignData,
            user: {
                id: userData.id,
                firstname: userData.first_name,
                lastname: userData.last_name,
                email: userData.email,
            },
        })
            .then(() => {
                toast({
                    variant: 'success',
                    message: Intl.formatMessage({ id: 'CAMPAIGN.UPDATE.MESSAGE.SUCCESS' }),
                });
            })
            .catch(() => {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'CAMPAIGN.UPDATE.MESSAGE.FAILED' }),
                });
            });
    };

    const isEditable = !campaignIsInFinalState(campaign?.campaignStatus);

    return (
        <div className="m-5">
            <CampaignProgress current_step={campaignDetails?.campaignStatus} className="mb-8" />
            <Row className="mb-8">
                <Col>
                    <Card className="card--shadowed">
                        <Card.Header className="text-closed position-relative">
                            <div className="bg-closed pin">
                                <i className="la la-bullseye text-white la-lg" />
                            </div>
                            <span className="ml-5 font-weight-semibold d-flex justify-content-between">
                                <FormattedMessage id="CAMPAIGN.INFOS.TITLE" />
                                {isEditable && (
                                    <Button
                                        className="p-0"
                                        onClick={() =>
                                            dispatch(
                                                actions.modalChange('campaign_edit', {
                                                    callback: updateCampaignData,
                                                    campaign: campaignDetails,
                                                }),
                                            )
                                        }
                                        variant="outline-link"
                                    >
                                        <i className="la la-pen p-0 text-muted" />
                                        <u className={`ml-2 font-size-sm `}>
                                            <FormattedMessage id="TRANSLATOR.UPDATE" />
                                        </u>
                                    </Button>
                                )}
                            </span>
                        </Card.Header>
                        <Card.Body>
                            {loading ? (
                                <Loader
                                    style={{ width: '5rem', height: '5rem' }}
                                    wrapperStyle={{ marginTop: '-15px' }}
                                    overlay
                                />
                            ) : (
                                <CampaignInfos campaign={campaignDetails} totalCount={statistics?.globalContact} />
                            )}
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <CampaignAssignment
                        current_step={campaignDetails?.campaignStatus}
                        statistics={statistics}
                        loading={loading}
                    />
                </Col>
            </Row>
            {campaignDisplaySynthesis(campaignDetails?.campaignStatus) && (
                <CampaignSynthesis
                    campaign={campaignDetails}
                    statistics={statisticsDetails}
                    className="mb-8"
                    loading={statsLoading}
                />
            )}
            {campaignDisplayStats(campaignDetails?.campaignStatus) && (
                <CampaignStats statistics={statisticsDetails} className="mb-8" loading={statsLoading} />
            )}
            <CampaignHistory events={events} loading={loading} />
            <Modal
                As={OperationSettings}
                showModal={showOperationSettings}
                setShowModal={(show) => {
                    setShowOperationSettings(show);
                    fetchData(campaign.id);
                }}
                campaign={campaignDetails}
                statistics={statistics}
            />
            <Modal
                As={CampaignCancel}
                showModal={showCampaignCancel}
                setShowModal={(show) => {
                    setShowCampaignCancel(show);
                    fetchData(campaign.id);
                }}
                callback={() => {
                    campaignCancel();
                    fetchData(campaign.id);
                }}
            />
            <Modal
                As={CampaignEdit}
                showModal={editCampaignModalOpen}
                setShowModal={(show) => {
                    setEditCampaignModalOpen(show);
                    fetchData(campaign.id);
                }}
            />
        </div>
    );
};

export default Campaign;
