import classNames from 'classnames';
import React, { KeyboardEvent, useContext, useEffect, useRef } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

import { FiltersContext } from './FiltersProvider';

type SearchInputFilterProps = {
    className?: string;
    name: string;
    onChange: Function;
    refresh?: Function;
    placeholder: string;
    disabled?: boolean;
    inputClassName: string;
};

const SearchInputFilter = ({
    className,
    name,
    onChange,
    refresh,
    placeholder,
    disabled,
    inputClassName,
}: SearchInputFilterProps) => {
    const filters = useContext(FiltersContext);
    const inputRef = useRef<HTMLInputElement>();

    const handleChange = () => {
        if (typeof onChange !== 'undefined') {
            onChange({
                [name]: {
                    value: inputRef?.current?.value || '',
                },
            });
        }

        if (typeof refresh !== 'undefined') {
            refresh();
        }
    };

    useEffect(() => {
        if (inputRef?.current) {
            inputRef.current.value = filters?.[name]?.value || '';
        }
    }, [filters, inputRef]);

    return (
        <Row className={classNames(className, 'search-input')}>
            <Col lg={11} xs={10} className="px-lg-4 px-0 kt-radius radius">
                <Form.Group className={inputClassName}>
                    <Form.Control
                        ref={inputRef}
                        defaultValue={filters?.[name]?.value || ''}
                        onKeyUp={(e: KeyboardEvent) => {
                            if (e.key === 'Enter') {
                                handleChange();
                            }
                        }}
                        className="kt-radius-lg-5 kt-radius-0 kt-top-left-radius-5 kt-bottom-left-radius-5"
                        type="text"
                        placeholder={placeholder}
                        disabled={disabled}
                    />
                </Form.Group>
            </Col>
            <Col lg={1} xs={2} className="search-input__button">
                <Button
                    onClick={handleChange}
                    className="btn-icon kt-radius-lg-5 kt-radius-0 kt-top-right-radius-5 kt-bottom-right-radius-5"
                    variant="primary"
                    disabled={disabled}
                >
                    <i className="fas fa-search" />
                </Button>
            </Col>
        </Row>
    );
};

SearchInputFilter.defaultProps = {
    name: 'query',
    value: '',
    inputClassName: '',
    disabled: false,
};

export default SearchInputFilter;
