import dayjs from 'dayjs';
import { Column } from 'primereact/column';
import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { routeTo } from '@app/helpers/RoutesHelper';

import { fetchDealerships } from '@app/store/apv/dealership/dealership.thunk';

import { Filters } from '@app/partials/layout/Filters';

import useFilters from '../../../hooks/useFilters';
import usePermissions from '../../../hooks/usePermissions';
import useTableHandlers from '../../../hooks/useTableHandlers';
import FiltersProvider from '../../../partials/content/FiltersProvider';
import HoCDataTable from '../../../partials/content/HoCDataTable';
import InputFilter from '../../../partials/content/InputFilter';
import RefreshTable from '../../../partials/content/RefreshTable';
import SearchInputFilter from '../../../partials/content/SearchInputFilter';
import SelectFilter from '../../../partials/content/SelectFilter';
import ROUTES from '../../../router/Routes';

function Dealerships() {
    const { hasPermission } = usePermissions();
    const { dealerships, loading, totalRecords } = useSelector((state) => state.dealership);
    const { items, start, filters, sortField, sortOrder } = useFilters({
        name: 'apv_dealership',
    });
    const { handleFilter, handlePagination, handleSort, refresh } = useTableHandlers({
        filterStore: 'apv_dealership',
        fetchAction: fetchDealerships,
    });

    const inputFilter = (name) => <InputFilter name={name} onChange={handleFilter} />;

    const selectFilter = (name, options) => <SelectFilter name={name} options={options} onChange={handleFilter} />;

    const addressBodyTemplate = (dealership) => (
        <>
            {dealership?.dealershipSource?.address}
            <br />
            {dealership?.dealershipSource?.zipcode} {dealership?.dealershipSource?.city}
        </>
    );

    const statusBodyTemplate = (dealership) => (
        <span className={dealership?.active ? 'text-success' : 'text-danger'}>
            {dealership?.active ? '• Actif' : '• Inactif'}
        </span>
    );

    const updatedBodyTemplate = (dealership) => dayjs(dealership.updated).format('DD/MM/YYYY [à] HH [h] mm');

    const actionsBodyTemplate = (dealership) => (
        <Link
            to={routeTo(ROUTES.DEALERSHIP.PATH, {
                id: dealership?.dealershipSource?.id,
            })}
        >
            <Button className="p-2 px-3" variant="secondary">
                <i className="fa fa-pen" />
                {Intl.formatMessage({ id: 'TRANSLATOR.UPDATE' })}
            </Button>
        </Link>
    );

    const cols = [
        {
            field: 'reference',
            header: Intl.formatMessage({ id: 'APV.DEALERSHIP.TABLE.HEAD.REFERENCE' }),
            style: { width: '10%' },
            filter: true,
            filterElement: inputFilter('reference'),
            body: (dealership) => dealership?.dealershipSource?.id,
        },
        {
            field: 'name',
            header: Intl.formatMessage({ id: 'APV.DEALERSHIP.TABLE.HEAD.NAME' }),
            style: { width: '15%' },
            filter: true,
            filterElement: inputFilter('dealershipName'),
            body: (dealership) => dealership?.dealershipSource?.usual_name,
        },
        {
            field: 'address',
            header: Intl.formatMessage({ id: 'APV.DEALERSHIP.TABLE.HEAD.ADDRESS' }),
            style: { width: '30%' },
            filter: true,
            filterElement: inputFilter('address'),
            body: addressBodyTemplate,
        },
        {
            field: 'delay',
            header: Intl.formatMessage({ id: 'APV.DEALERSHIP.TABLE.HEAD.DELAY' }),
            style: { width: '15%' },
            body: (dealership) => `${dealership.minDays} jour${dealership.minDays > 1 ? 's' : ''}`,
        },
        {
            field: 'status',
            header: Intl.formatMessage({ id: 'APV.DEALERSHIP.TABLE.HEAD.STATUS' }),
            style: { width: '10%' },
            filter: true,
            filterElement: selectFilter(
                'active',
                <>
                    <option value="">Tout les statuts</option>
                    <option value>Actif</option>
                    <option value={false}>Inactif</option>
                </>,
            ),
            body: statusBodyTemplate,
        },
        {
            field: 'call',
            header: Intl.formatMessage({ id: 'APV.DEALERSHIP.TABLE.HEAD.CALL' }),
            style: { width: '10%' },
            filter: true,
            filterElement: selectFilter(
                'deployCall',
                <>
                    <option value="">Tout les statuts</option>
                    <option value>Oui</option>
                    <option value={false}>Non</option>
                </>,
            ),
            body: (dealership) => (dealership?.sendToCall ? 'Oui' : 'Non'),
        },
        {
            field: 'updated',
            header: Intl.formatMessage({ id: 'APV.DEALERSHIP.TABLE.HEAD.UPDATED' }),
            style: { width: '10%' },
            body: updatedBodyTemplate,
        },
    ];

    if (hasPermission('APV_VIEW_PARAMS')) {
        cols.push({
            field: 'actions',
            header: Intl.formatMessage({ id: 'APV.DEALERSHIP.TABLE.HEAD.ACTIONS' }),
            style: { width: '10%' },
            body: actionsBodyTemplate,
        });
    }

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <Card>
            <Card.Body>
                <FiltersProvider value={filters}>
                    <Filters>
                        <Row>
                            <RefreshTable count={totalRecords} onClick={refresh} />
                            <Col lg={5} offset={2}>
                                <SearchInputFilter
                                    onChange={handleFilter}
                                    name="research"
                                    placeholder="Prestations, type, cible, critère, etc."
                                />
                            </Col>
                        </Row>
                    </Filters>
                    <HoCDataTable
                        value={dealerships}
                        totalRecords={totalRecords}
                        lazy
                        loading={loading}
                        paginator
                        onFilter={handleFilter}
                        onPage={handlePagination}
                        onSort={handleSort}
                        paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                        currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                        rows={items}
                        first={start}
                        sortField={sortField}
                        sortOrder={sortOrder}
                        removableSort
                        filterDisplay="row"
                        rowsPerPageOptions={[25, 50, 100]}
                        emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                    >
                        {dynamicColumns}
                    </HoCDataTable>
                </FiltersProvider>
            </Card.Body>
        </Card>
    );
}

export default Dealerships;
