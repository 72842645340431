import autouserApplications from './applications.json';
import autouserDealerships from './dealerships.json';
import autouserDirectory from './directory.json';
import autouserHistory from './history.json';
import autouserLogin from './login.json';
import autouserPermissions from './permissions.json';
import autouserRoles from './roles.json';
import autouserUsers from './users.json';

const frAutouser = {
    ...autouserApplications,
    ...autouserHistory,
    ...autouserPermissions,
    ...autouserRoles,
    ...autouserUsers,
    ...autouserDealerships,
    ...autouserLogin,
    ...autouserDirectory,
};

export default frAutouser;
