import React, { useState } from 'react';
import { Card, Col, Image } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';

import { DirectoryDealerships, DirectoryUsers } from '@app/pages/directory/index';

import DirectorySearch from '@app/components/forms/search/DirectorySearch';

export interface DirectorySearchType {
    user: string;
    dealership: string;
}

const Directory = () => {
    const [search, setSearch] = useState<DirectorySearchType>({
        user: '',
        dealership: '',
    });
    const bodyContent = () => {
        if (search.user) {
            return <DirectoryUsers search={search.user} />;
        } else if (search.dealership) {
            return <DirectoryDealerships search={search.dealership} />;
        }
        return (
            <Col lg={{ span: 6, offset: 3 }}>
                <Image src="/media/pages/customers/clients-recherche.svg" className="mt-10" />
            </Col>
        );
    };

    return (
        <Card id="first-tour">
            <Card.Body className="text-center mt-10">
                <div className="font-size-h4 font-weight-bold mb-8">
                    <FormattedHTMLMessage id="DIRECTORY.TABLE.VIEW.START" />
                </div>
                <DirectorySearch search={search} setSearch={setSearch} />
                {bodyContent()}
            </Card.Body>
        </Card>
    );
};

export default Directory;
