import classNames from 'classnames';
import { Skeleton } from 'primereact/skeleton';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import useFetch from '@app/hooks/useFetch';

import { routeTo } from '@app/helpers/RoutesHelper';

import { getStockPricingToDoCountersRaw, getVehiclesActionsToDo } from '@app/crud/stock/stock.crud';

import { usePermissions } from '@app/hooks';
import ROUTES from '@app/router/Routes';

import { DashboardContext } from '../../DashboardProvider';

const DashboardActivityStock = () => {
    const { params } = useContext(DashboardContext);
    const selectedDealerships = params?.dealership;
    const { hasPermission } = usePermissions();

    const [results, setResults] = useState([]);
    const [pricingResults, setPricingResults] = useState([]);
    const { fetch, loading, setLoading } = useFetch({
        fetchAction: getVehiclesActionsToDo,
        resultInterceptor: (response) => response,
    });

    const { fetch: fetchActions } = useFetch({
        fetchAction: getStockPricingToDoCountersRaw,
    });

    useEffect(() => {
        fetch({
            inactive: 0,
            ...(selectedDealerships && { dealer_id: selectedDealerships }),
        })
            .then((response) => {
                if (response) {
                    setLoading(true);
                    const mappedTypes = response.types.map((element) => ({
                        slug: element.slug,
                        total: element.total,
                    }));
                    setResults(mappedTypes);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log(`erreur : ${error}`);
            });

        fetchActions({
            ...(selectedDealerships && {
                dealerIds: selectedDealerships.split(','),
            }),
        })
            .then((response) => {
                if (response) {
                    setLoading(true);
                    setPricingResults(response);
                    setLoading(false);
                }
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(`erreur : ${error}`);
            });
    }, [selectedDealerships]);

    if (loading) {
        return <DashboardActivityStock.Skeleton />;
    }

    return (
        <div className={`mt-8 ${classNames('dashboard-activity')}`}>
            <div className="d-flex align-items-center">
                <i className="dashboard-activity__icon las la-2x la-car mr-2" />
                <span className="font-size-lg font-weight-bold">
                    <FormattedMessage id="DASHBOARD.ACTIVITY.STOCK" />
                </span>
            </div>
            <div className="d-flex flex-wrap">
                {hasPermission('STOCK_LIST_ACTION') && (
                    <div className="d-flex flex-column justify-content-center align-items-center mr-2 mt-3 dashboard-stock__card dashboard-stock__card--danger">
                        <div className="text-nowrap line-height-md font-weight-semibold text-white">
                            <FormattedHTMLMessage id="DASHBOARD.ACTIVITY.STOCK.ACTIONS.TOTAL" />
                        </div>
                        <div className="text-nowrap line-height-md font-weight-bold text-white dashboard-stock__number">
                            {(results?.[0]?.total || 0) + (pricingResults?.pricing_to_process || 0)}
                        </div>
                    </div>
                )}
                {hasPermission('STOCK_CHECK_PRICING') ? (
                    <div className="mr-2 mt-3 dashboard-stock__card bg-danger-lite-light">
                        <div className="d-flex flex-column justify-content-between align-items-center">
                            <div className="text-nowrap line-height-md font-weight-bold dashboard-stock__number">
                                {pricingResults?.pricing_to_check ?? '0'}
                            </div>
                            <div className="text-nowrap line-height-md font-weight-semibold">
                                <FormattedHTMLMessage id="DASHBOARD.ACTIVITY.STOCK.BLOCK.PRICING_TO_CHECK" />
                            </div>
                        </div>
                        <Link
                            to={{
                                pathname: routeTo(ROUTES.STOCK_PRICING_TO_DO.PATH, {
                                    page: 'check',
                                }),
                            }}
                        >
                            <Button
                                className="d-flex align-items-center py-1 px-3 mt-1 w-100 justify-content-center font-weight-bold border-danger"
                                variant="inline-danger"
                                size="sm"
                            >
                                <FormattedMessage id="TRANSLATOR.CONTROL" />
                            </Button>
                        </Link>
                    </div>
                ) : (
                    <div className="mr-2 mt-3 dashboard-stock__card bg-danger-lite-light">
                        <div className="d-flex flex-column justify-content-between align-items-center">
                            <div className="text-nowrap line-height-md font-weight-bold dashboard-stock__number">
                                {results?.[1]?.total ?? '0'}
                            </div>
                            <div className="text-nowrap line-height-md font-weight-semibold">
                                <FormattedHTMLMessage id="DASHBOARD.ACTIVITY.STOCK.WAITING.PUBLISHING" />
                            </div>
                        </div>
                        <Link
                            to={{
                                pathname: routeTo(ROUTES.STOCK_ACTION_TO_DO.PATH, {
                                    page: 'list',
                                }),
                                search: `?activeTab=${results?.[1]?.slug}`,
                            }}
                        >
                            <Button
                                className="d-flex align-items-center py-1 px-3 mt-1 w-100 justify-content-center font-weight-bold border-danger"
                                variant="inline-danger"
                                size="sm"
                            >
                                <FormattedMessage id="TRANSLATOR.CONTROL" />
                            </Button>
                        </Link>
                    </div>
                )}
                {hasPermission('STOCK_VIEW_PRICE_MANAGER') && (
                    <div className="mr-2 mt-3 dashboard-stock__card bg-light-blue">
                        <div className="d-flex flex-column justify-content-between align-items-center">
                            <div className="text-nowrap line-height-md font-weight-bold dashboard-stock__number text-dark-blue">
                                {pricingResults?.pricing_to_process ?? '0'}
                            </div>
                            <div className="text-nowrap line-height-md font-weight-semibold text-dark-blue">
                                <FormattedHTMLMessage id="DASHBOARD.ACTIVITY.STOCK.BLOCK.PRICING_TO_DO" />
                            </div>
                        </div>
                        <Link
                            to={{
                                pathname: routeTo(ROUTES.STOCK_PRICING_TO_DO.PATH, {
                                    page: 'list',
                                }),
                            }}
                        >
                            <Button
                                className="d-flex align-items-center py-1 px-3 mt-1 w-100 justify-content-center font-weight-bold bg-dark-blue"
                                size="sm"
                            >
                                <FormattedMessage id="TRANSLATOR.TREAT" />
                            </Button>
                        </Link>
                    </div>
                )}

                {hasPermission('STOCK_LIST_ACTION') && (
                    <div className="mr-2 mt-3 dashboard-stock__card bg-light-blue">
                        <div className="d-flex flex-column justify-content-between align-items-center">
                            <div className="text-nowrap line-height-md font-weight-bold dashboard-stock__number">
                                {(results?.[3]?.total || 0) + (results?.[2]?.total || 0)}
                            </div>
                            <div className="text-nowrap line-height-md font-weight-semibold">
                                <FormattedHTMLMessage id="DASHBOARD.ACTIVITY.STOCK.BLOCK.OTHER" />
                            </div>
                        </div>
                        <Link
                            to={{
                                pathname: routeTo(ROUTES.STOCK_ACTION_TO_DO.PATH, {
                                    page: 'list',
                                }),
                                search: `?activeTab=all}`,
                            }}
                        >
                            <Button
                                className="d-flex align-items-center py-1 px-3 mt-1 w-100 justify-content-center font-weight-bold bg-white text-dark-blue"
                                size="sm"
                            >
                                <FormattedMessage id="TRANSLATOR.CONTROL" />
                            </Button>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

DashboardActivityStock.Skeleton = () => <Skeleton borderRadius="10px" className="mt-8 p-20" />;
export default DashboardActivityStock;
