import React from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';

import { FollowUpTaskStatus } from '@app/crud/customers/customer.type';

import useLead from '@app/pages/customers/Hooks/useLead';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import useActionClient from '../Hooks/useActionClient';
import UpdateFollowUpTaskClient from '@app/crud/action/update-follow-up-task';

type FollowUpTaskProps = {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    params: { leadId: string; isLockAction?: boolean };
};

const FollowUpTask = ({ showModal, setShowModal, params }: FollowUpTaskProps) => {
    const Intl = useIntl();
    const lead = useLead(params?.leadId);
    const followUpTaskStatus = lead?.flags?.find((flag: FollowUpTaskStatus) =>
        [FollowUpTaskStatus.CLOSED, FollowUpTaskStatus.OPEN].includes(flag),
    );

    const isOpenTask = followUpTaskStatus === FollowUpTaskStatus.OPEN;
    const { iconName, buttonVariant, buttonId, titleId, textId } = isOpenTask
        ? {
              iconName: 'la-exclamation-triangle',
              buttonVariant: 'danger' as const,
              buttonId: 'CUSTOMER.MODAL.FOLLOW_UP_TASK.BUTTON',
              titleId: 'CUSTOMER.MODAL.FOLLOW_UP_TASK.TITLE',
              textId: params?.isLockAction
                  ? 'CUSTOMER.MODAL.FOLLOW_UP_TASK.ACTION_ALERT.TEXT'
                  : 'CUSTOMER.MODAL.FOLLOW_UP_TASK.FIRST_ALERT.TEXT',
          }
        : {
              iconName: 'la-hand-paper',
              buttonVariant: 'primary' as const,
              buttonId: 'CUSTOMER.MODAL.FOLLOW_UP_TASK.CONFIRMATION.BUTTON',
              titleId: 'CUSTOMER.MODAL.FOLLOW_UP_TASK.CONFIRMATION.TITLE',
              textId: 'CUSTOMER.MODAL.FOLLOW_UP_TASK.CONFIRMATION.TEXT',
          };

    const [caller, loading] = useActionClient(new UpdateFollowUpTaskClient(), {
        notifySucess: Intl.formatMessage({ id: 'TRANSLATOR.SUCCESS' }),
        notifyFail: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
    });

    const redirectToSaleforce = () => {
        const { REACT_APP_SALESFORCE_BMW } = process.env;
        const leadOriginId = lead?.originId.replace('BMW_GROUP_', '');

        window.open(`${REACT_APP_SALESFORCE_BMW}/${leadOriginId}/view`, '_blank');
    };

    const onSubmit = () => {
        if (isOpenTask) {
            redirectToSaleforce();
        } else {
            caller({
                lead: parseInt(params.leadId),
            }).then(() => {
                setShowModal(false);
            });
        }
    };

    if (!lead) return null;
    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            hideModal={() => setShowModal(false)}
            icon={<i className={`la la-2x text-primary ${iconName}`} />}
            title={<FormattedMessage id={titleId} />}
            body={<FormattedHTMLMessage id={textId} />}
            footer={
                <div className="d-flex justify-content-center">
                    <Button variant={buttonVariant} onClick={onSubmit}>
                        <FormattedMessage id={buttonId} />
                    </Button>
                </div>
            }
        />
    );
};

export default FollowUpTask;
