import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Column } from 'primereact/column';
import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Locale } from '@src/_metronic/i18n/I18nProvider';

import { Pagination, Sorts } from '@app/hooks/useTableHandlers';

import { userIsAssociateToDealership } from '@app/helpers/UserHelper';

import { Dealership, DealershipUser } from '@app/crud/autouser/dealerships.type';
import { Tag } from '@app/crud/autouser/tags.crud';

import DisplayDealership from '@app/pages/autouser/dealerships/partials/DisplayDealership';
import { getSortedUsersByRoleSlug } from '@app/pages/autouser/helpers/UserHelper';
import { actions } from '@app/store/modal/modal.store';

import HoCDataTable from '@app/partials/content/HoCDataTable';
import Label from '@app/partials/content/Label';

import ActionsButtons from '../users/Partials/ActionsButtons';

interface Props {
    dealership: Dealership;
    handleFilter: (value: unknown) => void;
    handlePagination: (e: Pagination) => void;
    handleSort: (value: Sorts) => void;
    refresh: () => void;
    sortField: string;
    sortOrder: number;
    loading: boolean;
    tags: Tag[];
}
const DealershipsPerimeterDataTable = ({
    dealership,
    handleFilter,
    handlePagination,
    handleSort,
    sortField,
    sortOrder,
    loading,
    refresh,
    tags,
}: Props) => {
    const dispatch = useDispatch();
    const nameBodyTemplate = (user: DealershipUser) => {
        const variantCallTransfer = user?.callTransfer ? 'success' : 'danger';
        return (
            <>
                <div>
                    {user?.lastName}
                    <br />
                    {user?.firstName}
                </div>
                <div className="d-flex align-items-center mt-2">
                    <Label variant={user.active ? 'success' : 'danger'} size="label-lg">
                        <FormattedHTMLMessage id={`AUTOUSER.USERS.VIEW.${user.active ? 'ACTIVE' : 'DISABLE'}`} />
                    </Label>
                    <Label variant={variantCallTransfer} size="label-lg" className="ml-2">
                        <i className={`la la-tty mr-1 text-${variantCallTransfer}`} />
                        <FormattedHTMLMessage id="AUTOUSER.USERS.VIEW.CALL_TRANSFERT" />
                    </Label>
                </div>
            </>
        );
    };

    const emailBodyTemplate = (user: DealershipUser) => <div>{user?.email}</div>;

    const phoneBodyTemplate = (user: DealershipUser) => {
        const phone = parsePhoneNumberFromString(user?.phone || '', Locale.toUpperCase());
        return <div>{phone?.formatNational?.() || '-'}</div>;
    };

    const mobileBodyTemplate = (user: DealershipUser) => {
        const cellPhone = parsePhoneNumberFromString(user?.cellPhone || '', Locale.toUpperCase());
        return <div>{cellPhone?.formatNational?.() || '-'}</div>;
    };

    const roleBodyTemplate = (user: DealershipUser) => <div>{user?.role?.name}</div>;

    const perimeterBodyTemplate = (user: DealershipUser) => (
        <div>
            {user?.tags?.typologie_lead?.length > 0 &&
                user?.tags?.typologie_lead.map((tag) => {
                    const tagData = tags?.find?.((t) => t?.slug === tag);
                    return (
                        <div key={`${user.id}-${tag}`} className="label m-1 label-primary label-inline label-lg">
                            {tagData?.name?.toUpperCase?.()}
                        </div>
                    );
                })}
        </div>
    );

    const actionBodyTemplate = (user: DealershipUser) => {
        if (user?.role?.type === 'fonctionnel') {
            // @ts-ignore
            return <ActionsButtons _user={user} refresh={refresh} dealership={dealership} />;
        }
    };

    const cols = [
        {
            field: 'name',
            header: <FormattedHTMLMessage id="AUTOUSER.DEALERSHIPS.TABLE.HEAD.NAME" />,
            style: { width: '200px' },
            body: nameBodyTemplate,
        },
        {
            field: 'email',
            header: <FormattedHTMLMessage id="AUTOUSER.DEALERSHIPS.TABLE.HEAD.EMAIL" />,
            body: emailBodyTemplate,
        },
        {
            field: 'phone',
            header: <FormattedHTMLMessage id="AUTOUSER.DEALERSHIPS.TABLE.HEAD.PHONE" />,
            style: { width: '150px' },
            body: phoneBodyTemplate,
        },
        {
            field: 'mobile',
            header: <FormattedHTMLMessage id="AUTOUSER.DEALERSHIPS.TABLE.HEAD.MOBILE" />,
            style: { width: '150px' },
            body: mobileBodyTemplate,
        },
        {
            field: 'role',
            header: <FormattedHTMLMessage id="AUTOUSER.DEALERSHIPS.TABLE.HEAD.ROLE" />,
            body: roleBodyTemplate,
        },
        {
            field: 'perimeter',
            header: <FormattedHTMLMessage id="AUTOUSER.DEALERSHIPS.TABLE.HEAD.PERIMETER" />,
            style: { width: '200px' },
            body: perimeterBodyTemplate,
        },
        userIsAssociateToDealership(dealership?.ref_dealership_id) && {
            field: 'action',
            header: <FormattedHTMLMessage id="AUTOUSER.DEALERSHIPS.TABLE.HEAD.ACTION" />,
            bodyClassName: 'text-center',
            style: { width: '150px' },
            body: actionBodyTemplate,
        },
    ];

    const dynamicColumns = cols.map((col) => col && <Column key={col.field} {...col} />);

    const validSupportRole = [
        'super-administrateur',
        'admin-bu-apv',
        'admin-bu-vo',
        'responsable-marketing',
        'assistance',
    ];

    const validApvRole = ['responsable-apv', 'conseiller-commercial-services'];
    return (
        <div className="mt-15">
            <div className="mb-5 d-flex align-items-center justify-content-between">
                <DisplayDealership dealership={dealership} />
                {userIsAssociateToDealership(dealership?.ref_dealership_id) && (
                    <div>
                        <Button
                            className="mr-5"
                            onClick={() =>
                                dispatch(
                                    actions.modalChange('add_user_to_dealership', {
                                        dealership,
                                    }),
                                )
                            }
                            variant="outline-primary"
                        >
                            <FormattedHTMLMessage id="AUTOUSER.DEALERSHIPS.TABLE.VIEW.ADD_USER" />
                        </Button>
                        <Button
                            className="mr-5"
                            onClick={() =>
                                dispatch(
                                    actions.modalChange('manage_dealership_signature', {
                                        dealership,
                                    }),
                                )
                            }
                            variant="outline-primary"
                        >
                            <FormattedHTMLMessage id="AUTOUSER.DEALERSHIPS.TABLE.VIEW.MANAGE_SIGNATURES" />
                        </Button>
                    </div>
                )}
            </div>
            {/* @ts-ignore */}
            <HoCDataTable
                value={getSortedUsersByRoleSlug(dealership?.users)}
                rowGroupMode="subheader"
                groupRowsBy="role.type"
                rowGroupHeaderTemplate={(data: DealershipUser) => {
                    if (data?.role?.slug && validSupportRole.includes(data.role.slug)) {
                        return (
                            <div className="py-4 kt-background font-size-lg font-weight-bold text-center">
                                <FormattedHTMLMessage id="AUTOUSER.ROLES.VIEW.TYPE.SUPPORT" />
                            </div>
                        );
                    } else if (data?.role?.slug && validApvRole.includes(data.role.slug)) {
                        return (
                            <div className="py-4 kt-background font-size-lg font-weight-bold text-center">
                                <FormattedHTMLMessage id="AUTOUSER.ROLES.VIEW.TYPE.APV" />
                            </div>
                        );
                    }
                    return null;
                }}
                rowGroupFooterTemplate={() => ''}
                lazy
                loading={loading}
                onFilter={handleFilter}
                onPage={handlePagination}
                onSort={handleSort}
                currentPageReportTemplate={<FormattedHTMLMessage id="DATATABLE.REPORT_TEMPLATE" />}
                sortField={sortField}
                sortOrder={sortOrder}
                removableSort
                rowsPerPageOptions={[25, 50, 100]}
                emptyMessage={<FormattedHTMLMessage id="DATATABLE.EMPTY_RESULT" />}
            >
                {dynamicColumns}
            </HoCDataTable>
        </div>
    );
};

export default DealershipsPerimeterDataTable;
