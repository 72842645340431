import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import Swal from 'sweetalert2';

import useModal from '@app/hooks/useModal';

import { leadSlug } from '@app/pages/customers/Helpers/Lead';
import { InOutChoiceVehicle } from '@app/pages/customers/Modals';
import Comment from '@app/pages/customers/Partials/Comment';
import { actions } from '@app/store/modal/modal.store';

import Loader from '@app/partials/content/Loader';
import Permission from '@app/partials/content/Permission';
import RowInfo from '@app/partials/content/RowInfo';
import {
    getRecoverableVat,
    getRegistrationTemplate,
    getVehicleInternalIdTemplate,
    getWarrantyTemplate,
    MAX_VEHICLES,
} from '@app/partials/content/Vehicle/VehicleHelpers';
import VehicleInfo from '@app/partials/content/Vehicle/VehicleInfo';

import LeadLocalVehicleProject from './LeadLocalVehicleProject';
import useActionClient from '@app/pages/customers/Hooks/useActionClient';
import CreateLeadElementOutOfStockClient from '@app/crud/action/create-lead-element-out-of-stock';
import { Selection } from '@app/pages/customers/Modals/InOutChoiceVehicle';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import UpdateLeadElementOutOfStockClient from '@app/crud/action/update-lead-element-out-of-stock';
import CreateLeadElementFromStockClient from '@app/crud/action/create-lead-element-from-stock';
import UpdateLeadElementFromStockClient from '@app/crud/action/update-lead-element-from-stock';
import DeleteLeadElementClient from '@app/crud/action/delete-lead-element';

interface Props {
    event: any;
    typeSlug: string;
    isLeadClosed: boolean;
    isVcuEvent: boolean;
}

let leadElementId: number | null = null;

const VehicleProject = ({ event, typeSlug, isLeadClosed, isVcuEvent }: Props) => {
    const { informations } = event;
    const [activeProject, setActiveProject] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showInOutChoice, setShowInOutChoice] = useModal(false, 'in_out_choice_vehicle');
    const [projects, setProjects] = useState(event?.project?.vehicles || []);
    const vehicle = projects?.length ? projects[activeProject] : null;
    const dispatch = useAppDispatch();
    const customerProject = event?.project?.customerProject;
    const isLeadAutobernard = informations?.sourceSlug === 'autobernard';
    const isLeadAudiOrOneLead =
        informations?.leadTypeSlug === 'constructeur' &&
        (informations?.leadOriginSlug === leadSlug.LEAD_AUDI_ORIGIN_SLUG ||
            informations?.sourceSlug === leadSlug.LEAD_ONE_LEAD_ORIGIN_SLUG);
    const Intl = useIntl();

    const [createOutOfStock, createOutOfStockLoading] = useActionClient(new CreateLeadElementOutOfStockClient(), {
        notifySucess: Intl.formatMessage({ id: 'CUSTOMERS.VIEW.LIFE.DETAIL.PROJECT.UPDATE_SUCCESS' }),
        notifyFail: Intl.formatMessage({ id: 'CUSTOMERS.VIEW.LIFE.DETAIL.PROJECT.UPDATE_FAILED' }),
    });
    const [updateOutOfStock, updateOutOfStockLoading] = useActionClient(new UpdateLeadElementOutOfStockClient(), {
        notifySucess: Intl.formatMessage({ id: 'CUSTOMERS.VIEW.LIFE.DETAIL.PROJECT.UPDATE_SUCCESS' }),
        notifyFail: Intl.formatMessage({ id: 'CUSTOMERS.VIEW.LIFE.DETAIL.PROJECT.UPDATE_FAILED' }),
    });
    const [createFromStock, createFromStockLoading] = useActionClient(new CreateLeadElementFromStockClient(), {
        notifySucess: Intl.formatMessage({ id: 'CUSTOMERS.VIEW.LIFE.DETAIL.PROJECT.UPDATE_SUCCESS' }),
        notifyFail: Intl.formatMessage({ id: 'CUSTOMERS.VIEW.LIFE.DETAIL.PROJECT.UPDATE_FAILED' }),
    });
    const [updateFromStock, updateFromStockLoading] = useActionClient(new UpdateLeadElementFromStockClient(), {
        notifySucess: Intl.formatMessage({ id: 'CUSTOMERS.VIEW.LIFE.DETAIL.PROJECT.UPDATE_SUCCESS' }),
        notifyFail: Intl.formatMessage({ id: 'CUSTOMERS.VIEW.LIFE.DETAIL.PROJECT.UPDATE_FAILED' }),
    });
    const [remove, removeLoading] = useActionClient(new DeleteLeadElementClient(), {
        notifySucess: Intl.formatMessage({ id: 'CUSTOMERS.VIEW.LIFE.DETAIL.PROJECT.UPDATE_SUCCESS' }),
        notifyFail: Intl.formatMessage({ id: 'CUSTOMERS.VIEW.LIFE.DETAIL.PROJECT.UPDATE_FAILED' }),
    });
    useEffect(() => {
        console.log('projects', projects);
    }, [projects]);
    const onSelectVehicle = (selection: Selection) => {
        if (selection.type == 'out_stock') {
            if (leadElementId) {
                updateOutOfStock({
                    leadElementId,
                    make: selection.vehicle.make,
                    model: selection.vehicle.model,
                    fuelType: selection.vehicle.fuelType,
                    gearsType: selection.vehicle.gearsType,
                }).then((response) => {
                    console.log('update', response);
                    setProjects(response.leadElements);
                });
            } else {
                createOutOfStock({
                    lead: informations.identifier,
                    make: selection.vehicle.make,
                    model: selection.vehicle.model,
                    fuelType: selection.vehicle.fuelType,
                    gearsType: selection.vehicle.gearsType,
                }).then((response) => {
                    console.log('create', response);
                    setProjects(response.leadElements);
                    setActiveProject(response.leadElements.length - 1);
                });
            }
        } else {
            if (leadElementId) {
                updateFromStock({
                    leadElementId,
                    internalId: selection.vehicle.sku,
                }).then((response) => {
                    console.log('update', response);
                    setProjects(response.leadElements);
                });
            } else {
                createFromStock({
                    lead: informations.identifier,
                    internalId: selection.vehicle.sku,
                }).then((response) => {
                    console.log('create', response);
                    setProjects(response.leadElements);
                    setActiveProject(response.leadElements.length - 1);
                });
            }
        }
        setShowInOutChoice(false);
    };

    const removeVehicle = (index: number) => {
        Swal.fire({
            title: Intl.formatMessage({ id: 'CUSTOMERS.VIEW.LIFE.DETAIL.PROJECT.REMOVE.TITLE' }),
            text: Intl.formatMessage({ id: 'CUSTOMERS.VIEW.LIFE.DETAIL.PROJECT.REMOVE.SUBTITLE' }),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: Intl.formatMessage({ id: 'TRANSLATOR.YES' }),
            cancelButtonText: Intl.formatMessage({ id: 'TRANSLATOR.NO' }),
            customClass: {
                confirmButton: 'btn mr-3 btn-primary',
                cancelButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
            preConfirm: () => {
                Swal.showLoading();
                remove({
                    leadElementId: vehicle.leadElementId,
                }).then((response) => {
                    console.log('remove', response);
                    setProjects(response.leadElements);
                    if (activeProject > response.leadElements.length - 1) {
                        setActiveProject(response.leadElements.length - 1);
                    }
                });
            },
        });
    };

    const showInOutChoiceVehicle = (vehicleToShow: any = null) => {
        leadElementId = vehicleToShow?.leadElementId;
        dispatch(
            actions.modalChange('in_out_choice_vehicle', {
                vehicleToShow,
            }),
        );
    };

    const getRibbons = () => {
        const operations =
            (typeof vehicle?.operations === 'string' ? JSON.parse(vehicle?.operations) : vehicle?.operations) || [];

        return (
            <div className="kt-ribbon__wrapper">
                {operations.map((operation: any, index: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={`operation_${index}`} className="mt-2 kt-ribbon__target kt-ribbon--round">
                        {operation.name}
                    </div>
                ))}
            </div>
        );
    };

    useEffect(() => {
        setLoading(
            createOutOfStockLoading ||
                updateOutOfStockLoading ||
                createFromStockLoading ||
                updateFromStockLoading ||
                removeLoading,
        );
    }, [
        createOutOfStockLoading,
        updateOutOfStockLoading,
        createFromStockLoading,
        updateFromStockLoading,
        removeLoading,
    ]);

    return (
        <div className="d-flex flex-wrap" style={{ height: '100%' }}>
            {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            <div className="col d-flex flex-column">
                {projects?.length > 0 && (
                    <>
                        <div className="d-flex flex-lg-nowrap flex-wrap align-items-lg-center align-items-end flex-column-reverse flex-lg-row justify-content-between mb-6">
                            <div className="d-flex flex-lg-row flex-column w-lg-auto w-100">
                                {projects.map((product: any, index: number) => (
                                    <Button
                                        variant="link"
                                        key={product.reference + '-' + index}
                                        onClick={() => setActiveProject(index)}
                                        className={`w-lg-auto w-100 px-4 py-2 project d-flex justify-content-between align-items-center ${
                                            activeProject === index &&
                                            `event__origin--${typeSlug} event__origin-bg-opacity--${typeSlug}`
                                        }`}
                                    >
                                        <span>{`${Intl.formatMessage({
                                            id: 'CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.CLIENT_PROJECT',
                                        })}${index + 1}`}</span>
                                        {event?.informations?.identifier &&
                                            !event?.informations?.processType &&
                                            !isLeadClosed && (
                                                <Button
                                                    variant="link"
                                                    onClick={() => removeVehicle(index)}
                                                    className="p-0 m-0"
                                                >
                                                    <i
                                                        className={`la la-lg la-trash ml-2 p-0 ${
                                                            activeProject === index && `event__origin--${typeSlug}`
                                                        }`}
                                                    />
                                                </Button>
                                            )}
                                    </Button>
                                ))}
                            </div>
                            {event?.informations?.identifier && !event?.informations?.processType && !isLeadClosed && (
                                <Permission permissions={['LEAD_ADD_VEHICLE_LEAD']}>
                                    {projects?.length < MAX_VEHICLES && !isLeadClosed && (
                                        <Button
                                            variant="link"
                                            className={`mb-lg-0 mb-5 px-3 py-2 project event__origin-bg-opacity--${typeSlug}`}
                                            onClick={() => showInOutChoiceVehicle()}
                                        >
                                            <i className={`la la-plus la-lg p-0 event__origin--${typeSlug}`} />
                                        </Button>
                                    )}
                                </Permission>
                            )}
                        </div>
                        {projects.map((product: any, index: number) => (
                            <React.Fragment key={product.reference + '-' + index}>
                                {activeProject === index && (
                                    <>
                                        <RowInfo
                                            label={Intl.formatMessage({ id: `CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.MAKE` })}
                                            value={product?.make || '-'}
                                        />
                                        <RowInfo
                                            label={Intl.formatMessage({
                                                id: `CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.MODEL`,
                                            })}
                                            value={product?.model || '-'}
                                        />
                                        <RowInfo
                                            label={Intl.formatMessage({
                                                id: `CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.VERSION`,
                                            })}
                                            value={product?.version || '-'}
                                        />
                                        {product?.type && (
                                            <RowInfo
                                                label={Intl.formatMessage({
                                                    id: `CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.TYPE.VEHICLE`,
                                                })}
                                                value={product.type || '-'}
                                            />
                                        )}
                                        {product?.color && (
                                            <RowInfo
                                                label={Intl.formatMessage({
                                                    id: `CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.COLOR`,
                                                })}
                                                value={product.color || '-'}
                                            />
                                        )}
                                        <RowInfo
                                            label={Intl.formatMessage({ id: `CUSTOMERS.MODAL.PURCHASE.VIN` })}
                                            value={product.serialNumber || '-'}
                                        />
                                        <RowInfo
                                            label={Intl.formatMessage({
                                                id: `CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.IMMAT`,
                                            })}
                                            value={getRegistrationTemplate(product)}
                                        />
                                        {product?.registrationNumberDate && (
                                            <RowInfo
                                                label={Intl.formatMessage({
                                                    id: `CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.IMMAT.DATE`,
                                                })}
                                                value={
                                                    dayjs(product.registrationNumberDate).format('DD/MM/YYYY') || '-'
                                                }
                                            />
                                        )}
                                        <RowInfo
                                            label={Intl.formatMessage({
                                                id: `CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.DATE.OF.CIRCULATION`,
                                            })}
                                            value={
                                                (product?.dateFirstRegistration &&
                                                    dayjs(product?.dateFirstRegistration).format('DD/MM/YYYY')) ||
                                                '-'
                                            }
                                        />
                                        <RowInfo
                                            label={Intl.formatMessage({
                                                id: `CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.LAST.KNOWN.MILEAGE`,
                                            })}
                                            value={
                                                product?.lastMileageKnow !== '0.0' ? (
                                                    <NumberFormat
                                                        value={product?.lastMileageKnow}
                                                        thousandSeparator=" "
                                                        displayType="text"
                                                        suffix=" km"
                                                    />
                                                ) : (
                                                    '-'
                                                )
                                            }
                                        />
                                        {!informations?.processType && (
                                            <RowInfo
                                                label={Intl.formatMessage({
                                                    id: `CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.NUM_${
                                                        product?.registration || product?.license_number ? 'VO' : 'VN'
                                                    }`,
                                                })}
                                                value={getVehicleInternalIdTemplate(product)}
                                            />
                                        )}
                                        {product?.policeNumber && (
                                            <RowInfo
                                                label={Intl.formatMessage({
                                                    id: 'CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.POLICE_NUMBER',
                                                })}
                                                value={product?.policeNumber || '-'}
                                            />
                                        )}
                                        {product?.reference && informations?.leadTypeSlug === 'achat_vo_online' && (
                                            <RowInfo
                                                label={Intl.formatMessage({
                                                    id: 'CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.REFERENCE',
                                                })}
                                                value={product?.reference || '-'}
                                            />
                                        )}
                                        {(informations?.leadTypeSlug === 'local' ||
                                            informations?.leadTypeSlug === 'vo') && (
                                            <RowInfo
                                                label={Intl.formatMessage({
                                                    id: 'CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.REFERENCE_AUTOSPHERE',
                                                })}
                                                value={product?.reference || '-'}
                                            />
                                        )}
                                        {informations?.identifier && getWarrantyTemplate(product) !== '-' && (
                                            <RowInfo
                                                label={Intl.formatMessage({
                                                    id: 'CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.GUARANTY',
                                                })}
                                                value={getWarrantyTemplate(product)}
                                            />
                                        )}
                                        {(informations?.leadTypeSlug === 'achat_vo_online' ||
                                            (informations?.processType &&
                                                informations?.processType.toLowerCase() === 'securycar')) && (
                                            <RowInfo
                                                label={Intl.formatMessage({
                                                    id: 'CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.STORAGE_PLACE',
                                                })}
                                                value={informations?.dealership?.name || '-'}
                                            />
                                        )}
                                        {(informations?.leadTypeSlug === 'local' ||
                                            informations?.leadTypeSlug === 'vo' ||
                                            informations?.leadTypeSlug === 'prestation_apv') && (
                                            <RowInfo
                                                label={Intl.formatMessage({
                                                    id: 'CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.TVA',
                                                })}
                                                value={getRecoverableVat(product)}
                                            />
                                        )}
                                        {!informations?.processType &&
                                            !['local', 'constructeur', 'fid_vo', 'fid_vn'].includes(
                                                informations?.leadTypeSlug,
                                            ) && (
                                                <Comment
                                                    comment={event?.project?.customerProject || '-'}
                                                    readOnly
                                                    label={`COMMENT_PROJECT${isLeadAutobernard ? '_AUTOBERNARD' : ''}`}
                                                    containerClassName="mb-4"
                                                />
                                            )}
                                        {informations?.processType === 'ORDERS_WEB_RESUME' && (
                                            <RowInfo
                                                label={Intl.formatMessage({
                                                    id: 'CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.POLICE_NUMBER',
                                                })}
                                                value={event?.fullWeb.orderwebVNumpoli}
                                            />
                                        )}
                                    </>
                                )}
                            </React.Fragment>
                        ))}
                    </>
                )}
                {
                    // lead without vehicle but with comment project contact
                    !(projects?.length > 0) &&
                        !informations?.processType &&
                        !['local', 'constructeur', 'fid_vo', 'fid_vn'].includes(informations?.leadTypeSlug) &&
                        event?.project?.customerProject && (
                            <Comment
                                comment={event?.project?.customerProject || '-'}
                                readOnly
                                label={
                                    ['dealership_website', 'fidcar'].includes(informations?.leadOriginSlug)
                                        ? 'COMMENT'
                                        : `COMMENT_PROJECT${isLeadAutobernard ? '_AUTOBERNARD' : ''}`
                                }
                            />
                        )
                }
                {
                    // lead constructeur audi or one lead with comment project contact
                    customerProject && isLeadAudiOrOneLead && (
                        <RowInfo
                            label={Intl.formatMessage({
                                id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.COMMENT_PROJECT_MANUFACTURER',
                            })}
                            value={event?.project?.customerProject}
                            useEditor
                        />
                    )
                }
            </div>
            {!isVcuEvent && (
                <div className="col-lg-5 mt-lg-0 mt-8">
                    {vehicle && event?.informations.leadType !== undefined && (
                        <VehicleInfo
                            event={event}
                            dealership={informations?.dealership?.identifier}
                            vehicle={vehicle}
                            setShowVehicle={() => showInOutChoiceVehicle(vehicle)}
                            ribbons={getRibbons()}
                            canUpdate={
                                event?.informations?.identifier && !event?.informations?.processType && !isLeadClosed
                            }
                        />
                    )}
                    {!vehicle && (
                        <Permission permissions={['LEAD_ADD_VEHICLE_LEAD']}>
                            <LeadLocalVehicleProject
                                setShowVehicle={() => showInOutChoiceVehicle()}
                                readOnly={!event?.informations?.identifier || isLeadClosed}
                            />
                        </Permission>
                    )}
                </div>
            )}
            <InOutChoiceVehicle
                showModal={showInOutChoice}
                setShowModal={setShowInOutChoice}
                onSelect={onSelectVehicle}
            />
        </div>
    );
};

export default VehicleProject;
