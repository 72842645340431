import * as dayjs from 'dayjs';
import { Column } from 'primereact/column';
import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { routeTo } from '@app/helpers/RoutesHelper';
import { empty } from '@app/helpers/ToolsHelper';

import { fetchOperations } from '@app/store/apv/commercial-operation/commercial-operation.thunk';

import { Filters } from '@app/partials/layout/Filters';

import useFilters from '../../../hooks/useFilters';
import useTableHandlers from '../../../hooks/useTableHandlers';
import CalendarRangeFilter from '../../../partials/content/CalendarRangeFilter';
import DropdownFilter from '../../../partials/content/DropdownFilter';
import FiltersProvider from '../../../partials/content/FiltersProvider';
import HoCDataTable from '../../../partials/content/HoCDataTable';
import InputFilter from '../../../partials/content/InputFilter';
import Label from '../../../partials/content/Label';
import Permission from '../../../partials/content/Permission';
import RefreshTable from '../../../partials/content/RefreshTable';
import SearchInputFilter from '../../../partials/content/SearchInputFilter';
import ROUTES from '../../../router/Routes';

function CommercialOperations({ history }) {
    const { operations, totalRecords, filtersContent, loading } = useSelector((state) => state.commercialOperation);
    const { items, start, filters, sortField, sortOrder } = useFilters({
        name: 'apv_operations',
    });
    const { handleFilter, handlePagination, handleSort, refresh } = useTableHandlers({
        filterStore: 'apv_operations',
        fetchAction: fetchOperations,
    });

    const calendarRangeFilter = (start, end) => <CalendarRangeFilter start={start} end={end} onChange={handleFilter} />;

    const inputFilter = (name) => <InputFilter name={name} onChange={handleFilter} />;

    const selectDealershipDropdown = () => (
        <DropdownFilter
            optionLabel="name"
            filterBy="name"
            name="dealershipId"
            options={
                filtersContent?.commercialOperationDealerships &&
                Object.values(filtersContent.commercialOperationDealerships).map((elm) => ({
                    name: elm.dealershipSource.usual_name,
                    key: elm.id,
                }))
            }
            onChange={handleFilter}
        />
    );

    const selectStatusDropdown = () => (
        <DropdownFilter
            optionLabel="name"
            filterBy="name"
            name="active"
            options={[
                {
                    name: 'Actif',
                    key: true,
                },
                {
                    name: 'Inactif',
                    key: false,
                },
            ]}
            onChange={handleFilter}
        />
    );

    const selectTypeDropdown = () => (
        <DropdownFilter
            optionLabel="name"
            filterBy="name"
            name="commercialOperationTypeId"
            options={
                filtersContent?.commercialOperationType &&
                Object.values(filtersContent.commercialOperationType).map((elm) => ({
                    name: elm.name,
                    key: elm.id,
                }))
            }
            onChange={handleFilter}
        />
    );

    const showAddOperation = () => {
        history.push(routeTo(ROUTES.OPERATION.PATH.CREATE));
    };

    const dealershipBodyTemplate = (operation) =>
        operation?.dealerships &&
        operation?.dealerships
            .filter((dealership) => !empty(dealership?.compta))
            .map((dealership) => dealership?.dealershipSource?.usual_name)
            .join(', ');

    const dateTimeBodyTemplate = (operation) => dayjs(operation.updated).format('DD/MM/YYYY [à] HH [h] mm');

    const dateIntervalBodyTemplate = (operation) => {
        const dateStart = dayjs(operation.dateStart).format('DD/MM/YYYY');
        const dateEnd = dayjs(operation.dateEnd).format('DD/MM/YYYY');
        return `${dateStart} au ${dateEnd}`;
    };

    const packageServiceBodyTemplate = (operation) => {
        const title = operation?.package ? operation?.package?.name : operation?.additionalService?.name || null;
        return (
            <>
                <span className={`${title && 'mr-3'}`}>{title}</span>
                <Label variant="primary">
                    <i className={`mr-2 fas ${operation?.package ? 'fa-car-battery' : 'fa-air-freshener'}`} />
                    {operation?.package ? 'Forfait' : 'Produit'}
                </Label>
            </>
        );
    };

    const statusBodyTemplate = (operation) => (
        <span className={operation?.active ? 'text-success' : 'text-danger'}>
            {operation?.active ? '• Actif' : '• Inactif'}
        </span>
    );

    const actionsBodyTemplate = (operation) => (
        <Link
            to={routeTo(ROUTES.OPERATION.PATH.UPDATE, {
                id: operation.id,
            })}
        >
            <Button className="p-2 px-3" variant="secondary">
                <i className="fa fa-pen" />
                Modifier
            </Button>
        </Link>
    );

    const cols = [
        {
            field: 'id',
            header: Intl.formatMessage({ id: 'APV.OPERATION.TABLE.HEAD.REFERENCE' }),
            style: { width: '5%' },
        },
        {
            field: 'name',
            header: Intl.formatMessage({ id: 'APV.OPERATION.TABLE.HEAD.NAME' }),
            style: { width: '15%' },
            filter: true,
            filterElement: inputFilter('commercialOperationName'),
        },
        {
            field: 'zone',
            header: Intl.formatMessage({ id: 'APV.OPERATION.TABLE.HEAD.ZONE' }),
            style: { width: '10%' },
            filter: true,
            filterElement: selectTypeDropdown(),
            body: (operation) => operation?.commercialOperationType?.name || '',
        },
        {
            field: 'package_service',
            header: Intl.formatMessage({ id: 'APV.OPERATION.TABLE.HEAD.PACKAGE.PRODUCT' }),
            style: { width: '15%' },
            filter: true,
            filterElement: inputFilter('packageOrAdditionalService'),
            body: packageServiceBodyTemplate,
        },
        {
            field: 'discount',
            header: Intl.formatMessage({ id: 'APV.OPERATION.TABLE.HEAD.DISCOUNT' }),
            style: { width: '5%' },
            body: (operation) =>
                operation?.percentage
                    ? `- ${operation.percentage} %`
                    : operation.price
                    ? `- ${operation.price} €`
                    : Intl.formatMessage({ id: 'APV.OPERATION.FREE' }),
        },
        {
            field: 'dealership',
            header: Intl.formatMessage({ id: 'APV.OPERATION.TABLE.HEAD.DEALERSHIP' }),
            style: { width: '10%' },
            filter: true,
            filterElement: selectDealershipDropdown(),
            body: dealershipBodyTemplate,
        },
        {
            field: 'date',
            header: Intl.formatMessage({ id: 'APV.OPERATION.TABLE.HEAD.DATE' }),
            style: { width: '10%' },
            filter: true,
            filterElement: calendarRangeFilter('dateStartOp', 'dateEndOp'),
            body: dateIntervalBodyTemplate,
        },
        {
            field: 'dateUpdate',
            header: Intl.formatMessage({ id: 'APV.OPERATION.TABLE.HEAD.DATEUPDATE' }),
            style: { width: '10%' },
            filter: false,
            body: dateTimeBodyTemplate,
        },
        {
            field: 'status',
            header: Intl.formatMessage({ id: 'APV.OPERATION.TABLE.HEAD.STATUS' }),
            style: { width: '10%' },
            filter: true,
            filterElement: selectStatusDropdown(),
            body: statusBodyTemplate,
        },
        {
            field: 'action',
            header: Intl.formatMessage({ id: 'APV.OPERATION.TABLE.HEAD.ACTION' }),
            style: { width: '10%' },
            filter: false,
            body: actionsBodyTemplate,
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <Card>
            <Card.Body>
                <FiltersProvider value={filters}>
                    <Filters>
                        <Row>
                            <RefreshTable count={totalRecords} onClick={refresh} />
                            <Col className="mr-4" lg={5} offset={2}>
                                <SearchInputFilter
                                    onChange={handleFilter}
                                    name="research"
                                    placeholder={Intl.formatMessage({ id: 'APV.OPERATION.SEARCH.PLACEHOLDER' })}
                                />
                            </Col>
                            <Permission permissions={['APV_ADD_COMMERCIAL_OPERATION']}>
                                <Button variant="outline-primary" onClick={() => showAddOperation()}>
                                    {Intl.formatMessage({ id: 'APV.OPERATION.ADD.BUTTON' })}
                                </Button>
                            </Permission>
                        </Row>
                    </Filters>
                    <HoCDataTable
                        value={operations}
                        totalRecords={totalRecords}
                        lazy
                        loading={loading}
                        paginator
                        onFilter={handleFilter}
                        onPage={handlePagination}
                        onSort={handleSort}
                        paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                        currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                        rows={items}
                        first={start}
                        sortField={sortField}
                        sortOrder={sortOrder}
                        removableSort
                        filterDisplay="row"
                        rowsPerPageOptions={[25, 50, 100]}
                        emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                    >
                        {dynamicColumns}
                    </HoCDataTable>
                </FiltersProvider>
            </Card.Body>
        </Card>
    );
}

export default withRouter(CommercialOperations);
