export interface Vehicle {
    sku?: string;
    vehicleInternalId?: string;
    internal_id: string;
    external_id: string;
    dealer_id?: string;
    dealershipId?: string;
    dealer_name: string;
    dealer_usual_name: string;
    dealer_group_id: string;
    dealer_group_usual_name: string;
    dealer_holding_id: string;
    dealer_holding_usual_name: string;
    dealer_pole_id: string;
    dealer_pole_usual_name: string;
    dealer_affaire_id: string;
    dealer_affaire_usual_name: string;
    source: string;
    type: string;
    brand?: string;
    make?: string;
    model: string;
    version: string;
    serial_number: string;
    police_number: null;
    type_number: null;
    license_number?: string;
    registration?: string;
    serialNumber?: string;
    license_certificate_number: null;
    date_car_registration_document: null;
    date_first_registration: null;
    date_first_circulation: null;
    segment: null;
    body_type: string;
    fuel_type?: string;
    fuelType?: string;
    gearbox: null;
    transmission: string;
    gender: null;
    places: number;
    doors: number;
    fiscal_power: number;
    real_power: number;
    engine_displacement: null;
    urban_consumption: number;
    extra_urban_consumption: number;
    mixed_consumption: number;
    co2_emissions: number;
    co2_wltp: null;
    co2_slice: string;
    crit_air: null;
    crvo_invoice: string | null;
    euro_norm: null;
    main_color: null;
    body_color: string;
    interior_color: null;
    factory_color: null;
    paint_type: null;
    seats_color: null;
    mileage: number;
    guaranteed_mileage: boolean;
    price: number;
    recoverable_vat?: boolean;
    recoverableVat?: boolean;
    vat_percentage: null;
    date_purchase: null;
    destination: null;
    provenance: null;
    origin: null;
    warranty_code: string;
    warranty_label: string;
    warranty: string;
    warranty_month: null;
    argus_key: string;
    compliance_level_argus: null;
    eurotax_typnatcode: null;
    recent_arrival: boolean;
    almost_new: boolean;
    little_price: boolean;
    low_mileage: boolean;
    notebook: null;
    title: null;
    description: null;
    equipments: string;
    equipments_argus_option: string;
    equipments_option: string;
    images: {
        s: string[];
        m: string[];
        l: string[];
        xl: string[];
        marketplace: string[];
    };
    default_image?: string;
    defaultImage?: string;
    image?: string;
    city: string;
    address: string;
    postcode: string;
    lat: number;
    lon: number;
    loc: {
        lat: number;
        lon: number;
    };
    gearsType?: string;
    gears_type?: string;
    catalog_price: number;
    discount_percentage: number;
    cotations: null;
    cotation: [];
    cotation_legacy: [];
    brand_model: string;
    dealer_ekip: null;
    carpass_uri: null;
    first_hand: boolean;
    old_price: null;
    kind: string;
    original_model: string;
    date_availability: null;
    year?: string;
    productionYear?: string;
    dateFirstRegistration?: string;
    conversion_bonus: boolean;
    repair_costs: 0;
    seo_title?: string;
    seoTitle?: string;
    meta_tag_description: string;
    department_code: string;
    group_internal_id: number;
    date_in_stock: string;
    previous_price: number;
    operations: string;
    date_stock_bdme: null;
    is_solovi: boolean;
    dealer_external: boolean;
    dealer_brand: string;
    dealer_parent: string;
    siret: string;
    code_lacentrale: string;
    is_stampyt: boolean;
    last_stampyt_check: null;
    last_cotations_update: null;
    is_photos_classic: boolean;
    is_degraded_360: boolean;
    brands_models: null;
    brands_models_bodytypes: null;
    brands_models_fueltypes: null;
    brands_models_cities: null;
    body_types_brands: null;
    fuel_types_brands: null;
    created_at: string;
    is_cs: boolean;
    is_delivery: boolean;
    is_afr: boolean;
    publication: boolean;
    is_360: boolean;
    is_360_bca: boolean;
    format_360: number;
    source_360?: 1 | '2' | 3 | null;
    source360?: number;
    nb_photos_360: number;
    certified_zero_default: boolean;
    player_360?: string[];
    player360?: string[];
    photosDefaults?: string[];
    photos_defaults?: string[];
    is_damages: boolean;
    state: null;
    nbImages?: number;
    nb_photos?: number;
    cnit: null;
    is_fluidite?: boolean;
    isFluidity?: boolean;
    is_booked: boolean;
    is_prestige: boolean;
    source_body_color: string;
    cities: string;
    popularity: number;
    is_warranty_fw: boolean;
    publishable_in_indices: [
        {
            name: string;
            active: boolean;
        },
        {
            name: string;
            active: boolean;
        },
    ];
    images_bypass: boolean;
    date_first_publication: string;
    fw_eligibility_warning: string[];
    afr_publication_warning: null | string;
    has_60_months: boolean;
    eligibilities: null;
    is_warranty_valid: boolean;
    cotations_classic: null;
    cotations_balloon: null;
    is_cotations: null;
    site_id: string;
    site_code: string;
    site_label: string;
    location?: string;
    location_id: string;
    location_code: string;
    location_label: string;
    stock_status: string;
    advertiser_base_pvo_code: string;
    product: string;
    schema: string;
    code_pvo: string;
    finance_credit: number;
    finance_loa: number;
    finance_lld: number;
    finance_amount: null;
    dealer_discount: number;
    trade_in_discount: number;
    green_discount: number;
    oem_discount: number;
    reference: string;
    afr_active_dealership: boolean;
    cs_active_dealership: boolean;
    fw_active_dealership: boolean;
    is_eligible_fw: boolean;
    is_eligible_cs: boolean;
    is_eligible_afr: boolean;
    is_eligible_fluidite: boolean;
    is_eligible_credit?: boolean;
    is_eligible_classic?: boolean;
    is_eligible_balloon?: boolean;
    credit?: boolean;
    comptant?: boolean;
    is_eligible_loa?: boolean;
    loa?: boolean;
    statistics: {
        afr_vo_published: boolean;
        afr_vo_not_published: boolean;
        afr_vo_eligible: boolean;
        afr_vo_not_eligible: boolean;
        afr_vn_published: boolean;
        afr_vn_not_published: boolean;
        afr_vn_eligible: boolean;
        afr_vn_not_eligible: boolean;
        afr_fw_published: boolean;
        afr_fw_not_published: boolean;
        afr_fw_eligible: boolean;
        afr_total_published: boolean;
        afr_total_eligible: boolean;
    };
    crvo: null | {
        site_name: string | null;
        site_id: number | null;
        description: string | null;
        events: [];
    };
    timeline: {
        days: number;
        days_accounting: number;
        days_last_publication: number;
        events: [
            {
                type: string;
                label: string;
                description: string;
                color: string;
                show_days: boolean;
                details: null;
                date: string;
            },
            {
                type: string;
                label: string;
                description: string;
                color: string;
                show_days: boolean;
                details: null;
                date: string;
            },
            {
                type: null;
                label: string;
                description: string;
                color: string;
                show_days: boolean;
                date: string;
            },
        ];
    };
    is_crvo: boolean;
    is_excluded: boolean;
    is_waiting_images: boolean;
    export_stock_cgi_update: boolean;
    has_pricing_reco: boolean;
    pricing: null | VehiclePricing;
    anomaly: string | null;
    damages_tags: null;
    codegta: null;
    is_approved: boolean;
    parking_aid: boolean;
    reversing_radar: boolean;
    gps: boolean;
    head_up_display: boolean;
    bluetooth: boolean;
    air_conditioning: boolean;
    cruise_control: boolean;
    sunroof: boolean;
    panoramic_roof: boolean;
    leather_interior: boolean;
    led_lights: boolean;
    isofix: boolean;
    three_indivivual_seats: boolean;
    alloy_wheels: boolean;
    internal_fuel_type: string;
    internal_seats_color: null;
    internal_body_type: string;
    internal_paint_type: null;
    internal_transmission: string;
    internal_type: string;
    internal_notebook: null;
    internal_gears_type: string;
    internal_body_color: string;
    internal_interior_color: null;
    internal_kind: string;
    origin_fuel_type: string;
    origin_body_type: string;
    origin_type: string;
    origin_gears_type: string;
    origin_body_color: string;
    origin_kind: string;
    origin_transmission: string;
    slug_brand: string;
    slug_model: string;
    slug_fuel_type: string;
    slug_body_type: string;
    slug_type: string;
    slug_gears_type: string;
    slug_body_color: string;
    slug_kind: string;
    slug_transmission: string;
}

export interface PricingEvent {
    status: {
        name: string;
        slug: string;
    };
    oldPrice: null | number;
    newPrice: null | number;
    recoPrice: null | number;
    createdAt: {
        date: Date;
        timezone_type: number;
        timezone: string;
    };
    variationNewOld: number;
    variationReco: number;
    reason: string;
    comment: string;
    author: string;
    role: string;
    refusalStreak: number;
    check?: {
        status: {
            name: string;
            slug: string;
        };
        author: string;
        role: string;
        date: {
            date: Date;
            timezone_type: number;
            timezone: string;
        };
        event?: {
            status: {
                name: string;
                slug: string;
            };
            newPrice: number;
            reason: string;
            comment: string;
        };
    };
}

export interface PricingDuration {
    duration: number;
}

export interface VehiclePricing {
    averageSellingTime: number;
    createdAt: {
        date: string;
        timezone_type: number;
        timezone: string;
    };
    spotPrice: number;
    recoPrice: number;
    variationReco: number;
    thumb: null;
    radar: null;
    event?: PricingEvent;
    rating: number;
    expirationDate?: string;
    recoGeneratedAt?: string;
}

export interface VehicleHistoricalPrice {
    id: number;
    attribute: string;
    newValue?: string;
    previousValue?: string;
    updatedAt?: Date;
    createdAt?: Date;
    updatedBy?: string;
    pricingStatus?: {
        description: string;
        slug: string;
        updatedAt: Date;
    };
    pricingReason?: {
        description: string;
        updatedAt: Date;
    };
    author?: null;
    authorRole?: null;
    comment?: null;
    type: string;
    oldPrice?: number;
    newPrice?: number;
    recoPrice?: number;
}

export interface PricingState {
    state: PricingStateEnum;
}

export enum PricingStateEnum {
    AVAILABLE = 'available',
    IN_PROGRESS = 'in_progress',
    PLANNED = 'planned',
}
