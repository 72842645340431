import classNames from 'classnames';
import dayjs from 'dayjs';
import parseMax from 'libphonenumber-js/max';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';

import { isAMail, isASiren } from '@app/helpers/ToolsHelper';

import { getCities } from '@app/crud/securyweb/securyweb.crud';
import { Cities, StatusContractSlug } from '@app/crud/securyweb/securyweb.types';

import variantTypes from '@app/constants';
import { useAppSelector, useFetch } from '@app/hooks';
import {
    BORNE_CUSTOMER_PATH,
    CUSTOMER_PATH,
    HOUSING_MODE,
    HOUSING_TYPE,
    LANGUAGE,
    MARITAL_STATUS,
    PRO_CATEGORY,
} from '@app/pages/customers/Modals/SecuryCarContract/FormOptions';
import FormPartTitle from '@app/pages/customers/Modals/SecuryCarContract/FormPartTitle';
import {
    isBorneProduct,
    isFieldDisabled,
    isFieldRequired,
} from '@app/pages/customers/Modals/SecuryCarContract/Helpers/SecurywebHelper';
import useFilteredBrandsAndModels from '@app/pages/customers/Modals/SecuryCarContract/Hooks/useFilteredBrandsAndModels';

import SwitchInput from '@app/partials/content/SwitchInput';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';
import EditableDatePicker from '@app/partials/content/DateTimePicker/EditableDatePicker';

const SecuryCarContractStepSubscriber = () => {
    const Intl = useIntl();
    const methods = useFormContext();
    const { control, formState, setValue, getValues, watch } = methods;
    const [isSameDatas, setIsSameDatas] = useState(false);
    const { errors } = formState;

    const { data: customer } = useAppSelector((state) => state.customer.customer);
    const { context } = useAppSelector((state) => state.auth.userData);
    const { params } = useAppSelector((state) => state.modal);

    const subscriber = watch('subscriber');
    const dealership = watch('dealership');
    const product = watch('product');
    const isSteSelected = watch('subscriber.civility') === 'STE';
    const [isBorne, setIsBorne] = useState(isBorneProduct(product));
    const productsWithCompanyOption = ['874', '804', '884', '894', '226'];
    const customerPathLabel = isBorne ? 'CUSTOMER_PATH' : 'CUSTOMER_ORIGIN';
    const { filteredBrands, filteredModels, filterBrands, filterModels, loadModels } = useFilteredBrandsAndModels();
    const formBrandValue = watch('vehicle.brand');

    useEffect(() => {
        setIsBorne(isBorneProduct(product));
    }, [product]);

    const {
        fetch: fetchBeneficiaryCities,
        data: beneficiaryCities,
        setData: setBeneficiaryCities,
    } = useFetch({
        fetchAction: getCities,
        resultInterceptor: (response) => {
            const fetchedCities = response.result as Cities[];
            if (customer.city && !watch('beneficiary.city')) {
                const foundCity = fetchedCities?.find(
                    (city) => city.localite.toLowerCase() === customer.city.toLowerCase(),
                );
                setValue('beneficiary.city', foundCity?.localite);
                setValue('beneficiary.cityId', foundCity?.id);
            }
            return fetchedCities;
        },
    });

    const { fetch: fetchSubscriberCities, data: subscriberCities } = useFetch({
        fetchAction: getCities,
        resultInterceptor: (response) => {
            const fetchedCities = response.result as Cities[];
            if (customer.city && !watch('subscriber.city')) {
                const foundCity = fetchedCities?.find(
                    (city) => city.localite.toLowerCase() === customer.city.toLowerCase(),
                );
                setValue('subscriber.city', foundCity?.localite);
                setValue('subscriber.cityId', foundCity?.id);
            }

            if (isSameDatas) {
                setBeneficiaryCities(fetchedCities);
            }
            return fetchedCities;
        },
    });

    const mapCivility = (civility: string) => {
        if (civility === 'mr') {
            return 'M';
        } else if (civility === 'mme') {
            return 'MME';
        }
    };

    useEffect(() => {
        if (isSameDatas) {
            if (subscriberCities) {
                setBeneficiaryCities(subscriberCities);
            }

            Object.keys(subscriber).forEach((key) => {
                setValue(`beneficiary.${key}`, subscriber[key], { shouldValidate: formState.isSubmitted });
            });
        }
    }, [isSameDatas]);

    useEffect(() => {
        const hasParams = params?.formValues?.subscriber?.zipcode || params?.formValues?.beneficiary?.zipcode;
        if (customer.zipcode && !hasParams)
            fetchSubscriberCities(customer.zipcode)
                .then(() => {})
                .catch(() => {
                    toast({
                        variant: variantTypes.DANGER,
                        message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                    });
                });
    }, [customer]);

    useEffect(() => {
        // prettier-ignore
        if (params && params?.formValues) { // NOSONAR
            Object.keys(params?.formValues).forEach((key) => {
                if (key === 'product' || key === 'typeContrat') return;
                return params?.formValues?.[key] ? setValue(key, params?.formValues?.[key]) : {};
            });

            if (params?.formValues?.beneficiary?.zipcode) {
                fetchBeneficiaryCities(params?.formValues?.beneficiary?.zipcode);
            }
            if (params?.formValues?.subscriber?.zipcode) {
                fetchSubscriberCities(params?.formValues?.subscriber?.zipcode);
            }
        }
    }, [params]);

    useEffect(() => {
        if (formBrandValue) {
            loadModels(formBrandValue);
        }
    }, [formBrandValue]);

    const renderItemTitle = (itemTitle: string) => <FormattedMessage id={itemTitle} />;

    // fields should be refactored to reduce its Cognitive Complexity
    // prettier-ignore
    const fields = (userType: 'subscriber' | 'beneficiary') => { // NOSONAR
        const isSubscriber = userType === 'subscriber';

        return (
            <>
                <Row>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.CIVILITY" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name={`${userType}.civility`}
                                {...(isSubscriber && { defaultValue: mapCivility(customer?.civility?.toLowerCase()) })}
                                rules={{
                                    required:
                                        isFieldRequired(`${userType}.civility`, watch('statut')) &&
                                        Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                }}
                                disabled={
                                    (!isSubscriber && isSameDatas) ||
                                    isFieldDisabled(`${userType}.civility`, watch('statut'), isBorne)
                                }
                                render={({ value, onChange }) => {
                                    if (isSubscriber) {
                                        return (
                                            <div className="d-flex flex-wrap gap-6">
                                                {(product !== '874' || dealership === 'MUL027') && (
                                                    <div className="d-flex align-items-center">
                                                        <RadioButton
                                                            inputId="mme"
                                                            value="MME"
                                                            onChange={(e) => {
                                                                onChange(e.value);
                                                                if (isSameDatas) {
                                                                    setValue('beneficiary.civility', e.value);
                                                                }
                                                            }}
                                                            checked={value === 'MME'}
                                                            disabled={
                                                                (!isSubscriber && isSameDatas) ||
                                                                isFieldDisabled(`${userType}.civility`, watch('statut'))
                                                            }
                                                        />
                                                        <label htmlFor="mme" className="ml-2 my-0">
                                                            <FormattedHTMLMessage id="TRANSLATOR.MRS" />
                                                        </label>
                                                    </div>
                                                )}
                                                {(product !== '874' || dealership === 'MUL027') && (
                                                    <div className="d-flex align-items-center">
                                                        <RadioButton
                                                            inputId="mr"
                                                            value="M"
                                                            onChange={(e) => {
                                                                onChange(e.value);
                                                                if (isSameDatas) {
                                                                    setValue('beneficiary.civility', e.value);
                                                                }
                                                            }}
                                                            checked={value === 'M'}
                                                            disabled={
                                                                (!isSubscriber && isSameDatas) ||
                                                                isFieldDisabled(`${userType}.civility`, watch('statut'))
                                                            }
                                                        />
                                                        <label htmlFor="mr" className="ml-2 my-0">
                                                            <FormattedHTMLMessage id="TRANSLATOR.MR" />
                                                        </label>
                                                    </div>
                                                )}
                                                {(productsWithCompanyOption.includes(product) ||
                                                    dealership === 'MUL027') && (
                                                    <div className="d-flex align-items-center">
                                                        <RadioButton
                                                            inputId="company"
                                                            value="STE"
                                                            onChange={(e) => onChange(e.value)}
                                                            checked={value === 'STE'}
                                                            disabled={
                                                                (!isSubscriber && isSameDatas) ||
                                                                isFieldDisabled(`${userType}.civility`, watch('statut'))
                                                            }
                                                        />
                                                        <label htmlFor="company" className="ml-2 my-0">
                                                            <FormattedHTMLMessage id="TRANSLATOR.COMPANY" />
                                                        </label>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    }
                                    return (
                                        <div className="d-flex flex-wrap gap-6">
                                            <div className="d-flex align-items-center">
                                                <RadioButton
                                                    inputId="mme"
                                                    value="MME"
                                                    onChange={(e) => onChange(e.value)}
                                                    checked={value === 'MME'}
                                                    disabled={!isSubscriber && isSameDatas}
                                                />
                                                <label htmlFor="mme" className="ml-2 my-0">
                                                    <FormattedHTMLMessage id="TRANSLATOR.MRS" />
                                                </label>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <RadioButton
                                                    inputId="mr"
                                                    value="M"
                                                    onChange={(e) => onChange(e.value)}
                                                    checked={value === 'M'}
                                                    disabled={!isSubscriber && isSameDatas}
                                                />
                                                <label htmlFor="mr" className="ml-2 my-0">
                                                    <FormattedHTMLMessage id="TRANSLATOR.MR" />
                                                </label>
                                            </div>
                                        </div>
                                    );
                                }}
                            />
                            <ErrorForm errors={errors} name={`${userType}.civility`} />
                        </Form.Group>
                    </Col>
                    {isSubscriber && (
                        <Col lg={6}>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.LANGUAGE" />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name={`${userType}.language`}
                                    rules={{
                                        required:
                                            isFieldRequired(`${userType}.language`, watch('statut'), isBorne) &&
                                            Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    }}
                                    render={({ value, onChange }) => (
                                        <Dropdown
                                            value={value}
                                            options={
                                                context === 'eff'
                                                    ? [LANGUAGE.find((language) => language.code === 'FR')]
                                                    : LANGUAGE
                                            }
                                            onChange={(e) => onChange(e.value)}
                                            className={classNames({
                                                'is-invalid': errors?.[userType]?.language,
                                            })}
                                            optionLabel="title"
                                            optionValue="code"
                                            placeholder={Intl.formatMessage({
                                                id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.LANGUAGE',
                                            })}
                                            disabled={
                                                (!isSubscriber && isSameDatas) ||
                                                isFieldDisabled(`${userType}.language`, watch('statut'), isBorne)
                                            }
                                        />
                                    )}
                                />
                                <ErrorForm errors={errors} name={`${userType}.language`} />
                            </Form.Group>
                        </Col>
                    )}
                </Row>
                {isSteSelected && isSubscriber && (
                    <Row>
                        <Col lg={6}>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.SIREN" />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name={`${userType}.siren`}
                                    {...(isSubscriber && { defaultValue: customer.siren })}
                                    rules={{
                                        validate: (value) => {
                                            if (value && !isASiren(watch(`${userType}.siren`))) {
                                                return Intl.formatMessage({
                                                    id: 'CUSTOMERS.MODAL.CREATE.SIREN.OR.SIRET.FORMAT',
                                                });
                                            }
                                            return true;
                                        },
                                        required:
                                            isFieldRequired(`${userType}.siren`, watch('statut'), isBorne) &&
                                            Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    }}
                                    render={({ value, onChange }) => (
                                        <InputText
                                            value={value}
                                            onChange={(e) => onChange(e.currentTarget.value)}
                                            className={classNames('form-control', {
                                                'is-invalid': errors?.[userType]?.siren,
                                            })}
                                            placeholder={Intl.formatMessage({
                                                id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.SIREN',
                                            })}
                                            disabled={isFieldDisabled(`${userType}.siren`, watch('statut'))}
                                        />
                                    )}
                                />
                                <ErrorForm errors={errors} name={`${userType}.siren`} />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.COMPANY_NAME" />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name={`${userType}.companyName`}
                                    {...(isSubscriber && { defaultValue: customer.companyName })}
                                    rules={{
                                        required:
                                            isFieldRequired(`${userType}.companyName`, watch('statut'), isBorne) &&
                                            Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    }}
                                    render={({ value, onChange }) => (
                                        <InputText
                                            value={value}
                                            onChange={(e) => onChange(e.currentTarget.value)}
                                            className={classNames('form-control', {
                                                'is-invalid': errors?.[userType]?.companyName,
                                            })}
                                            placeholder={Intl.formatMessage({
                                                id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.LASTNAME',
                                            })}
                                            disabled={isFieldDisabled(`${userType}.companyName`, watch('statut'))}
                                        />
                                    )}
                                />
                                <ErrorForm errors={errors} name={`${userType}.companyName`} />
                            </Form.Group>
                        </Col>
                    </Row>
                )}
                {(!isSteSelected || !isSubscriber) && (
                    <Row>
                        <Col lg={6}>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.LASTNAME" />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name={`${userType}.lastname`}
                                    {...(isSubscriber && { defaultValue: customer.lastname })}
                                    rules={{
                                        required:
                                            isFieldRequired(`${userType}.lastname`, watch('statut'), isBorne) &&
                                            Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    }}
                                    render={({ value, onChange }) => (
                                        <InputText
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e.currentTarget.value);
                                                if (isSameDatas && isSubscriber) {
                                                    setValue('beneficiary.lastname', e.currentTarget.value);
                                                }
                                            }}
                                            className={classNames('form-control', {
                                                'is-invalid': errors?.[userType]?.lastname,
                                            })}
                                            placeholder={Intl.formatMessage({
                                                id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.LASTNAME',
                                            })}
                                            disabled={
                                                (!isSubscriber && isSameDatas) ||
                                                isFieldDisabled(`${userType}.lastname`, watch('statut'), isBorne)
                                            }
                                        />
                                    )}
                                />
                                <ErrorForm errors={errors} name={`${userType}.lastname`} />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.FIRSTNAME" />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name={`${userType}.firstname`}
                                    {...(isSubscriber && { defaultValue: customer.firstname })}
                                    rules={{
                                        required:
                                            isFieldRequired(`${userType}.firstname`, watch('statut')) &&
                                            Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    }}
                                    render={({ value, onChange }) => (
                                        <InputText
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e.currentTarget.value);
                                                if (isSameDatas && isSubscriber) {
                                                    setValue('beneficiary.firstname', e.currentTarget.value);
                                                }
                                            }}
                                            className={classNames('form-control', {
                                                'is-invalid': errors?.[userType]?.firstname,
                                            })}
                                            placeholder={Intl.formatMessage({
                                                id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.FIRSTNAME',
                                            })}
                                            disabled={
                                                (!isSubscriber && isSameDatas) ||
                                                isFieldDisabled(`${userType}.firstname`, watch('statut'), isBorne)
                                            }
                                        />
                                    )}
                                />
                                <ErrorForm errors={errors} name={`${userType}.firstname`} />
                            </Form.Group>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.ADDRESS" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name={`${userType}.address`}
                                {...(isSubscriber && { defaultValue: customer.address })}
                                rules={{
                                    required:
                                        isFieldRequired(`${userType}.address`, watch('statut')) &&
                                        Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                }}
                                render={({ value, onChange }) => (
                                    <InputText
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e.currentTarget.value);
                                            if (isSameDatas && isSubscriber) {
                                                setValue('beneficiary.address', e.currentTarget.value);
                                            }
                                        }}
                                        className={classNames('form-control', {
                                            'is-invalid': errors?.[userType]?.address,
                                        })}
                                        placeholder={Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.ADDRESS',
                                        })}
                                        disabled={
                                            (!isSubscriber && isSameDatas) ||
                                            isFieldDisabled(`${userType}.address`, watch('statut'), isBorne)
                                        }
                                    />
                                )}
                            />
                            <ErrorForm errors={errors} name={`${userType}.address`} />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.ADDITIONAL_ADDRESS" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name={`${userType}.additionalAddress`}
                                render={({ value, onChange }) => (
                                    <InputText
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e.currentTarget.value);
                                            if (isSameDatas && isSubscriber) {
                                                setValue('beneficiary.additionalAddress', e.currentTarget.value);
                                            }
                                        }}
                                        className="form-control"
                                        placeholder={Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.ADDITIONAL_ADDRESS',
                                        })}
                                        disabled={
                                            (!isSubscriber && isSameDatas) ||
                                            isFieldDisabled(`${userType}.additionalAddress`, watch('statut'), isBorne)
                                        }
                                    />
                                )}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                {!isBorne && (
                    <Row>
                        <Col lg={6}>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.ADDRESS_PLACE_CALLED" />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name={`${userType}.addressPlaceCalled`}
                                    render={({ value, onChange }) => (
                                        <InputText
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e.currentTarget.value);
                                                if (isSameDatas && isSubscriber) {
                                                    setValue('beneficiary.addressPlaceCalled', e.currentTarget.value);
                                                }
                                            }}
                                            className="form-control"
                                            placeholder={Intl.formatMessage({
                                                id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.ADDITIONAL_ADDRESS',
                                            })}
                                            disabled={
                                                (!isSubscriber && isSameDatas) ||
                                                isFieldDisabled(`${userType}.addressPlaceCalled`, watch('statut'))
                                            }
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.ZIPCODE" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name={`${userType}.zipcode`}
                                {...(isSubscriber && { defaultValue: customer.zipcode })}
                                rules={{
                                    required:
                                        isFieldRequired(`${userType}.zipcode`, watch('statut')) &&
                                        Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    validate: (value) => {
                                        if (value) {
                                            if (value.length < 4 || value.length > 5) {
                                                return Intl.formatMessage({
                                                    id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.ERROR.ZIPCODE',
                                                });
                                            }
                                        }
                                    },
                                }}
                                render={({ value, onChange }) => (
                                    <InputText
                                        value={value}
                                        keyfilter="int"
                                        onBlur={() => {
                                            if (!value) {
                                                setValue(`${userType}.city`, null);
                                                setValue(`${userType}.cityId`, null);
                                                return;
                                            }
                                            return isSubscriber
                                                ? fetchSubscriberCities(value)
                                                : fetchBeneficiaryCities(value);
                                        }}
                                        onChange={(e) => {
                                            onChange(e.currentTarget.value);
                                            if (isSameDatas && isSubscriber) {
                                                setValue('beneficiary.zipcode', e.currentTarget.value);
                                            }
                                        }}
                                        className={classNames('form-control', {
                                            'is-invalid': errors?.[userType]?.zipcode,
                                        })}
                                        placeholder={Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.ZIPCODE',
                                        })}
                                        disabled={
                                            (!isSubscriber && isSameDatas) ||
                                            isFieldDisabled(`${userType}.zipcode`, watch('statut'), isBorne)
                                        }
                                    />
                                )}
                            />
                            <ErrorForm errors={errors} name={`${userType}.zipcode`} />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.CITY" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name={`${userType}.cityId`}
                                render={({ value }) => <input type="hidden" value={value} />}
                            />
                            <Controller
                                control={control}
                                name={`${userType}.city`}
                                rules={{
                                    required:
                                        isFieldRequired(`${userType}.city`, watch('statut')) &&
                                        Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                }}
                                render={({ value, onChange }) => (
                                    <Dropdown
                                        value={value}
                                        options={(isSubscriber ? subscriberCities : beneficiaryCities) || []}
                                        onChange={(e) => {
                                            onChange(e.value);
                                            const selectedCity = (
                                                isSubscriber ? subscriberCities : beneficiaryCities
                                            ).find((city) => city.localite === e.value); // NOSONAR

                                            setValue(`${userType}.cityId`, selectedCity?.id);
                                            if (isSameDatas && isSubscriber) {
                                                setValue('beneficiary.city', e.value);
                                                setValue('beneficiary.cityId', selectedCity?.id);
                                            }
                                        }}
                                        className={classNames({
                                            'is-invalid': errors?.[userType]?.city,
                                        })}
                                        optionLabel="localite"
                                        optionValue="localite"
                                        placeholder={Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.CITY',
                                        })}
                                        emptyMessage={Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.SELECT_CITY.EMPTY',
                                        })}
                                        disabled={
                                            (!isSubscriber && isSameDatas) ||
                                            isFieldDisabled(`${userType}.city`, watch('statut'), isBorne)
                                        }
                                    />
                                )}
                            />
                            <ErrorForm errors={errors} name={`${userType}.city`} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.LOCALITY_COMPLEMENT" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name={`${userType}.localityComplement`}
                                render={({ value, onChange }) => (
                                    <InputText
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e.currentTarget.value);
                                            if (isSameDatas && isSubscriber) {
                                                setValue('beneficiary.localityComplement', e.currentTarget.value);
                                            }
                                        }}
                                        className="form-control"
                                        placeholder={Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.LOCALITY_COMPLEMENT',
                                        })}
                                        disabled={
                                            (!isSubscriber && isSameDatas) ||
                                            isFieldDisabled(`${userType}.localityComplement`, watch('statut'), isBorne)
                                        }
                                    />
                                )}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PHONE" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name={`${userType}.phone`}
                                {...(isSubscriber && { defaultValue: customer.phone })}
                                rules={{
                                    required:
                                        isFieldRequired(`${userType}.phone`, watch('statut')) &&
                                        Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    validate: (value) => {
                                        const statut = getValues('statut');
                                        const mobile = getValues(`${userType}.mobile`);

                                        if (value) {
                                            const parsedPhoneNumberFR = parseMax(value, 'FR');
                                            const parsedPhoneNumberBE = parseMax(value, 'BE');
                                            const isPhone =
                                                parsedPhoneNumberFR?.getType() === 'FIXED_LINE' ||
                                                parsedPhoneNumberBE?.getType() === 'FIXED_LINE';
                                            if (
                                                (!parsedPhoneNumberFR?.isValid() && !parsedPhoneNumberBE?.isValid()) ||
                                                !isPhone
                                            ) {
                                                return Intl.formatMessage({
                                                    id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.ERROR.PHONE',
                                                });
                                            }
                                        } else if (statut === StatusContractSlug.VALID && !mobile) {
                                            return Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' });
                                        }
                                    },
                                }}
                                render={({ value, onChange }) => (
                                    <InputText
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e.currentTarget.value);
                                            if (isSameDatas && isSubscriber) {
                                                setValue('beneficiary.phone', e.currentTarget.value);
                                            }
                                        }}
                                        className={classNames('form-control', {
                                            'is-invalid': errors?.[userType]?.phone,
                                        })}
                                        placeholder={Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.PHONE',
                                        })}
                                        disabled={
                                            (!isSubscriber && isSameDatas) ||
                                            isFieldDisabled(`${userType}.phone`, watch('statut'), isBorne)
                                        }
                                    />
                                )}
                            />
                            <ErrorForm errors={errors} name={`${userType}.phone`} />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.MOBILE" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name={`${userType}.mobile`}
                                {...(isSubscriber && { defaultValue: customer.mobile })}
                                rules={{
                                    required:
                                        isFieldRequired(`${userType}.mobile`, watch('statut')) &&
                                        Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    validate: (value) => {
                                        if (value) {
                                            const parsedPhoneNumberFR = parseMax(value, 'FR');
                                            const parsedPhoneNumberBE = parseMax(value, 'BE');
                                            const isMobile =
                                                parsedPhoneNumberFR?.getType() === 'MOBILE' ||
                                                parsedPhoneNumberBE?.getType() === 'MOBILE';

                                            if (
                                                (!parsedPhoneNumberFR?.isValid() && !parsedPhoneNumberBE?.isValid()) ||
                                                !isMobile
                                            ) {
                                                return Intl.formatMessage({
                                                    id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.ERROR.MOBILE',
                                                });
                                            }
                                        }
                                    },
                                }}
                                render={({ value, onChange }) => (
                                    <InputText
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e.currentTarget.value);
                                            if (isSameDatas && isSubscriber) {
                                                setValue('beneficiary.mobile', e.currentTarget.value);
                                            }
                                        }}
                                        className={classNames('form-control', {
                                            'is-invalid': errors?.[userType]?.mobile,
                                        })}
                                        placeholder={Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.MOBILE',
                                        })}
                                        disabled={
                                            (!isSubscriber && isSameDatas) ||
                                            isFieldDisabled(`${userType}.mobile`, watch('statut'), isBorne)
                                        }
                                    />
                                )}
                            />
                            <ErrorForm errors={errors} name={`${userType}.mobile`} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col lg={isBorne ? 12 : 6}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.EMAIL" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name={`${userType}.email`}
                                {...(isSubscriber && { defaultValue: customer.email })}
                                rules={{
                                    required:
                                        isFieldRequired(`${userType}.email`, watch('statut')) &&
                                        Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    validate: (value) => {
                                        if (value && !isAMail(value)) {
                                            return Intl.formatMessage({
                                                id: 'CUSTOMERS.MODAL.CREATE.EMAIL.FORMAT',
                                            });
                                        }
                                    },
                                }}
                                render={({ value, onChange }) => (
                                    <InputText
                                        value={value}
                                        keyfilter="email"
                                        onChange={(e) => {
                                            onChange(e.currentTarget.value);
                                            if (isSameDatas && isSubscriber) {
                                                setValue('beneficiary.email', e.currentTarget.value);
                                            }
                                        }}
                                        className={classNames('form-control', {
                                            'is-invalid': errors?.[userType]?.email,
                                        })}
                                        placeholder={Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.EMAIL',
                                        })}
                                        disabled={
                                            (!isSubscriber && isSameDatas) ||
                                            isFieldDisabled(`${userType}.email`, watch('statut'), isBorne)
                                        }
                                    />
                                )}
                            />
                            <ErrorForm errors={errors} name={`${userType}.email`} />
                        </Form.Group>
                    </Col>
                    {!isBorne && (
                        <Col lg={6}>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.BIRTH_DATE" />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name={`${userType}.birthDate`}
                                    rules={{
                                        required:
                                            isFieldRequired(`${userType}.birthDate`, watch('statut')) &&
                                            Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                        validate: (value) => {
                                            const civility = getValues(`${userType}.civility`);
                                            const statut = getValues('statut');
                                            if (statut === StatusContractSlug.VALID && civility !== 'STE' && !value) {
                                                return Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' });
                                            }

                                            const age = dayjs().diff(dayjs(value), 'year');
                                            if (value && (age < 18 || age > 100)) {
                                                return Intl.formatMessage({
                                                    id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.ERROR.BIRTHDATE',
                                                });
                                            }
                                        },
                                    }}
                                    render={({ value, onChange }) => (
                                        <EditableDatePicker
                                            value={value || ''}
                                            placeholder="JJ/MM/AAAA"
                                            maxDate={dayjs().toDate()}
                                            className={classNames({
                                                'is-invalid': errors?.[userType]?.birthDate,
                                            })}
                                            onChange={(e: { value: Date }) => {
                                                onChange(e.value);
                                                if (isSameDatas && isSubscriber) {
                                                    setValue('beneficiary.birthDate', e.value);
                                                }
                                            }}
                                            disabled={
                                                (!isSubscriber && isSameDatas) ||
                                                isFieldDisabled(`${userType}.birthDate`, watch('statut'))
                                            }
                                            canClear={!isSameDatas}
                                        />
                                    )}
                                />
                                <ErrorForm errors={errors} name={`${userType}.birthDate`} />
                            </Form.Group>
                        </Col>
                    )}
                </Row>
                {!isBorne && (
                    <Row>
                        <Col lg={6}>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.MARITAL_STATUS" />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name={`${userType}.maritalStatus`}
                                    rules={{
                                        required:
                                            isFieldRequired(`${userType}.maritalStatus`, watch('statut')) &&
                                            Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    }}
                                    render={({ value, onChange }) => (
                                        <Dropdown
                                            value={value}
                                            options={MARITAL_STATUS}
                                            onChange={(e) => {
                                                onChange(e.value);
                                                if (isSameDatas && isSubscriber) {
                                                    setValue('beneficiary.maritalStatus', e.value);
                                                }
                                            }}
                                            className={classNames({
                                                'is-invalid': errors?.[userType]?.maritalStatus,
                                            })}
                                            optionLabel="title"
                                            optionValue="code"
                                            itemTemplate={(item) => renderItemTitle(item.title)}
                                            {...(value && {
                                                valueTemplate: (item, { placeholder }) => {
                                                    if (!item) {
                                                        return placeholder;
                                                    }
                                                    return <FormattedMessage id={item.title} />;
                                                },
                                            })}
                                            placeholder={Intl.formatMessage({
                                                id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.MARITAL_STATUS',
                                            })}
                                            disabled={
                                                (!isSubscriber && isSameDatas) ||
                                                isFieldDisabled(`${userType}.maritalStatus`, watch('statut'))
                                            }
                                        />
                                    )}
                                />
                                <ErrorForm errors={errors} name={`${userType}.maritalStatus`} />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PRO_CATEGORY" />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name={`${userType}.proCategory`}
                                    rules={{
                                        required:
                                            isFieldRequired(`${userType}.proCategory`, watch('statut')) &&
                                            Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    }}
                                    render={({ value, onChange }) => (
                                        <Dropdown
                                            value={value}
                                            options={PRO_CATEGORY}
                                            onChange={(e) => {
                                                onChange(e.value);
                                                if (isSameDatas && isSubscriber) {
                                                    setValue('beneficiary.proCategory', e.value);
                                                }
                                            }}
                                            className={classNames({
                                                'is-invalid': errors?.[userType]?.proCategory,
                                            })}
                                            optionLabel="title"
                                            optionValue="code"
                                            itemTemplate={(item) => renderItemTitle(item.title)}
                                            {...(value && {
                                                valueTemplate: (item, { placeholder }) => {
                                                    if (!item) {
                                                        return placeholder;
                                                    }
                                                    return <FormattedMessage id={item.title} />;
                                                },
                                            })}
                                            placeholder={Intl.formatMessage({
                                                id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.PRO_CATEGORY',
                                            })}
                                            disabled={
                                                (!isSubscriber && isSameDatas) ||
                                                isFieldDisabled(`${userType}.proCategory`, watch('statut'))
                                            }
                                        />
                                    )}
                                />
                                <ErrorForm errors={errors} name={`${userType}.proCategory`} />
                            </Form.Group>
                        </Col>
                    </Row>
                )}
                {isBorne && (
                    <>
                        <Row>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.HOUSING_TYPE" />
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="subscriber.housingType"
                                        rules={{
                                            required:
                                                isFieldRequired('subscriber.housingType', watch('statut'), isBorne) &&
                                                Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                        }}
                                        render={({ value, onChange }) => (
                                            <Dropdown
                                                value={value}
                                                options={HOUSING_TYPE}
                                                onChange={(e) => onChange(e.value)}
                                                className={classNames('form-control', {
                                                    'is-invalid': errors?.subscriber?.housingType,
                                                })}
                                                optionLabel="title"
                                                placeholder={Intl.formatMessage({
                                                    id: 'TRANSLATOR.SELECT',
                                                })}
                                                disabled={isFieldDisabled(
                                                    'subscriber.housingType',
                                                    watch('statut'),
                                                    isBorne,
                                                )}
                                            />
                                        )}
                                    />
                                    <ErrorForm errors={errors} name="subscriber.housingType" />
                                </Form.Group>
                            </Col>

                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.HOUSING_MODE" />
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="subscriber.housingMode"
                                        rules={{
                                            required:
                                                isFieldRequired('subscriber.housingMode', watch('statut'), isBorne) &&
                                                Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                        }}
                                        render={({ value, onChange }) => (
                                            <Dropdown
                                                value={value}
                                                options={HOUSING_MODE}
                                                onChange={(e) => onChange(e.value)}
                                                className={classNames('form-control', {
                                                    'is-invalid': errors?.subscriber?.housingMode,
                                                })}
                                                optionLabel="title"
                                                placeholder={Intl.formatMessage({
                                                    id: 'TRANSLATOR.SELECT',
                                                })}
                                                disabled={isFieldDisabled(
                                                    'subscriber.housingMode',
                                                    watch('statut'),
                                                    isBorne,
                                                )}
                                            />
                                        )}
                                    />
                                    <ErrorForm errors={errors} name="subscriber.housingMode" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage
                                            id={`CUSTOMERS.MODAL.SECURYCAR_CONTRACT.${customerPathLabel}`}
                                        />
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="customerPath"
                                        rules={{
                                            required:
                                                isFieldRequired('customerPath', watch('statut'), isBorne) &&
                                                Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                        }}
                                        render={({ value, onChange }) => (
                                            <Dropdown
                                                value={value}
                                                options={isBorne ? BORNE_CUSTOMER_PATH : CUSTOMER_PATH}
                                                onChange={(e) => onChange(e.value)}
                                                className={classNames('form-control', {
                                                    'is-invalid': errors?.customerPath,
                                                })}
                                                optionLabel="title"
                                                placeholder={Intl.formatMessage({
                                                    id: 'TRANSLATOR.SELECT',
                                                })}
                                                disabled={isFieldDisabled('customerPath', watch('statut'), isBorne)}
                                            />
                                        )}
                                    />
                                    <ErrorForm errors={errors} name="customerPath" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.BRAND" />
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="vehicle.brand"
                                        rules={{
                                            required:
                                                isFieldRequired('vehicle.brand', watch('statut'), isBorne) &&
                                                Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                        }}
                                        render={({ value, onChange }) => (
                                            <AutoComplete
                                                value={value}
                                                field="Name"
                                                suggestions={filteredBrands}
                                                completeMethod={filterBrands}
                                                className="w-100"
                                                inputClassName={classNames('form-control', {
                                                    'is-invalid': errors?.vehicle?.brand,
                                                })}
                                                placeholder={Intl.formatMessage({
                                                    id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.BRAND',
                                                })}
                                                onSelect={(e) => onChange(e.value.Name)}
                                                onChange={(e) => onChange(e.value)}
                                                disabled={isFieldDisabled('vehicle.brand', watch('statut'), isBorne)}
                                            />
                                        )}
                                    />
                                    <ErrorForm errors={errors} name="vehicle.brand" />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.MODEL" />
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="vehicle.model"
                                        rules={{
                                            required:
                                                isFieldRequired('vehicle.model', watch('statut'), isBorne) &&
                                                Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                        }}
                                        render={({ value, onChange }) => (
                                            <AutoComplete
                                                value={value}
                                                field="Name"
                                                suggestions={filteredModels}
                                                completeMethod={filterModels}
                                                className="w-100"
                                                inputClassName={classNames('form-control', {
                                                    'is-invalid': errors?.vehicle?.model,
                                                })}
                                                placeholder={Intl.formatMessage({
                                                    id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.MODEL',
                                                })}
                                                onSelect={(e) => onChange(e.value.Name)}
                                                onChange={(e) => onChange(e.value)}
                                                disabled={isFieldDisabled('vehicle.model', watch('statut'), isBorne)}
                                            />
                                        )}
                                    />
                                    <ErrorForm errors={errors} name="vehicle.model" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </>
                )}
            </>
        );
    };

    return (
        <>
            <Controller
                control={control}
                name="customerId"
                {...(customer && { defaultValue: customer.id })}
                render={({ value }) => <input type="hidden" value={value} />}
            />
            <FormPartTitle title="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.CATEGORY.SUBSCRIBER.TITLE" />
            {fields('subscriber')}

            {!isBorne && (
                <>
                    <FormPartTitle title="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.CATEGORY.BENEFICIARY.TITLE" />
                    <div className="div-info font-size-base mb-4 d-flex justify-content-between align-items-center">
                        <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.IS_SAME_CUSTOMER" />
                        <SwitchInput
                            name="isSameDatas"
                            form={false}
                            className="switch-input--primary switch-input--inactive-toggle-lighten switch-input--inactive-label-left-secondary"
                            onChange={() => setIsSameDatas(!isSameDatas)}
                            labelFirst={Intl.formatMessage({ id: 'TRANSLATOR.NO' })}
                            labelSecond={Intl.formatMessage({ id: 'TRANSLATOR.YES' })}
                            active={isSameDatas}
                            disabled={isSteSelected}
                        />
                    </div>

                    {fields('beneficiary')}
                </>
            )}
        </>
    );
};

export default SecuryCarContractStepSubscriber;
