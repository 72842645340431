import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

dayjs.extend(relativeTime);

export interface BlockProps {
    status: number | null;
    updatedAt: string;
}

const TchekStatus = ({ status, updatedAt }: BlockProps) => {
    if (status === null) {
        return null;
    }

    return (
        <div className="d-flex ml-2 flex-column">
            <FormattedHTMLMessage id={`CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.TCHEK.STATUS.${status}`} />
            {updatedAt !== null && (
                <span title={dayjs(updatedAt).format('DD/MM/YYYY à HH:mm')}>
                    {dayjs(updatedAt).fromNow()}
                    <i className="la la-clock ml-1" />
                </span>
            )}
        </div>
    );
};

export default TchekStatus;
