import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import {
    ASSIGNED,
    dealershipsAssignmentsDone,
    dealershipsAssignmentsTodo,
    dealershipsHasValidAssignments,
    TO_ASSIGN,
} from '../../helpers/CampaignHelper';
import { OperationContext } from '../../OperationProvider';

import OperationAssignmentsToLink from './OperationAssignmentsToLink';
import OperationPanel from './OperationPanel';

const variants = {
    show: {
        height: 'auto',
        overflow: 'hidden',
        opacity: 1,
        transition: {
            ease: 'easeOut',
        },
    },
    hide: {
        height: 0,
        overflow: 'hidden',
        opacity: 0,
        transition: {
            ease: 'easeOut',
        },
    },
};

const OperationAssignments = () => {
    const { operationState, setOperationState } = useContext(OperationContext);
    const [expandedPanel, setExpandedPanel] = useState(null);
    const valid = dealershipsHasValidAssignments(operationState);

    const panels = [
        {
            state: ASSIGNED,
            title: (
                <FormattedHTMLMessage
                    id="CAMPAIGN.MODALS.SETTINGS.ASSIGNMENTS.ASSIGNED"
                    values={{
                        number: operationState?.withAssignment,
                    }}
                />
            ),
            variant: 'success',
            toggleable: false,
        },
        {
            state: TO_ASSIGN,
            title: (
                <FormattedHTMLMessage
                    id={`CAMPAIGN.MODALS.SETTINGS.ASSIGNMENTS.CARD_TO_ASSIGN${valid ? '.DONE' : ''}`}
                    values={{
                        number: valid
                            ? dealershipsAssignmentsDone(operationState)
                            : dealershipsAssignmentsTodo(operationState),
                    }}
                />
            ),
            variant: valid ? 'success' : 'danger',
            children: <OperationAssignmentsToLink />,
            toggleable: true,
        },
    ];

    const handleEqualAssignments = () => {
        let selectedDealers = operationState?.selectedDealers || {};

        Object.keys(operationState?.dealerships || {}).forEach?.((dealershipId) => {
            const dealership = operationState?.dealerships?.[dealershipId];
            // apply equal assignments to dealership defined but without affectations
            if (
                dealership?.name &&
                !operationState?.selectedDealers?.[dealershipId] &&
                !operationState?.affectedDealers?.[dealershipId]
            ) {
                const dealers = dealership?.dealers?.map((d) => d.id);
                selectedDealers = {
                    ...(selectedDealers || {}),
                    [dealershipId]: dealers,
                };
            }
        });

        setOperationState({
            ...operationState,
            affectedDealers: {},
            selectedDealers,
        });
        setExpandedPanel(null);
    };

    return (
        <div className="position-relative h-100">
            <motion.div variants={variants} initial="show" animate={!expandedPanel ? 'show' : 'hide'}>
                <div className="text-center">
                    <i className="las la-bullseye la-4x text-primary" />
                    <div className="text-primary font-size-h3 font-weight-semibold">
                        <FormattedMessage id="CAMPAIGN.MODALS.SETTINGS.ASSIGNMENTS.TITLE" />
                    </div>
                </div>
            </motion.div>
            <div
                className={classNames({
                    'mt-8': expandedPanel === null,
                })}
            >
                {panels.map((panel) => (
                    <OperationPanel
                        state={panel.state}
                        variant={panel.variant}
                        title={panel.title}
                        key={panel.state}
                        toggleable={panel.toggleable}
                        expandedPanel={expandedPanel}
                        setExpandedPanel={setExpandedPanel}
                    >
                        {panel?.children}
                    </OperationPanel>
                ))}
                <motion.div
                    variants={variants}
                    initial={!expandedPanel && !valid ? 'show' : 'hide'}
                    animate={!expandedPanel && !valid ? 'show' : 'hide'}
                >
                    <div className="mt-4 text-danger text-center font-size-sm">
                        <FormattedHTMLMessage
                            id="CAMPAIGN.MODALS.SETTINGS.ASSIGNMENTS.ERROR"
                            values={{
                                number: operationState?.withoutAssignment || 0,
                            }}
                        />
                    </div>
                </motion.div>
                <motion.div
                    variants={variants}
                    initial={!expandedPanel && valid ? 'show' : 'hide'}
                    animate={!expandedPanel && valid ? 'show' : 'hide'}
                >
                    <div className="mt-4 text-center font-size-lg">
                        <FormattedHTMLMessage id="CAMPAIGN.MODALS.SETTINGS.ASSIGNMENTS.DONE" />
                    </div>
                </motion.div>
                <motion.div
                    variants={variants}
                    initial={expandedPanel === TO_ASSIGN ? 'show' : 'hide'}
                    animate={expandedPanel === TO_ASSIGN ? 'show' : 'hide'}
                >
                    <div className="mt-8 text-center font-size-lg">
                        {valid ? (
                            <FormattedHTMLMessage id="CAMPAIGN.MODALS.SETTINGS.ASSIGNMENTS.DONE" />
                        ) : (
                            <>
                                <div className="mb-4">
                                    <FormattedHTMLMessage id="CAMPAIGN.MODALS.SETTINGS.ASSIGNMENTS.EQUAL_ASSIGN.TEXT" />
                                </div>
                                <Button variant="primary" onClick={handleEqualAssignments}>
                                    <FormattedHTMLMessage id="CAMPAIGN.MODALS.SETTINGS.ASSIGNMENTS.EQUAL_ASSIGN.BUTTON" />
                                </Button>
                            </>
                        )}
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default OperationAssignments;
