import classNames from 'classnames';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import { getDealershipsDirectory } from '@app/crud/autouser/dealerships.crud';

import DisplayDealership from '@app/pages/autouser/dealerships/partials/DisplayDealership';
import DirectoryUsers from '@app/pages/directory/DirectoryUsers';

import Loader from '@app/partials/content/Loader';

import useFetch from '../../hooks/useFetch';

interface DirectorySearchType {
    search: string;
}

const DirectoryDealerships = ({ search }: DirectorySearchType) => {
    const [dealershipExpanded, setDealershipExpanded] = useState<string>();

    const { data, loading, fetch } = useFetch({
        fetchAction: getDealershipsDirectory,
    });
    const dealerships = Object.values(data ?? {});

    useEffect(() => {
        fetch({ q: search }).then();
        setDealershipExpanded(undefined);
    }, [search]);

    useEffect(() => {
        if (dealerships?.length === 1) {
            setDealershipExpanded(dealerships[0].ref_dealership_id);
        }
    }, [data]);
    return (
        <div>
            {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            {!loading && (
                <>
                    <div className="my-10 font-size-h4">
                        <FormattedHTMLMessage
                            id="DIRECTORY.TABLE.VIEW.FOUND.NUMBER"
                            values={{
                                count: dealerships?.length ?? 0,
                                search,
                            }}
                        />
                    </div>
                    {Object.values(dealerships)?.length > 0 &&
                        dealerships?.map((dealership) => {
                            const expanded = dealershipExpanded === dealership.ref_dealership_id;
                            return (
                                <>
                                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                                    <div
                                        className="cursor-pointer"
                                        onClick={() =>
                                            setDealershipExpanded(
                                                dealershipExpanded === dealership.ref_dealership_id
                                                    ? undefined
                                                    : dealership.ref_dealership_id,
                                            )
                                        }
                                    >
                                        <Divider />
                                        <div className="d-flex align-items-center justify-content-between">
                                            <DisplayDealership dealership={dealership} />
                                            <i
                                                className={classNames('pr-10 la-3x las', {
                                                    'la-plus': !expanded,
                                                    'la-minus': expanded,
                                                })}
                                            />
                                        </div>
                                    </div>
                                    {expanded && (
                                        <DirectoryUsers search={dealership.ref_dealership_id} dealershipResearch />
                                    )}
                                </>
                            );
                        })}
                </>
            )}
        </div>
    );
};

export default DirectoryDealerships;
