import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import './CustomersSearch.scss';
import { DirectorySearchType } from '@app/pages/directory/Directory';

import SearchInput from '@app/partials/content/SearchInput';

interface DirectorySearchProps {
    search: DirectorySearchType;
    setSearch: (search: DirectorySearchType) => void;
}

const DirectorySearch = ({ search, setSearch }: DirectorySearchProps) => {
    const Intl = useIntl();

    const handleUserChange = (value: string) => {
        setSearch({
            user: value,
            dealership: '',
        });
    };
    const handleDealershipChange = (value: string) => {
        setSearch({
            user: '',
            dealership: value,
        });
    };

    return (
        <Row className="customers-search justify-content-center">
            <Col lg={4}>
                <SearchInput
                    name="user"
                    value={search.user}
                    placeholder={Intl.formatMessage({ id: 'DIRECTORY.TABLE.VIEW.RESEARCH_USER.PLACEHOLDER' })}
                    onChange={handleUserChange}
                />
            </Col>
            <div className="pl-6 pt-1 font-weight-bold align-self-center">
                <FormattedMessage id="TRANSLATOR.OR" />
            </div>
            <Col lg={4}>
                <SearchInput
                    name="dealership"
                    value={search.dealership}
                    placeholder={Intl.formatMessage({ id: 'DIRECTORY.TABLE.VIEW.RESEARCH_DEALERSHIP.PLACEHOLDER' })}
                    onChange={handleDealershipChange}
                />
            </Col>
        </Row>
    );
};

export default DirectorySearch;
