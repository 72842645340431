import axios from 'axios';

// eslint-disable-next-line import/prefer-default-export
export function getMetaFile() {
    return axios.get('/meta.json', {
        params: {
            t: new Date().getTime(),
        },
    });
}
