import { AnimateSharedLayout, motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '@app/hooks';
import { fetchPricingState } from '@app/store/stock/stock.thunk';

import ActionButton from '@app/partials/content/ActionButton';

import usePermissions from '../../../hooks/usePermissions';

const container = {
    hidden: { opacity: 1 },
    visible: {
        opacity: 1,
        transition: {
            delay: 1,
            staggerChildren: 0.15,
        },
    },
};

type ActionsHandBandProps = {
    isLoadingExport: boolean;
    exportPricing: () => void;
    handleClickImport: () => void;
    isInScrollHeadBand?: boolean;
};

const ActionsHandBand = ({
    isLoadingExport,
    exportPricing,
    handleClickImport,
    isInScrollHeadBand,
}: ActionsHandBandProps) => {
    const pricingState = useAppSelector((state) => state.stock.pricingState);
    const dispatch = useDispatch();
    const { hasPermission } = usePermissions();

    useEffect(() => {
        if (hasPermission('STOCK_MANUAL_PRICING')) {
            dispatch(fetchPricingState());
        }
    }, []);

    return (
        <AnimateSharedLayout>
            <motion.div
                variants={container}
                initial="hidden"
                animate="visible"
                className={`fullheadband__part ${
                    isInScrollHeadBand ? 'scrollheadband' : 'fullheadband'
                }__actions col-12 col-md-8`}
            >
                {hasPermission('STOCK_EXPORT_PRICING') && (
                    <ActionButton
                        icon={<i className="la la-2x text-white la-download" />}
                        title={<FormattedMessage id="STOCK.PRICING.HEADBAND.PRICING_TO_DO.EXPORT" />}
                        variant="primary"
                        disabled={() => isLoadingExport}
                        disabledTooltip={<FormattedMessage id="STOCK.PRICING.EXPORT_DISABLED" />}
                        onClick={exportPricing}
                        style={{ letterSpacing: '-0.26px' }}
                        view="list"
                    />
                )}
                {hasPermission('STOCK_IMPORT_PRICING') && (
                    <ActionButton
                        icon={<i className="la la-2x text-white la-upload" />}
                        title={<FormattedMessage id="STOCK.PRICING.HEADBAND.PRICING_TO_DO.IMPORT" />}
                        variant="primary"
                        onClick={handleClickImport}
                        style={{ letterSpacing: '-0.26px' }}
                        view="list"
                        disabled={() => pricingState === 'in_progress'}
                        disabledTooltip={<FormattedMessage id="STOCK.PRICING.IMPORT_DISABLED" />}
                    />
                )}
            </motion.div>
        </AnimateSharedLayout>
    );
};

ActionsHandBand.defaultProps = {
    isInScrollHeadBand: false,
};

export default ActionsHandBand;
