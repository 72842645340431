import type { CancelToken } from 'axios';

import ApiGateway, { ApiResponse, CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import type {
    AnonymizeUsers,
    AuthorizeResponse,
    DealershipReturn,
    GetUsersFromAdParams,
    GetUsersParams,
    SearchUserGDPR,
    UpdateUserParams,
    UpdateUserPartnerParams,
    UserCreationData,
    UserGDPR,
    UsersResponse,
} from '@app/crud/autouser/user.types';

import { User } from '@app/store/login/login.store';

const { REACT_APP_AUTOUSER_URL, REACT_APP_GDPR_URL } = process.env;

export async function addUser(data: UserCreationData) {
    return ApiGateway.post(`${REACT_APP_AUTOUSER_URL}/admin/users`, {
        ...data,
        active: true,
        external: true,
    });
}

export function associateUser(username: string, params = {}) {
    return ApiGateway.post(`${REACT_APP_AUTOUSER_URL}/admin/users`, {
        email: username,
        external: false,
        active: true,
        ...params,
    });
}

export function deleteUser(userId: number) {
    return ApiGateway.delete(`${REACT_APP_AUTOUSER_URL}/admin/users/${userId}`);
}

export function getUsers(params: GetUsersParams) {
    return ApiGateway.get<User[], UsersResponse<User[]>>(`${REACT_APP_AUTOUSER_URL}/admin/users`, {
        params,
    });
}
export function getDirectoryUsers(params: GetUsersParams) {
    return ApiGateway.get<User[], UsersResponse<User[]>>(`${REACT_APP_AUTOUSER_URL}/directory/users`, {
        params,
    });
}

export function getUsersFromAd(params: GetUsersFromAdParams) {
    return ApiGateway.get<User[], UsersResponse<User[]>>(`${REACT_APP_AUTOUSER_URL}/admin/users/search`, {
        params,
    });
}

export function getUser(id: number) {
    return ApiGateway.get(`${REACT_APP_AUTOUSER_URL}/admin/users/${id}`);
}

export function getUserPartner(id: number) {
    return ApiGateway.get<
        UpdateUserPartnerParams['attributes'],
        CustomAxiosResponse<UpdateUserPartnerParams['attributes']>
    >(`${REACT_APP_AUTOUSER_URL}/admin/users/partner-attribute-value/${id}`);
}

export function updateUserPartner(user_id: number, attributes: UpdateUserPartnerParams['attributes']) {
    return ApiGateway.post<UpdateUserPartnerParams, UpdateUserPartnerParams>(
        `${REACT_APP_AUTOUSER_URL}/admin/users/partner-attribute-value`,
        {
            user_id,
            attributes,
        },
    );
}

export function updateUser(id: number, data: UpdateUserParams) {
    return ApiGateway.patch(`${REACT_APP_AUTOUSER_URL}/admin/users/${id}`, data);
}

export function updatePassword(userId: number, password: string) {
    return ApiGateway.post(`${REACT_APP_AUTOUSER_URL}/admin/users/${userId}/change-password`, {
        password,
    });
}

export function addUserConnection() {
    return ApiGateway.post(`${REACT_APP_AUTOUSER_URL}/user/connect`);
}

export function getAllPerimeter(user_id: number) {
    return ApiGateway.get(
        `${REACT_APP_AUTOUSER_URL}/admin/users/perimeter${user_id ? `/${user_id}` : ''}?showInactiveDealerships=0`,
    );
}

export function getUserPerimeter() {
    return ApiGateway.get(`${REACT_APP_AUTOUSER_URL}/users/perimeter?showInactiveDealerships=0`);
}

export function getDealershipVendors(refDealershipId: number | string) {
    return ApiGateway.get<User[], UsersResponse<User[]>>(`${REACT_APP_AUTOUSER_URL}/vendors/${refDealershipId}`);
}

export const authorize = (context: string) =>
    ApiGateway.get<AuthorizeResponse, AuthorizeResponse>(`${REACT_APP_AUTOUSER_URL}/adlogin/url?context=${context}`);

export const vendorsSearch = (param: {
    dealerships: string;
    scope: string | boolean;
    roles?: string;
    withHierarchy?: boolean;
}) =>
    ApiGateway.post<ApiResponse<DealershipReturn>, CustomAxiosResponse<ApiResponse<DealershipReturn>>>(
        `${REACT_APP_AUTOUSER_URL}/vendors/search`,
        param,
    );

export function getUserToAnonymize(params: SearchUserGDPR & { cancelToken: CancelToken }) {
    return ApiGateway.get<UserGDPR, CustomAxiosResponse<{ results: UserGDPR[]; count: number }> | undefined>(
        `${REACT_APP_GDPR_URL}/search`,
        {
            params,
            cancelToken: params.cancelToken,
        },
    );
}

export function anonymizeUsers(params: AnonymizeUsers[]) {
    return ApiGateway.post<AnonymizeUsers[], { errors: [] }>(`${REACT_APP_GDPR_URL}/anonymize`, {
        anonymizationRequests: params,
    });
}
