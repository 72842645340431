import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

import { usePermissions } from '@app/hooks';
import { RootState } from '@app/store/rootDuck';
import { fetchVehicleHistoricalValue } from '@app/store/stock/stock.thunk';
import { Vehicle, VehicleHistoricalPrice } from '@app/store/stock/stock.type';

import Loader from '@app/partials/content/Loader';

import Price from '@app/components/formatters/price/Price';

import StockPriceHistoryRecommendationItem from './StockPriceHistoryRecommendationItem';

type StockPriceHistoryProps = {
    vehicle: Vehicle;
    dispatch: Function;
    history: any;
    showTitle: boolean;
};

const StockPriceHistory = ({ vehicle, dispatch, history, showTitle }: StockPriceHistoryProps) => {
    const { data: vehicleHistoricalPrice, loading } = useSelector(
        (state: RootState) => state.stock.vehicleHistoricalPrice,
    );
    const { hasPermission } = usePermissions();

    const updatedByHandler = (slug: string) => {
        switch (slug) {
            case 'PVO2':
                return 'Import PVO²';
            case 'Automanager':
                return 'Automanager → PVO²';
            default:
                return '';
        }
    };
    const vehicleHistoricalPriceContent = (item: VehicleHistoricalPrice) => {
        if (item.type === 'PRICING') {
            return (
                <td colSpan={3}>
                    <StockPriceHistoryRecommendationItem item={item} />
                </td>
            );
        }
        if (item.type === 'PVO_ALIGN') {
            return (
                <td colSpan={3}>
                    <FormattedHTMLMessage id="STOCK_VEHICLE.CARD.PRICE_HANDLER.HISTORICAL.TABLE.PVO_ALIGN.PREVIOUS_PRICE" />
                    <Price value={Number(item.previousValue)} />
                    <br />
                    <FormattedHTMLMessage id="STOCK_VEHICLE.CARD.PRICE_HANDLER.HISTORICAL.TABLE.PVO_ALIGN.NEW_PRICE" />
                    <Price value={Number(item.newValue)} />
                </td>
            );
        }
        return (
            <>
                <td>
                    <Price value={Number(item.previousValue)} />
                </td>
                <td>
                    <NumberFormat value={item.newValue} thousandSeparator=" " displayType="text" suffix=" €" />
                </td>
                <td width="18%">{updatedByHandler(item.updatedBy)}</td>
            </>
        );
    };

    useEffect(() => {
        if (vehicle?.sku) {
            dispatch(fetchVehicleHistoricalValue({ sku: vehicle.sku, history }));
        }
    }, [vehicle, dispatch, history]);
    return loading ? (
        <Loader style={{ width: '5rem', height: '5rem' }} overlay />
    ) : (
        vehicleHistoricalPrice?.length > 0 && (
            <div
                className={`border-top w-100 ${
                    hasPermission('STOCK_VIEW_PRICE_MANAGER') && !showTitle ? 'h-50' : 'h-100'
                }`}
            >
                {showTitle && (
                    <div className="price-handler_historical-title">
                        <FormattedHTMLMessage
                            id="STOCK_VEHICLE.CARD.PRICE_HANDLER.HISTORICAL.TABLE.TITLE"
                            values={{ licenseNumber: vehicle?.license_number }}
                        />
                    </div>
                )}
                <div className="price-handler_historical-table">
                    <Table>
                        <thead className="thead--sticky bg-white">
                            <tr>
                                <th data-field="id" data-sortable="true" className="text-center">
                                    <FormattedMessage id="STOCK_VEHICLE.CARD.PRICE_HANDLER.CONTENT.TABLE.DATE" />
                                </th>
                                <th />
                                <th>
                                    <FormattedMessage id="STOCK_VEHICLE.CARD.PRICE_HANDLER.CONTENT.TABLE.ORIGIN_PRICE" />
                                </th>
                                <th>
                                    <FormattedMessage id="STOCK_VEHICLE.CARD.PRICE_HANDLER.CONTENT.TABLE.NEW_PRICE" />
                                </th>
                                <th>
                                    <FormattedMessage id="STOCK_VEHICLE.CARD.PRICE_HANDLER.CONTENT.TABLE.MODIFIED_IN" />
                                </th>
                            </tr>
                            <tr className="bg-white">
                                <th colSpan={5} className="head-border p-0" />
                            </tr>
                        </thead>
                        <tbody>
                            {vehicleHistoricalPrice.map((item, key) => (
                                <tr key={key}>
                                    <td className="text-right" width="10%" style={{ border: 0 }}>
                                        {dayjs(item.updatedAt || item.createdAt).format('DD/MM/YYYY')}
                                        <br />
                                        {dayjs(item.updatedAt || item.createdAt).format('[à] HH[h]mm')}
                                    </td>
                                    <td width="5%" style={{ border: 0 }}>
                                        <span className="dot" />
                                    </td>
                                    {vehicleHistoricalPriceContent(item)}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        )
    );
};

export default StockPriceHistory;
