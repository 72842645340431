import ApiGateway, { ApiResponse, CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { ActionClient, ActionPayloadRelatedToLead, ActionRelatedTo, ActionResponse } from './base';

const { REACT_APP_LEAD_URL } = process.env;

export default class ContractExtensionClient
    implements ActionClient<ContractExtensionPayload, ContractExtensionResponse>
{
    public async execute(payload: ContractExtensionPayload): Promise<ContractExtensionResponse> {
        return ApiGateway.post<
            ApiResponse<ContractExtensionResponse>,
            CustomAxiosResponse<ApiResponse<ContractExtensionResponse>>
        >(`${REACT_APP_LEAD_URL}/action/contract-extension`, payload).then(
            (response: ApiResponse<ContractExtensionResponse>) => {
                if (response.result?.error) {
                    throw new Error(response.result?.error);
                }

                return {
                    relatedToLead: response.result.id,
                    relatedToCustomer: response.result.contact.id,
                    ...response.result,
                };
            },
        );
    }

    public readonly action: string = 'contract-extension';

    public readonly relatedTo: ActionRelatedTo = 'lead';

    public reference(payload: ContractExtensionPayload): number {
        return payload.lead;
    }
}

export interface ContractExtensionPayload extends ActionPayloadRelatedToLead {
    date: string;
}

export interface ContractExtensionResponse extends ActionResponse {
    id: number;
    contact: {
        id: number;
    };
}
