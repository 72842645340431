import ApiGateway, { ApiResponse, CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { ActionClient, ActionPayloadRelatedToLead, ActionRelatedTo, ActionResponse } from './base';

const { REACT_APP_LEAD_URL } = process.env;

export default class CreateLeadElementOutOfStockClient
    implements ActionClient<CreateLeadElementOutOfStockPayload, CreateLeadElementOutOfStockResponse>
{
    public async execute(payload: CreateLeadElementOutOfStockPayload): Promise<CreateLeadElementOutOfStockResponse> {
        return ApiGateway.post<
            ApiResponse<CreateLeadElementOutOfStockResponse>,
            CustomAxiosResponse<ApiResponse<CreateLeadElementOutOfStockResponse>>
        >(`${REACT_APP_LEAD_URL}/action/lead-element/create-out-of-stock`, payload).then(
            (response: ApiResponse<CreateLeadElementOutOfStockResponse>) => {
                if (response.result?.error) {
                    throw new Error(response.result?.error);
                }

                return {
                    relatedToLead: response.result.id,
                    relatedToCustomer: response.result.contact.id,
                    ...response.result,
                };
            },
        );
    }

    public readonly action: string = 'update-lead-element';

    public readonly relatedTo: ActionRelatedTo = 'lead';

    public reference(payload: CreateLeadElementOutOfStockPayload): number {
        return payload.lead;
    }
}

export interface CreateLeadElementOutOfStockPayload extends ActionPayloadRelatedToLead {
    make: string;
    model: string;
    fuelType?: string;
    gearsType?: string;
}

export interface CreateLeadElementOutOfStockResponse extends ActionResponse {
    id: number;
    contact: {
        id: number;
    };
    leadElements: any[];
}
