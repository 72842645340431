import { parsePhoneNumberFromString } from 'libphonenumber-js';
import React from 'react';
import { Image } from 'react-bootstrap';

import { Locale } from '@src/_metronic/i18n/I18nProvider';

import { Dealership } from '@app/crud/autouser/dealerships.type';

import store from '@app/store/store';

interface DisplayDealershipProps {
    dealership: Dealership;
}

const DisplayDealership = ({ dealership }: DisplayDealershipProps) => {
    const phone = parsePhoneNumberFromString(dealership?.phone || '', Locale.toUpperCase());
    const getDealershipBrand = (d: Dealership) => {
        let brand = d?.brand.toLowerCase().replace(' ', '-');
        if (brand.includes('mercedes')) {
            brand = 'mercedes-benz';
        } else if (brand?.includes('volkswagen')) {
            brand = 'volkswagen';
        } else if (brand?.includes('multi') && store.getState().auth.userData.context.toLowerCase() === 'bernard') {
            brand = 'bernard';
        }
        return brand;
    };

    const dealershipLogo = () => {
        if (dealership?.ref_dealership_usualname.startsWith('AUTOBERNARD DEALS')) {
            return <Image width={90} src="/media/marques/bernard-deals.svg" />;
        }

        return <Image width={90} src={`/media/marques/${getDealershipBrand(dealership)}.svg`} />;
    };
    return (
        <div className="d-flex align-items-center">
            {dealershipLogo()}
            <div className="text-left ml-10">
                <div className="font-weight-bold">
                    {dealership?.ref_dealership_usualname}
                    {dealership?.dealership_code && ` - ${dealership?.dealership_code.toUpperCase()}`}
                    {dealership?.ref_dealership_id && ` - ${dealership?.ref_dealership_id.toUpperCase()}`}
                </div>
                <div>{dealership?.name}</div>
                <div>
                    {dealership?.address?.address_1} - {dealership?.address?.zipcode} {dealership?.address?.city}
                </div>
                <div>Tél. {phone?.formatNational?.()}</div>
            </div>
        </div>
    );
};
export default DisplayDealership;
