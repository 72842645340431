import { saveAs } from 'file-saver';
import React from 'react';
import { Card } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { getStockCrvoInvoice } from '@app/crud/stock/stock.crud';

import { Vehicle } from '@app/store/stock/stock.type';

import Permission from '@app/partials/content/Permission';
import RowInfo from '@app/partials/content/RowInfo';
import toast from '@app/partials/content/Toast';

interface StockVehicleDealershipProps {
    vehicle: Vehicle;
}
const StockVehicleDealership = ({ vehicle }: StockVehicleDealershipProps) => {
    const Intl = useIntl();

    const handleOpenCrvoInvoice = async () => {
        try {
            const crvoInvoice = await getStockCrvoInvoice(vehicle?.license_number, vehicle?.serial_number);
            if (crvoInvoice) {
                saveAs(crvoInvoice, vehicle?.crvo_invoice);
            }
        } catch (error) {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        }
    };

    return (
        <Card>
            <Card.Header>
                <i className="card__icon la-lg las la-building text-dark" />
                {Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.DEALERSHIP.TITLE' })}
            </Card.Header>
            <Card.Body>
                <RowInfo
                    label={Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.DEALERSHIP.CONTENT.NUM_VO' })}
                    value={vehicle?.sku || '-'}
                    colLabel={6}
                    colValue={6}
                />
                <Permission permissions={['STOCK_VIEW_INTERNAL_DATA']}>
                    <RowInfo
                        label={Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.DEALERSHIP.CONTENT.DEALERSHIP_CODE' })}
                        value={vehicle?.dealer_id || '-'}
                        colLabel={6}
                        colValue={6}
                    />
                </Permission>
                <RowInfo
                    label={Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.DEALERSHIP.CONTENT.DEALERSHIP' })}
                    value={vehicle?.dealer_usual_name || '-'}
                    colLabel={6}
                    colValue={6}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.DEALERSHIP.CONTENT.POLE' })}
                    value={vehicle?.dealer_pole_usual_name || '-'}
                    colLabel={6}
                    colValue={6}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.DEALERSHIP.CONTENT.HOLDING' })}
                    value={vehicle?.dealer_holding_usual_name || '-'}
                    colLabel={6}
                    colValue={6}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.DEALERSHIP.CONTENT.SOURCE' })}
                    value={vehicle?.product || '-'}
                    colLabel={6}
                    colValue={6}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.DEALERSHIP.CONTENT.PVO_CODE' })}
                    value={vehicle?.code_pvo || '-'}
                    colLabel={6}
                    colValue={6}
                />
                {vehicle?.crvo_invoice && (vehicle?.license_number || vehicle?.serial_number) && (
                    <Permission permissions={['STOCK_VIEW_INVOICE_CRVO']}>
                        <RowInfo
                            label={Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.DEALERSHIP.CONTENT.CRVO_INVOICE' })}
                            value={
                                <button
                                    type="submit"
                                    className="text-primary text-left font-size-sm font-weight-bold btn p-0"
                                    onClick={handleOpenCrvoInvoice}
                                >
                                    <u>
                                        <FormattedMessage id="STOCK_VEHICLE.CARD.DEALERSHIP.CONTENT.CRVO_SEE_INVOICE" />
                                    </u>
                                </button>
                            }
                            colLabel={6}
                            colValue={6}
                        />
                    </Permission>
                )}
            </Card.Body>
        </Card>
    );
};

export default StockVehicleDealership;
