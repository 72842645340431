import ApiGateway, { ApiResponse, CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { ActionClient, ActionPayload, ActionRelatedTo, ActionResponse } from './base';

const { REACT_APP_LEAD_URL } = process.env;

export default class UpdateLeadElementFromStockClient
    implements ActionClient<UpdateLeadElementFromStockPayload, UpdateLeadElementFromStockResponse>
{
    public async execute(payload: UpdateLeadElementFromStockPayload): Promise<UpdateLeadElementFromStockResponse> {
        const { leadElementId, ...rest } = payload;
        return ApiGateway.post<
            ApiResponse<UpdateLeadElementFromStockResponse>,
            CustomAxiosResponse<ApiResponse<UpdateLeadElementFromStockResponse>>
        >(`${REACT_APP_LEAD_URL}/action/lead-element/${leadElementId}/update-from-stock`, rest).then(
            (response: ApiResponse<UpdateLeadElementFromStockResponse>) => {
                if (response.result?.error) {
                    throw new Error(response.result?.error);
                }

                return {
                    relatedToLead: response.result.id,
                    relatedToCustomer: response.result.contact.id,
                    ...response.result,
                };
            },
        );
    }

    public readonly action: string = 'update-lead-element';

    public readonly relatedTo: ActionRelatedTo = 'lead';

    public reference(payload: UpdateLeadElementFromStockPayload): number {
        return payload.leadElementId;
    }
}

export interface UpdateLeadElementFromStockPayload extends ActionPayload {
    leadElementId: number;
    internalId: string;
}

export interface UpdateLeadElementFromStockResponse extends ActionResponse {
    id: number;
    contact: {
        id: number;
    };
    leadElements: any[];
}
