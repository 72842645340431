// eslint-disable-next-line import/no-extraneous-dependencies
import * as H from 'history';
import React from 'react';
import { Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import type { Dispatch } from 'redux';

import { isExpired } from '@app/helpers/DateHelper';

import { usePermissions } from '@app/hooks';
import StockPriceChange from '@app/pages/stock/partials/price/StockPriceChange';
import StockPriceDetails from '@app/pages/stock/partials/price/StockPriceDetails';
import StockPriceHistory from '@app/pages/stock/partials/price/StockPriceHistory';
import StockPriceRecommendation from '@app/pages/stock/partials/price/StockPriceRecommendation';
import useRadarEncoder from '@app/pages/stock/pricing/hooks/useRadarEncoder';
import { Vehicle } from '@app/store/stock/stock.type';

import Permission from '@app/partials/content/Permission';

type StockVehiclePriceHandlerProps = {
    vehicle: Vehicle;
    history: H.History;
    dispatch: Dispatch;
};

const StockVehiclePriceHandler = ({ vehicle, history, dispatch }: StockVehiclePriceHandlerProps) => {
    const { hasPermission } = usePermissions();

    const stockPriceContent = () => {
        if (
            vehicle.has_pricing_reco &&
            vehicle?.pricing?.expirationDate &&
            !isExpired(vehicle.pricing.expirationDate)
        ) {
            return <StockPriceRecommendation sku={vehicle.sku} price={vehicle.price} pricing={vehicle.pricing} />;
        } else if (hasPermission('STOCK_PRICE_UPDATE') || !vehicle?.pricing?.rating || vehicle.pricing.rating <= 1) {
            return <StockPriceChange sku={vehicle.sku} />;
        }
        return '';
    };

    return (
        <Card className="price-handler">
            <Card.Header>
                <div className="d-flex justify-content-between">
                    <div>
                        <i className="card__icon la-lg las la-money-bill-wave text-dark" />
                        <FormattedMessage id="STOCK_VEHICLE.CARD.PRICE_HANDLER.TITLE" />
                    </div>
                    {vehicle?.pricing?.radar && (
                        <div className="radar_icon">
                            <a href={useRadarEncoder(vehicle.pricing.radar)} target="_blank" rel="noreferrer">
                                <img src="/media/icons/radar_icon.svg" alt="radar" />
                            </a>
                        </div>
                    )}
                </div>
            </Card.Header>
            <Card.Body>
                <div
                    className="d-flex flex-column align-items-center h-100"
                    style={{ minHeight: '10vh', maxHeight: '75vh' }}
                >
                    <Permission permissions={['STOCK_VIEW_PRICE_MANAGER']}>
                        <div className="w-100 h-50">
                            <StockPriceDetails vehicle={vehicle} />
                            {stockPriceContent()}
                        </div>
                    </Permission>
                    <StockPriceHistory vehicle={vehicle} dispatch={dispatch} history={history} showTitle={false} />
                </div>
            </Card.Body>
        </Card>
    );
};

export default StockVehiclePriceHandler;
