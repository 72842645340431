import { Checkbox } from 'primereact/checkbox';
import React, { useEffect, useMemo, useState } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';
import useQueryParams from '@app/hooks/useQueryParams';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { routeTo } from '@app/helpers/RoutesHelper';

import { getIndicators, getPricingDuration, getVehicles } from '@app/crud/stock/stock.crud';

import { useFetch, usePermissions } from '@app/hooks';
import ROUTES from '@app/router/Routes';
import { actions as stockActions } from '@app/store/stock/stock.store';

import FiltersProvider from '@app/partials/content/FiltersProvider';
import toast from '@app/partials/content/Toast';
import HeadBand from '@app/partials/layout/HeadBand';

import PricingHandBand from '../../headbands/PricingHandBand';
import { getNewDealerIds } from '../../helpers/Perimeter';

import StockPricingTodoDatatable from './StockPricingTodoDatatable';
import StockPricingToDoHeader from './StockPricingToDoHeader';

const HEIGHT = '150px';
const SCROLL_HEIGHT = '60px';

const StockPricingTodo = () => {
    const Intl = useIntl();
    const dispatch = useDispatch();
    const [loadedPerimeter, setLoadedPerimeter] = useState(false);
    const [pricingDuration, setPricingDuration] = useState();
    const [counters, setCounters] = useState({
        list: 0,
        check: 0,
    });
    const [activeTab, setActiveTab] = useState('list');
    const { getValues } = useFormContext();
    const history = useHistory();
    const { page } = useParams();
    const isHistoryPage = page === 'history';
    const queryParams = useQueryParams();
    const location = useLocation();
    const { hasPermission } = usePermissions();
    const { userData } = useSelector((state) => state.auth);
    const { fetch } = useFetch({
        fetchAction: getIndicators,
    });

    const { fetch: fetchPricingDuration } = useFetch({
        fetchAction: getPricingDuration,
    });

    const extraParams = useMemo(
        () => ({
            loadedPerimeter,
        }),
        [loadedPerimeter],
    );

    const {
        start,
        filters,
        sortField,
        sortOrder,
        resetFilter,
        resetFilters,
        updatePendingFilters,
        releasePendingFilters,
    } = useFilters({
        name: 'stock_pricing_to_do',
    });

    const { data, handleFilter, handlePagination, handleSort, loading, refresh } = useTableHandlers({
        filterStore: 'stock_pricing_to_do',
        fetchAction: getVehicles,
        withStore: false,
        extraParams,
        // eslint-disable-next-line @typescript-eslint/no-shadow
        doSearch: (_, extraParams) => extraParams?.loadedPerimeter,
        // eslint-disable-next-line @typescript-eslint/no-shadow
        paramsInterceptor: (params, filters) => {
            const perimeter = getValues('perimeter');
            let dealer_id = params?.dealer_id;
            let sortFieldValue;
            let sortDirectionValue;

            if (!dealer_id) {
                dealer_id = getNewDealerIds(filters?.filters, perimeter);
            }

            if (isHistoryPage) {
                sortFieldValue = 'date_pricing_event';
                sortDirectionValue = 'desc';
            } else {
                sortFieldValue = filters?.sortField || null;
                sortDirectionValue = filters?.sortOrder === 1 ? 'asc' : 'desc';
            }

            return {
                ...params,
                from: filters.start, // to start page 0
                size: 10,
                sortField: sortFieldValue,
                sortDirection: sortDirectionValue,
                dealer_id,
                has_pricing_reco: page === 'list' ? true : null,
                is_pricing_histo: page === 'history' ? true : null,
                has_pricing_check: page === 'check' ? true : null,
                has_refusal_streak: page === 'check' ? filters.filters?.has_refusal_streak : null,
                older_than_120_days: filters.filters?.older_than_120_days,
                context: userData.context,
            };
        },
    });
    const hasPermissionToCheckPricing = hasPermission('STOCK_CHECK_PRICING');

    useEffect(() => {
        // Get queryParams from dashboard to display the right tabs
        if (queryParams?.activeTab) {
            setActiveTab(queryParams?.activeTab);
            history.replace(location.pathname);
        }
    }, [queryParams]);

    useEffect(() => {
        fetchPricingDuration()
            .then((response) => {
                if (response?.result?.duration) {
                    setPricingDuration(response.result.duration);
                }
            })
            .catch(() => {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                });
            });
    }, [fetchPricingDuration]);

    const resetSelectedVehicles = () => {
        dispatch(stockActions.UpdateVehicleSelected({}));
    };

    useEffect(() => {
        resetSelectedVehicles();
        if (page !== 'list' && page !== 'check' && page !== 'history') {
            toast({
                onShow: history.push(routeTo(ROUTES.STOCK_PRICING_TO_DO.PATH, { page: 'list' })),
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        } else {
            resetFilters(['has_pricing_reco', 'is_pricing_histo', 'has_pricing_check']);
            if (page === 'list') handleFilter({ has_pricing_reco: true });
            else if (page === 'history') handleFilter({ has_pricing_reco: true });
            else if (page === 'check') handleFilter({ has_pricing_check: true });
        }
    }, [page]);

    useEffect(() => {
        history.push(routeTo(ROUTES.STOCK_PRICING_TO_DO.PATH, { page: activeTab }));
        handlePagination({
            page: 0,
            start: 0,
            rows: 10,
        });
    }, [activeTab]);

    useEffect(() => {
        fetch({
            indicators: [activeTab === 'list' && 'pricing_to_check', activeTab === 'check' && 'pricing_to_process'],
        }).then((response) => {
            if (response) {
                if (activeTab === 'list')
                    setCounters({
                        list: data.total,
                        check: response.pricing_to_check,
                    });
                else if (activeTab === 'check')
                    setCounters({
                        list: response.pricing_to_process,
                        check: data.total,
                    });
            }
        });
    }, [data]);

    return (
        <FiltersProvider value={filters}>
            <Card className="p-4">
                <HeadBand
                    fixed
                    height={HEIGHT}
                    scrollHeight={SCROLL_HEIGHT}
                    parent="kt_subheader"
                    variant="customers-primary"
                >
                    <PricingHandBand count={data.total} type={activeTab} isHistory={isHistoryPage} />
                </HeadBand>
                <StockPricingToDoHeader
                    handleFilter={handleFilter}
                    updatePendingFilters={updatePendingFilters}
                    releasePendingFilters={releasePendingFilters}
                    setLoadedPerimeter={setLoadedPerimeter}
                    isHistory={isHistoryPage}
                    page={page}
                />
                <div
                    className={`position-relative d-flex justify-content-between ${
                        !isHistoryPage && !hasPermission('STOCK_CHECK_PRICING') && 'text-right'
                    }`}
                >
                    {!isHistoryPage && hasPermission('STOCK_CHECK_PRICING') && (
                        <Tabs
                            className="nav-tabs-line nav-tabs-line-primary nav-tabs-line-2x"
                            defaultActiveKey={activeTab}
                            activeKey={activeTab}
                            id={activeTab}
                            onSelect={(e) => {
                                setActiveTab(e);
                            }}
                        >
                            <Tab
                                tabClassName="nav-item--over"
                                eventKey="list"
                                title={
                                    <FormattedHTMLMessage
                                        id="STOCK.PRICING.TABS.LIST"
                                        values={{
                                            count: counters.list ? counters.list : 0,
                                        }}
                                    />
                                }
                            />
                            <Tab
                                eventKey="check"
                                title={`${Intl.formatMessage({
                                    id: 'STOCK.PRICING.TABS.CHECK',
                                })} (${counters.check})`}
                            />
                        </Tabs>
                    )}
                    <div className="d-flex flex-row-reverse">
                        {hasPermissionToCheckPricing && !isHistoryPage && (
                            <div className="d-flex">
                                <span className="d-flex items-center mt-4 font-weight-bold">
                                    <Checkbox
                                        inputId="older_than_120_days"
                                        disabled={loading}
                                        onChange={(e) =>
                                            e.checked
                                                ? handleFilter({
                                                      older_than_120_days: true,
                                                  })
                                                : resetFilter('older_than_120_days')
                                        }
                                        checked={filters.older_than_120_days || false}
                                        className="mx-2"
                                    />
                                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                    <label htmlFor="older_than_120_days">
                                        <FormattedMessage id="STOCK.PRICING.TITLE.OLDER_THAN_120_DAYS" />
                                    </label>
                                </span>
                                {page === 'check' && (
                                    <span className="d-flex items-center mt-4 font-weight-bold">
                                        <Checkbox
                                            inputId="refusal_streak"
                                            disabled={loading}
                                            onChange={(e) =>
                                                e.checked
                                                    ? handleFilter({
                                                          has_refusal_streak: true,
                                                      })
                                                    : resetFilter('has_refusal_streak')
                                            }
                                            checked={filters.has_refusal_streak || false}
                                            className="mx-2"
                                        />
                                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                        <label htmlFor="refusal_streak">
                                            <FormattedMessage id="STOCK.PRICING.TITLE.REFUSAL_STREAK.LABEL" />
                                        </label>
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <StockPricingTodoDatatable
                    handleFilter={handleFilter}
                    handlePagination={handlePagination}
                    handleSort={handleSort}
                    loading={loading}
                    actions={data?.results || []}
                    total={data?.total}
                    items={10}
                    start={start}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    page={page}
                    isHistory={isHistoryPage}
                    duration={pricingDuration}
                    refresh={refresh}
                />
            </Card>
        </FiltersProvider>
    );
};

export default StockPricingTodo;
