import classNames from 'classnames';
import React, { KeyboardEvent, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

type SearchInputProps = {
    className?: string;
    name: string;
    value: string;
    onChange: (value: string) => void;
    placeholder: string;
    disabled?: boolean;
};

const SearchInput = ({ className, name, value, onChange, placeholder, disabled }: SearchInputProps) => {
    const [currentValue, setCurrentValue] = useState(value);

    const handleChange = () => {
        onChange(currentValue?.trim?.());
    };

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    return (
        <Row className={classNames(className, 'search-input')}>
            <Col lg={11} xs={10} className="px-lg-4 px-0 kt-radius radius">
                <Form.Group>
                    <Form.Control
                        name={name}
                        value={currentValue}
                        onKeyUp={(e: KeyboardEvent) => {
                            if (e.key === 'Enter') {
                                handleChange();
                            }
                        }}
                        onChange={(e) => {
                            setCurrentValue(e.target.value || '');
                        }}
                        className="kt-radius-lg-5 kt-radius-0 kt-top-left-radius-5 kt-bottom-left-radius-5"
                        type="text"
                        placeholder={placeholder}
                        disabled={disabled}
                    />
                </Form.Group>
            </Col>
            <Col lg={1} xs={2} className="search-input__button">
                <Button
                    onClick={handleChange}
                    className="btn-icon kt-radius-lg-5 kt-radius-0 kt-top-right-radius-5 kt-bottom-right-radius-5"
                    variant="primary"
                    disabled={disabled}
                >
                    <i className="fas fa-search" />
                </Button>
            </Col>
        </Row>
    );
};

export default SearchInput;
