import * as dayjs from 'dayjs';
import { isNull } from 'lodash';
import { Column } from 'primereact/column';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';
import useModal from '@app/hooks/useModal';
import usePermissions from '@app/hooks/usePermissions';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { routeTo } from '@app/helpers/RoutesHelper';

import ROUTES from '@app/router/Routes';
import { fetchOrders } from '@app/store/manufacturer/order/order.thunk';

import { CalendarFilter } from '@app/partials/content/CalendarFilter';
import DropdownFilter from '@app/partials/content/DropdownFilter';
import FiltersProvider from '@app/partials/content/FiltersProvider';
import HoCDataTable from '@app/partials/content/HoCDataTable';
import InputFilter from '@app/partials/content/InputFilter';
import Label from '@app/partials/content/Label';
import Permission from '@app/partials/content/Permission';
import RefreshTable from '@app/partials/content/RefreshTable';
import SearchInputFilter from '@app/partials/content/SearchInputFilter';
import { Filters } from '@app/partials/layout/Filters';

import { CONSIDERED_CONFIRMED_APPOINTMENT_STATUS, getStateBySlug } from './Helpers/Status';
import ConfirmAffectation from './Modals/ConfirmAffectation';
import ConfirmAppointment from './Modals/ConfirmAppointment';
import ConfirmAffectationButton from './partials/ConfirmationAffectationButton';
import ConfirmButton from './partials/ConfirmButton';

const OrderTable = () => {
    const Intl = useIntl();
    const { hasPermission } = usePermissions();
    const { orders, totalRecords, filtersContent, loading } = useSelector((state) => state.manufacturer.order);
    const { items, start, filters, sortField, sortOrder } = useFilters({
        name: `manufacturer_orders`,
    });
    const { handleFilter, handlePagination, handleSort, refresh } = useTableHandlers({
        filterStore: `manufacturer_orders`,
        fetchAction: fetchOrders,
        paramsInterceptor: (params, interceptorFilters) => {
            const newParams = { ...params };
            delete newParams.details;
            if (params?.details) {
                switch (params.details) {
                    case 'assigned':
                        newParams.orderAppointmentAffected = true;
                        break;
                    case 'unassigned':
                        newParams.orderAppointmentAffected = false;
                        break;
                    case 'confirmed':
                        newParams.orderAppointmentConfirmed = true;
                        break;
                    case 'unconfirmed':
                        newParams.orderAppointmentConfirmed = false;
                        break;
                    default:
                        break;
                }
            }
            return {
                ...newParams,
                start: interceptorFilters.start,
                items: interceptorFilters.items,
            };
        },
    });
    const [showConfirmAffectationModal, toggleConfirmAffectationModal] = useModal(false);
    const [showConfirmAppointmentModal, toggleConfirmAppointmentModal] = useModal(false);
    const [selectedOrderAppointmentId, setSelectedOrderAppointmentId] = useState('');

    const selectStatusDropdown = () => (
        <DropdownFilter
            optionLabel="name"
            filterBy="key"
            name="orderAppointmentStatus"
            options={
                filtersContent?.orderAppointmentStatus &&
                Object.values(filtersContent.orderAppointmentStatus).map((elm) => ({
                    name: elm.name,
                    key: elm.slug,
                }))
            }
            onChange={handleFilter}
        />
    );

    const selectDealershipDropdown = () => (
        <DropdownFilter
            optionLabel="name"
            filterBy="name"
            name="dealership[refDealership]"
            options={
                filtersContent?.dealership &&
                Object.values(filtersContent.dealership).map((elm) => ({
                    name: elm.usualName,
                    key: elm.refDealership,
                }))
            }
            onChange={handleFilter}
        />
    );

    const selectDetails = () => (
        <DropdownFilter
            optionLabel="name"
            filterBy="key"
            name="details"
            options={[
                {
                    name: Intl.formatMessage({ id: 'MANUFACTURER.ORDER.FILTER.AFFECTATION.ASSIGNED' }),
                    key: 'assigned',
                },
                {
                    name: Intl.formatMessage({ id: 'MANUFACTURER.ORDER.FILTER.AFFECTATION.NOT_ASSIGNED' }),
                    key: 'unassigned',
                },
                {
                    name: Intl.formatMessage({ id: 'MANUFACTURER.ORDER.FILTER.APPOINTMENT.CONFIRMED' }),
                    key: 'confirmed',
                },
                {
                    name: Intl.formatMessage({ id: 'MANUFACTURER.ORDER.FILTER.APPOINTMENT.NOT_CONFIRMED' }),
                    key: 'unconfirmed',
                },
            ]}
            onChange={handleFilter}
        />
    );

    const inputFilter = (name) => <InputFilter name={name} onChange={handleFilter} />;

    const calendarFilter = (name) => <CalendarFilter name={name} onChange={handleFilter} />;

    const vehicleBodyTemplate = (order) => (
        <>
            {order.make} {order.model}
        </>
    );

    const dealershipBodyTemplate = (order) => {
        const title = order?.dealership?.usualName || null;
        return (
            <>
                <span className={`${title && 'mr-3'}`}>{title}</span>
                {order?.dealership?.isManagedByCallCenter && (
                    <Label variant="primary">
                        <i className="mr-2 fas fa-headphones" />
                        {Intl.formatMessage({ id: 'MANUFACTURER.ORDER.TABLE.CONTENT.CALL' })}
                    </Label>
                )}
            </>
        );
    };

    const statusBodyTemplate = (order) => (
        <Label variant={getStateBySlug(order)}>
            {order?.appointment?.orderAppointmentStatus?.name || order?.orderStatus?.name}
        </Label>
    );

    const appointmentBodyTemplate = (order) =>
        order?.appointment ? dayjs(order.appointment.startDate).format('DD/MM/YYYY [à] HH [h] mm') : '';

    const createdBodyTemplate = (order) => dayjs(order.orderDate).format('DD/MM/YYYY [à] HH [h] mm');

    const confirmAffectation = (orderAppointmentId) => {
        setSelectedOrderAppointmentId(orderAppointmentId);
        toggleConfirmAffectationModal(true);
    };

    const confirmAppointment = (orderAppointmentId) => {
        setSelectedOrderAppointmentId(orderAppointmentId);
        toggleConfirmAppointmentModal(true);
    };

    const detailsBodyTemplate = (order) => (
        <div className="d-flex justify-content-center">
            <Link
                to={routeTo(ROUTES.MANUFACTURER_ORDER.PATH, {
                    id: order.cryptedId,
                })}
            >
                <Button className="btn-icon" variant="primary">
                    <i className="las la-eye" />
                </Button>
            </Link>
            {!order?.dealership?.isActiveMecaplanning ? (
                <Permission permissions={['APV_CONFIRMATION_APPOINTMENT_ORDER_SITES_CE']}>
                    <ConfirmButton
                        orderAppointmentId={order?.appointment?.cryptedId}
                        isConfirmed={CONSIDERED_CONFIRMED_APPOINTMENT_STATUS.includes(
                            order?.appointment?.orderAppointmentStatus?.slug,
                        )}
                        btnVariant="danger"
                        confirm={confirmAppointment}
                    />
                </Permission>
            ) : (
                !isNull(order?.appointment?.affected) && (
                    <Permission permissions={['APV_CONFIRMATION_ASSIGN_ORDER_SITES_CE']}>
                        <ConfirmAffectationButton
                            orderAppointmentId={order?.appointment?.cryptedId}
                            isAffected={order?.appointment?.affected}
                            btnVariant="danger"
                            updateAffectation={confirmAffectation}
                        />
                    </Permission>
                )
            )}
        </div>
    );

    const cols = [
        {
            field: 'reference',
            header: Intl.formatMessage({ id: 'MANUFACTURER.ORDER.TABLE.HEAD.REFERENCE' }),
            style: { width: '8%' },
            filter: true,
            filterElement: inputFilter('reference'),
        },
        {
            field: 'customer',
            header: Intl.formatMessage({ id: 'MANUFACTURER.ORDER.TABLE.HEAD.CUSTOMER' }),
            style: { width: '10%' },
            filter: true,
            filterElement: inputFilter('customer[name]'),
            body: (order) => `${order?.customer?.firstName || ''} ${order?.customer?.lastName || ''}`,
        },
        {
            field: 'vehicleName',
            header: Intl.formatMessage({ id: 'MANUFACTURER.ORDER.TABLE.HEAD.VEHICLE' }),
            style: { width: '12%' },
            filter: true,
            filterElement: inputFilter('make_model'),
            body: vehicleBodyTemplate,
        },
        {
            field: 'dealership',
            header: Intl.formatMessage({ id: 'MANUFACTURER.ORDER.TABLE.HEAD.DEALERSHIP' }),
            style: { width: '13%' },
            filter: true,
            filterElement: selectDealershipDropdown(),
            body: dealershipBodyTemplate,
        },
        {
            field: 'status',
            header: Intl.formatMessage({ id: 'MANUFACTURER.ORDER.TABLE.HEAD.STATUS' }),
            style: { width: '10%' },
            filter: true,
            filterElement: selectStatusDropdown(),
            body: statusBodyTemplate,
        },
        {
            field: 'appointment_date',
            header: Intl.formatMessage({ id: 'MANUFACTURER.ORDER.TABLE.HEAD.APPOINTMENT' }),
            style: { width: '10%' },
            filter: true,
            filterElement: calendarFilter('orderAppointmentDate'),
            body: appointmentBodyTemplate,
        },
        {
            field: 'order_date',
            header: Intl.formatMessage({ id: 'MANUFACTURER.ORDER.TABLE.HEAD.ORDER' }),
            style: { width: '10%' },
            filter: true,
            filterElement: calendarFilter('created'),
            body: createdBodyTemplate,
        },
    ];

    if (hasPermission('APV_DETAILS_ORDER')) {
        cols.push({
            field: 'details',
            header: Intl.formatMessage({ id: 'MANUFACTURER.ORDER.TABLE.HEAD.ACTIONS' }),
            style: { width: '6%' },
            filter: true,
            filterElement: selectDetails(),
            body: detailsBodyTemplate,
        });
    }

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <>
            <FiltersProvider value={filters}>
                <Filters>
                    <Row>
                        <RefreshTable count={totalRecords} onClick={refresh} />
                        <Col lg={5} offset={2}>
                            <SearchInputFilter
                                onChange={handleFilter}
                                name="q"
                                placeholder="Client, référence, véhicule…"
                            />
                        </Col>
                    </Row>
                </Filters>
                <HoCDataTable
                    value={orders}
                    totalRecords={totalRecords}
                    lazy
                    loading={loading}
                    paginator
                    onFilter={handleFilter}
                    onPage={handlePagination}
                    onSort={handleSort}
                    paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                    currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                    rows={items}
                    first={start}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    removableSort
                    filterDisplay="row"
                    rowsPerPageOptions={[25, 50, 100]}
                    emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                >
                    {dynamicColumns}
                </HoCDataTable>
            </FiltersProvider>
            <ConfirmAffectation
                showModal={showConfirmAffectationModal}
                setShowModal={toggleConfirmAffectationModal}
                selectedOrderAppointmentId={selectedOrderAppointmentId}
                refreshMethod={refresh}
            />
            <ConfirmAppointment
                showModal={showConfirmAppointmentModal}
                setShowModal={toggleConfirmAppointmentModal}
                selectedOrderAppointmentId={selectedOrderAppointmentId}
                refreshMethod={refresh}
            />
        </>
    );
};

export default OrderTable;
