import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import useFetch from '@app/hooks/useFetch';

import { empty } from '@app/helpers/ToolsHelper';

import { getStock } from '@app/crud/customers/stock.crud';

import VehicleDataTable from '@app/pages/customers/Partials/Vehicle/VehicleDataTable';
import VehicleFilters from '@app/pages/customers/Partials/Vehicle/VehicleFilters';

import Loader from '@app/partials/content/Loader';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';
import { getVehicleInternalIdTemplate } from '@app/partials/content/Vehicle/VehicleHelpers';

const VehicleSearch = ({ excludedVehicles, showModal, vehicleType, onSelect, setShowModal }) => {
    const filtersRef = useRef();
    const tableScrollRef = useRef();
    const [maxHeight, setMaxHeight] = useState(0);
    const { fetch, data, loading } = useFetch({
        fetchAction: getStock,
        resultInterceptor: (newData, oldData, params) => {
            const nextData = { ...newData };

            if (oldData?.vehicles && newData?.result?.vehicles && !params?.reset) {
                nextData.result.vehicles = [...oldData.vehicles, ...newData.result.vehicles];
            }

            return nextData?.result || [];
        },
    });
    const methods = useForm({
        defaultValues: {
            size: 30,
            from: 0,
        },
    });
    const { register, getValues, setValue } = methods;
    const Intl = useIntl();

    const handleSearch = useCallback(
        (reset = true) => {
            const params = Object.entries(getValues()).reduce((acc, [param, value]) => {
                if (!empty(value)) acc[param] = value;
                return acc;
            }, {});

            for (const data in params) {
                if (data.endsWith('_slider')) {
                    params[data.replace(/_slider$/, '')] = `${params[data][0]},${params[data][1]}`;
                    delete params[data];
                }
            }

            fetch(
                {
                    ...params,
                },
                {
                    reset,
                },
            );
        },
        [getValues, fetch],
    );

    const onSubmit = () => {
        tableScrollRef.current.scrollTop = 0;
        setValue('from', 0);
        handleSearch();
    };

    const handleTableScroll = () => {
        const elm = tableScrollRef.current;

        // +1 to enter in if at the end of the scroll
        if (parseInt(elm.scrollHeight - elm.scrollTop, 10) === parseInt(elm.clientHeight, 10) + 1) {
            setValue('from', getValues('from') + 30);
            handleSearch(false);
        }
    };

    const isExcludedVehicle = (vehicle) =>
        !!excludedVehicles.find(
            (excludedVehicle) =>
                getVehicleInternalIdTemplate(excludedVehicle) === getVehicleInternalIdTemplate(vehicle),
        );

    const handleSelect = (vehicle) => {
        if (isExcludedVehicle(vehicle)) {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR.ALREADY_SELECTED_CAR' }),
            });
            return false;
        }

        if (onSelect) {
            onSelect(vehicle);
            setShowModal(false);
        }
    };

    useEffect(() => {
        if (showModal) {
            if (vehicleType) {
                switch (vehicleType) {
                    case 'is_fluidite':
                        setValue('is_fluidite', true);
                        break;
                    case 'in_stock':
                        setValue('internal_type', 'vo');
                        break;
                    default:
                        break;
                }
            }
            handleSearch();
        } else {
            tableScrollRef.current = undefined;
        }
    }, [showModal]);

    useEffect(() => {
        register('size');
        register('from');
    }, [register]);

    useEffect(() => {
        setMaxHeight(filtersRef?.current ? `${filtersRef.current.clientHeight}px` : 0);
    }, [setMaxHeight, filtersRef.current]);

    return (
        <ModalDefault
            size="xl"
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x la-car text-primary" />}
            title={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE.TITLE' })}
            body={
                <FormProvider {...methods}>
                    <div className="d-flex flex-wrap position-relative mb-10">
                        {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
                        <div className="col px-0 flex-grow-0" ref={filtersRef}>
                            <VehicleFilters data={data} onSubmit={onSubmit} />
                        </div>
                        <Col
                            className="overflow-auto mt-lg-0 mt-8 flex-grow-1"
                            ref={(e) => {
                                if (e && !tableScrollRef.current) {
                                    tableScrollRef.current = e;
                                    tableScrollRef.current.addEventListener('scroll', handleTableScroll);
                                }
                            }}
                            style={{
                                maxHeight,
                            }}
                        >
                            <VehicleDataTable data={data} filters={getValues()} onSelect={handleSelect} />
                        </Col>
                    </div>
                </FormProvider>
            }
        />
    );
};

VehicleSearch.defaultProps = {
    excludedVehicles: [],
};

export default VehicleSearch;
