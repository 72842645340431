import { Column } from 'primereact/column';
import React, { useEffect } from 'react';
import { FormattedHTMLMessage, useIntl } from 'react-intl';

import useFilters from '@app/hooks/useFilters';

import { getDirectoryUsers } from '@app/crud/autouser/user.crud';

import { User } from '@app/store/login/login.store';

import DropdownFilter from '@app/partials/content/DropdownFilter';
import FiltersProvider from '@app/partials/content/FiltersProvider';
import HoCDataTable from '@app/partials/content/HoCDataTable';
import InputFilter from '@app/partials/content/InputFilter';
import Loader from '@app/partials/content/Loader';

import useTableHandlers from '../../hooks/useTableHandlers';

interface DirectorySearchType {
    search: string;
    dealershipResearch?: boolean;
}

const DirectoryUsers = ({ search, dealershipResearch = false }: DirectorySearchType) => {
    const Intl = useIntl();
    const { items, start, sortField, sortOrder, updateFilters, filters } = useFilters({
        name: 'directory_users_search',
        persisted: false,
        defaultValues: {
            items: 50,
            sortField: 'fullName',
            sortOrder: 1,
        },
    });

    const { data, loading, handleFilter, handlePagination, handleSort } = useTableHandlers({
        filterStore: 'directory_users_search',
        // @ts-ignore
        fetchAction: getDirectoryUsers,
        withStore: false,
        paramsInterceptor: (_, allFilters) => {
            const cleanedFilters = Object.keys(allFilters.filters).reduce((acc, key) => {
                if (![null, '', undefined].includes(allFilters.filters[key]?.value)) {
                    // @ts-ignore
                    acc[key] = allFilters.filters[key].value;
                } else if (
                    ![null, '', undefined].includes(allFilters.filters[key]) &&
                    allFilters.filters[key]?.value === undefined
                ) {
                    // @ts-ignore
                    acc[key] = allFilters.filters[key];
                }
                return acc;
            }, {});

            return {
                items: !dealershipResearch ? allFilters.items : undefined,
                page: !dealershipResearch ? allFilters.page - 1 : undefined,
                visibleForDealershipsDirectory: dealershipResearch ? true : undefined,
                active: true,
                external: false,
                sort: allFilters.sortField
                    ? `${allFilters.sortField}_${allFilters.sortOrder > 0 ? 'asc' : 'desc'}`
                    : undefined,
                ...allFilters.items,
                ...allFilters.page,
                ...cleanedFilters,
            };
        },
    });

    useEffect(() => {
        if (dealershipResearch) {
            updateFilters({
                filters: {
                    dealershipResearchFullPerimeter: search,
                    research: undefined,
                },
            });
        } else {
            updateFilters({
                filters: {
                    research: search,
                    dealershipResearchFullPerimeter: undefined,
                },
            });
        }
    }, [search]);

    const selectRolesDropdown = () => (
        <DropdownFilter
            optionLabel="name"
            filterBy="name"
            name="roles"
            options={Object.values(data?.facets?.roles ?? [])?.map?.((elm) => ({
                name: elm.name,
                key: elm.slug,
            }))}
            onChange={handleFilter}
        />
    );

    const nameBodyTemplate = (user: User) => (
        <div style={{ minHeight: '35px' }} className="d-flex align-items-center">
            {user?.first_name || ''}
            {user?.first_name && user?.last_name && ' '}
            {user?.last_name || ''}
        </div>
    );

    const roleBodyTemplate = (user: User) => user?.role?.name || user?.role;

    const emailBodyTemplate = (user: User) => user.email;

    const perimeterBodyTemplate = (user: User) => (
        <div>
            {user?.dealership_perimeter?.map?.((perimeter) => (
                <div className="label m-1 label-light-secondary label-inline label-lg">{perimeter?.name}</div>
            ))}
        </div>
    );

    const inputFilter = (name: string) => <InputFilter name={name} onChange={handleFilter} />;

    const cols = [
        {
            field: 'fullName',
            header: Intl.formatMessage({ id: 'AUTOUSER.USERS.TABLE.HEAD.USERNAME' }),
            body: nameBodyTemplate,
            filter: true,
            filterElement: inputFilter('fullName'),
            sortable: true,
        },
        {
            field: 'roles',
            header: Intl.formatMessage({ id: 'AUTOUSER.USERS.TABLE.HEAD.ROLE' }),
            body: roleBodyTemplate,
            filter: true,
            filterElement: selectRolesDropdown(),
            sortable: true,
        },
        {
            field: 'email',
            header: Intl.formatMessage({ id: 'AUTOUSER.USERS.TABLE.HEAD.EMAIL' }),
            body: emailBodyTemplate,
            filter: true,
            filterElement: inputFilter('email'),
            sortable: true,
        },
        {
            field: 'phone',
            header: Intl.formatMessage({ id: 'AUTOUSER.USERS.TABLE.HEAD.PHONE' }),
            body: (user: User) => user.phone,
            filter: true,
            filterElement: inputFilter('phone'),
        },
        {
            field: 'cellPhone',
            header: Intl.formatMessage({ id: 'AUTOUSER.USERS.TABLE.HEAD.MOBILE' }),
            body: (user: User) => user.cell_phone,
            filter: true,
            filterElement: inputFilter('cellPhone'),
        },
        {
            field: 'dealershipResearchFullPerimeter',
            header: Intl.formatMessage({ id: 'AUTOUSER.USERS.TABLE.HEAD.PERIMETER' }),
            body: perimeterBodyTemplate,
            filter: true,
            filterElement: inputFilter('dealershipResearchFullPerimeter'),
            style: dealershipResearch ? { display: 'none' } : {},
        },
    ];

    // @ts-ignore
    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    const users = data?.result || null;

    return (
        <FiltersProvider value={filters}>
            {loading && <Loader block style={{ width: '5rem', height: '5rem' }} />}
            {!loading && (
                <div className="py-5">
                    {!dealershipResearch && (
                        <div className="my-10 font-size-h4">
                            <FormattedHTMLMessage
                                id="DIRECTORY.TABLE.VIEW.FOUND.NUMBER"
                                values={{
                                    count: data?.count ?? 0,
                                    search,
                                }}
                            />
                        </div>
                    )}
                    {/* @ts-ignore */}
                    <HoCDataTable
                        className="users-datatable"
                        value={users}
                        totalRecords={data?.count || 0}
                        lazy
                        paginator={!dealershipResearch}
                        paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                        currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                        loading={loading}
                        onPage={handlePagination}
                        onFilter={handleFilter}
                        onSort={handleSort}
                        rows={items}
                        first={start}
                        sortField={sortField}
                        sortOrder={sortOrder}
                        removableSort
                        filterDisplay="row"
                        rowsPerPageOptions={[25, 50, 100]}
                        emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                    >
                        {dynamicColumns}
                    </HoCDataTable>
                </div>
            )}
        </FiltersProvider>
    );
};

export default DirectoryUsers;
