import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { empty } from '@app/helpers/ToolsHelper';

import { getOneCustomerActivity } from '@app/crud/customers/customer.crud';

import { useFetch } from '@app/hooks';
import useActionHandler from '@app/pages/customers/Hooks/useActionHandler';
import AddCommentAction from '@app/pages/customers/Partials/customer-life/EventDetailAPVActivities/AddCommentAction';
import LeadActions from '@app/pages/customers/Partials/LeadRow/LeadActions';

import { ChatMessage } from '@app/partials/content/ChatMessage';

const APVActivitiesTimeline = ({ data, event }) => {
    const [leadItem, setLeadItem] = useState();

    const excludedTypes = [
        'update_lead_pending',
        'update_lead_pending_30_status',
        'update_lead_pending_60_status',
        'update_lead_pending_90_status',
        'update_lead_pending_120_status',
        'take_lead',
        'treat_separately',
        'update_lead_taken_30_status',
        'update_lead_taken_60_status',
        'update_lead_taken_90_status',
        'update_lead_taken_120_status',
    ];

    const { data: customer } = useSelector((state) => state.customer.customer);
    const lead = customer.leads.filter((customerLead) => customerLead.id === data?.informations?.identifier)[0];
    const { fetch: fetchActivity, data: leadData } = useFetch({
        fetchAction: getOneCustomerActivity,
    });

    const handleActionClick = useActionHandler(lead || leadItem);

    useEffect(() => {
        if (data?.informations?.identifier) fetchActivity(data?.informations?.identifier);
    }, [fetchActivity, data]);

    useEffect(() => {
        setLeadItem({
            ...leadData.informations,
            leadType: {
                slug: leadData?.informations?.leadTypeSlug,
            },
            leadStatus: {
                slug: leadData?.informations?.statusSlug,
            },
            id: leadData?.informations?.identifier,
        });
    }, [leadData]);

    const messageTypeHandler = (slug, role) => {
        let type = {};

        switch (slug) {
            case 'add_answer_lead_apv':
            case 'update_closing_lead_status':
            case 'reassignment_lead':
                if (
                    role.toLowerCase().includes('autosphere-contact') ||
                    role.toLowerCase().includes('autosphere contact') ||
                    role.toLowerCase().includes('accueil') ||
                    role.toLowerCase().includes('secrétaire')
                ) {
                    type = {
                        color: 'success',
                        arrowPosition: 'right',
                        type: 'call',
                    };
                } else {
                    type = {
                        color: 'primary',
                        arrowPosition: 'left',
                        type: 'dealership',
                    };
                }
                break;
            default:
                type = {
                    color: 'success',
                    arrowPosition: 'right',
                    type: 'call',
                };
        }

        return type;
    };

    const isEventTypeAllowed = (slug) => !excludedTypes.includes(slug);

    const timelineHandler = useMemo(
        () =>
            event?.events?.map((activity) => {
                const type = messageTypeHandler(activity?.eventType?.slug, activity?.user?.role);

                return (
                    <React.Fragment key={`activity_${activity.event.id}`}>
                        {isEventTypeAllowed(activity?.eventType?.slug) && (
                            <div className={`timeline-item align-items-start timeline-item-${type.type}`}>
                                <div className="timeline-label w-50" style={{ fontSize: '13px' }}>
                                    {activity?.eventType?.slug === 'create_lead_local' && (
                                        <span>
                                            <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.APV_ACTIVITIES_DETAIL.CREATED" />
                                        </span>
                                    )}
                                    {activity?.eventType?.slug === 'update_closing_lead_status' && (
                                        <span>
                                            <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.APV_ACTIVITIES_DETAIL.CLOSED" />
                                        </span>
                                    )}
                                    {activity?.eventType?.slug === 'reassignment_lead' && (
                                        <span>
                                            <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.APV_ACTIVITIES_DETAIL.REASSIGNED" />
                                        </span>
                                    )}
                                    <div className="font-weight-normal">
                                        <p className={`m-0 text-${type.color}`}>
                                            <span className="font-weight-bold">
                                                {activity?.user?.firstname} {activity?.user?.lastname}&nbsp;•&nbsp;
                                                {activity?.user?.role}
                                            </span>
                                        </p>
                                        <p>
                                            <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.APV_ACTIVITIES_DETAIL.THE" />{' '}
                                            {dayjs(activity.date).format('DD/MM/YYYY [à] HH:mm')}{' '}
                                        </p>
                                    </div>
                                    {activity.action.message && (
                                        <ChatMessage
                                            message={activity.action.message}
                                            arrowPosition={type.arrowPosition}
                                            variant={activity.action?.highPriority === true ? 'danger' : type.color}
                                        />
                                    )}
                                </div>
                                <div className="timeline-badge timeline-badge__activity">
                                    <i className={`fa fa-genderless icon-xl text-${type.color}`} />
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                );
            }),
        [event],
    );

    return (
        !empty(data) && (
            <>
                <div className="timeline-handler h-auto">
                    <div
                        className="timeline timeline-reverse flex-column-reverse d-flex h-100"
                        style={{ maxWidth: 'none' }}
                    >
                        <AddCommentAction leadItem={leadItem} handleActionClick={handleActionClick} />
                        {timelineHandler}
                    </div>
                </div>
                <div className="d-flex justify-content-center mt-10">
                    <LeadActions lead={leadItem} onClick={handleActionClick} details={false} />
                </div>
            </>
        )
    );
};

export default APVActivitiesTimeline;
