import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import ModalDefault from '@app/partials/content/modals/Modal.default';

const CampaignCancel = ({ showModal, setShowModal, callback }) => (
    <ModalDefault
        show={showModal}
        hideModal={() => setShowModal(false)}
        icon={<i className="las la-3x text-primary la-laptop" />}
        title={<FormattedMessage id="CAMPAIGN.MODALS.CANCEL.TITLE" />}
        subtitle={<FormattedHTMLMessage id="CAMPAIGN.MODALS.CANCEL.SUBTITLE" />}
        footer={
            <div className="w-100 d-flex justify-content-center">
                <Button
                    variant="outline-secondary"
                    style={{ width: '170px' }}
                    className="mr-6"
                    onClick={() => {
                        setShowModal(false);
                    }}
                >
                    <FormattedMessage id="CAMPAIGN.MODALS.CANCEL.ABORT" />
                </Button>
                <Button
                    variant="primary"
                    style={{ width: '170px' }}
                    onClick={() => {
                        callback();
                    }}
                >
                    <FormattedMessage id="CAMPAIGN.PROGRESS.CANCEL" />
                </Button>
            </div>
        }
    />
);

export default CampaignCancel;
