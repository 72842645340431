import { Column } from 'primereact/column';
import React, { ReactNode } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, Link, useHistory } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { capitalize } from '@app/helpers/StringHelper';

import { getRoles } from '@app/crud/autouser/role.crud';
import { RoleType, UserRole } from '@app/crud/autouser/role.types';

import ROUTES from '@app/router/Routes';

import FiltersProvider from '@app/partials/content/FiltersProvider';
import HoCDataTable from '@app/partials/content/HoCDataTable';
import RefreshTable from '@app/partials/content/RefreshTable';
import SearchInputFilter from '@app/partials/content/SearchInputFilter';
import SelectFilter from '@app/partials/content/SelectFilter';
import { Filters } from '@app/partials/layout/Filters';

function Roles() {
    const Intl = useIntl();
    const history = useHistory();
    const { items, start, filters, sortField, sortOrder } = useFilters({
        name: 'autouser_roles_search',
    });
    const { data, loading, handleFilter, handlePagination, handleSort, refresh } = useTableHandlers({
        filterStore: 'autouser_roles_search',
        fetchAction: getRoles,
        withStore: false,
        // @ts-ignore
        paramsInterceptor: (params, paramsFilters) => ({
            ...params,
            start: paramsFilters.start,
            limit: paramsFilters.items,
        }),
    });

    const showAddRole = () => {
        history.push(generatePath(ROUTES.ROLE.PATH.CREATE));
    };

    const roles = data?.result || [];

    const totalRecords = data?.count || 0;

    const typeBodyTemplate = (role: UserRole) => <span>{role?.type !== null ? capitalize(role.type) : ''}</span>;

    const actionsBodyTemplate = (role: UserRole) => (
        <Link to={generatePath(ROUTES.ROLE.PATH.UPDATE, { id: role.id })}>
            <Button className="p-2 px-3" variant="secondary">
                <i className="fa fa-pen" />
                <FormattedMessage id="TRANSLATOR.UPDATE" />
            </Button>
        </Link>
    );

    const selectFilter = (name: string, options: ReactNode) => (
        <SelectFilter name={name} options={options} onChange={handleFilter} />
    );

    const typeFilter = (
        <>
            <option value="">{Intl.formatMessage({ id: 'AUTOUSER.ROLES.VIEW.TYPE.ALL_TYPES' })}</option>
            <option value={RoleType.FONCTIONNEL}>
                {Intl.formatMessage({ id: 'AUTOUSER.ROLES.VIEW.TYPE.FUNCTIONAL' })}
            </option>
            <option value={RoleType.SUPPORT}>{Intl.formatMessage({ id: 'AUTOUSER.ROLES.VIEW.TYPE.SUPPORT' })}</option>
        </>
    );

    const cols = [
        {
            field: 'name',
            header: Intl.formatMessage({ id: 'AUTOUSER.ROLES.TABLE.HEAD.NAME' }),
            style: { width: '20%' },
        },
        {
            field: 'shortName',
            header: Intl.formatMessage({ id: 'AUTOUSER.ROLES.TABLE.HEAD.SHORTNAME' }),
            style: { width: '10%' },
        },
        {
            field: 'description',
            header: Intl.formatMessage({ id: 'AUTOUSER.ROLES.TABLE.HEAD.DESCRIPTION' }),
            style: { width: '40%' },
        },
        {
            field: 'type',
            header: Intl.formatMessage({ id: 'AUTOUSER.ROLES.TABLE.HEAD.TYPE' }),
            style: { width: '15%' },
            body: typeBodyTemplate,
            filter: true,
            filterElement: selectFilter('type', typeFilter),
        },
        {
            field: 'actions',
            header: Intl.formatMessage({ id: 'AUTOUSER.ROLES.TABLE.HEAD.ACTIONS' }),
            style: { width: '15%' },
            body: actionsBodyTemplate,
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <Card>
            <Card.Body>
                <FiltersProvider value={filters}>
                    <Filters>
                        <Row>
                            <RefreshTable count={totalRecords} onClick={refresh} />
                            <Col className="mr-4" lg={{ span: 5, offset: 2 }}>
                                <SearchInputFilter
                                    onChange={handleFilter}
                                    name="research"
                                    placeholder="Nom du rôle, description."
                                />
                            </Col>
                            <Button variant="outline-primary" onClick={() => showAddRole()}>
                                <FormattedMessage id="AUTOUSER.ROLES.CREATE" />
                            </Button>
                        </Row>
                    </Filters>
                    {/* @ts-ignore */}
                    <HoCDataTable
                        value={roles}
                        totalRecords={totalRecords}
                        lazy
                        loading={loading}
                        paginator
                        onFilter={handleFilter}
                        onPage={handlePagination}
                        onSort={handleSort}
                        paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                        currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                        rows={items}
                        first={start}
                        sortField={sortField}
                        sortOrder={sortOrder}
                        removableSort
                        filterDisplay="row"
                        rowsPerPageOptions={[25, 50, 100]}
                        emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                    >
                        {dynamicColumns}
                    </HoCDataTable>
                </FiltersProvider>
            </Card.Body>
        </Card>
    );
}

export default Roles;
