import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { getActions } from '@app/pages/customers/Helpers/Actions';
import useAction from '@app/pages/customers/Hooks/useAction';
import { actions } from '@app/store/modal/modal.store';

import ActionButton from '@app/partials/content/ActionButton';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';
import { useAppSelector } from '@app/hooks';
import ConsignContactClient from '@app/crud/action/consign-contact';
import useLead from '../Hooks/useLead';
import useActionClient from '../Hooks/useActionClient';

type ConsignProps = {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
};

const Contact = ({ showModal, setShowModal }: ConsignProps) => {
    const dispatch = useDispatch();
    const Intl = useIntl();
    const [selectedAction, setSelectedAction] = useState(undefined);
    const { register, handleSubmit, setValue } = useForm();
    const { params } = useAppSelector((state) => state.modal);
    const lead = useLead(params?.leadId);

    const [caller, loading] = useActionClient(new ConsignContactClient(), {
        permission: 'LEAD_CONSIGN_CALL_CONTACT',
        notifyFail: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CONTACT.ERROR' }),
    });

    const handleClick = (action: string = undefined) => {
        setSelectedAction(action);

        switch (action) {
            case 'CONFIRM_CONTACT':
                setValue('result', "J'ai bien eu le client au téléphone. \nIl est intéressé par un véhicule.");
                break;
            case 'CALLBACK':
                dispatch(actions.modalChange('callback', params));
                break;
            default:
                setValue('result', '');
                break;
        }
    };

    const onSubmit = (data: any) => {
        caller({
            lead: lead?.id,
            result: data.result,
        })
            .then(() => {
                dispatch(
                    actions.modalChange('next_action', {
                        leadId: lead?.id,
                    }),
                );
            })
            .catch((reason: any) => {
                console.error(reason);
            });
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    useEffect(() => {
        setSelectedAction(undefined);
    }, [showModal]);

    const buttonActions = getActions(['consign_contact_done', 'appointment_callback']);

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x text-primary la-pen" />}
            title={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CONTACT.TITLE' })}
            body={
                <>
                    <div className="d-flex justify-content-center mt-4 mb-10">
                        {buttonActions &&
                            buttonActions?.length &&
                            buttonActions.map(([action, properties], key) => (
                                <ActionButton
                                    {...properties}
                                    key={action}
                                    active={!selectedAction ? undefined : selectedAction === action}
                                    onClick={() => handleClick(action)}
                                    className={`flex-grow-1 px-4 ${key + 1 < buttonActions.length ? 'mr-lg-6' : ''}`}
                                />
                            ))}
                    </div>
                    <Form.Group>
                        <Form.Label>{Intl.formatMessage({ id: 'TRANSLATOR.COMMENT' })}</Form.Label>
                        <Form.Control
                            as="textarea"
                            ref={register({
                                required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                            })}
                            name="result"
                            rows={4}
                        />
                    </Form.Group>
                </>
            }
            footer={
                <div className="float-right">
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)} className="mr-5">
                        {Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                    </Button>
                    <Button variant="primary" disabled={!selectedAction} onClick={handleSubmit(onSubmit, onError)}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.VALIDATE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default Contact;
