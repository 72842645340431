import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { getActions } from '@app/pages/customers/Helpers/Actions';

import ActionButton from '@app/partials/content/ActionButton';
import ModalDefault from '@app/partials/content/modals/Modal.default';

import { VehicleSearch } from './index';

import VehicleOutStock from './VehicleOutStock';

export type VehicleType = 'in_stock' | 'is_fluidite' | 'out_stock';
export interface Selection {
    type: VehicleType;
    vehicle: any;
}

interface Props {
    onSelect: (s: Selection) => void;
    showModal: boolean;
    setShowModal: (b: boolean) => void;
}

const InOutChoiceVehicle = ({ onSelect, showModal, setShowModal }: Props) => {
    const [vehicleType, setVehicleType] = useState<VehicleType | null>(null);
    const Intl = useIntl();

    const actions = getActions(['in_stock', 'out_stock', 'fluidity']);
    const handleClick = (action: string) => {
        switch (action) {
            case 'IN_STOCK':
                setVehicleType('in_stock');
                break;
            case 'OUT_STOCK':
                setVehicleType('out_stock');
                break;
            case 'FLUIDITY':
                setVehicleType('is_fluidite');
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        setVehicleType(null);
    }, [showModal]);

    const onSelectVehicle = (vehicle: any) => {
        onSelect({
            type: vehicleType,
            vehicle: vehicle,
        });
    };
    return (
        <ModalDefault
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x la-car text-primary" />}
            title={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.IN_OUT_CHOICE.VEHICLE.CHOICE' })}
            body={
                <>
                    <div className="d-flex flex-lg-nowrap flex-wrap justify-content-center mt-lg-5 mt-0 mb-lg-8 mb-0">
                        {actions.map(([action, properties]) => (
                            <ActionButton
                                {...properties}
                                style={{
                                    minHeight: '110px',
                                    minWidth: '140px',
                                }}
                                key={action}
                                onClick={() => handleClick(action)}
                                className="mr-lg-6 mx-lg-0 mx-4 mt-lg-0 mt-8"
                            />
                        ))}
                    </div>
                    <VehicleSearch
                        showModal={['in_stock', 'is_fluidite'].indexOf(vehicleType) >= 0}
                        onSelect={onSelectVehicle}
                        setShowModal={() => setVehicleType(null)}
                        vehicleType={vehicleType}
                    />
                    <VehicleOutStock
                        showModal={['out_stock'].indexOf(vehicleType) >= 0}
                        onSelect={onSelectVehicle}
                        setShowModal={() => setVehicleType(null)}
                    />
                </>
            }
        />
    );
};

export default InOutChoiceVehicle;
