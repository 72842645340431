import ApiGateway, { ApiResponse, CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { Dealership } from './dealerships.type';

const { REACT_APP_AUTOUSER_URL } = process.env;

interface DirectoryParams {
    q: string;
}
// eslint-disable-next-line import/prefer-default-export
export function getDirectory(params: DirectoryParams) {
    return ApiGateway.get<ApiResponse<Dealership[]>, CustomAxiosResponse<ApiResponse<Dealership[]>>>(
        `${REACT_APP_AUTOUSER_URL}/directory`,
        {
            params,
        },
    );
}

export function getDealershipsDirectory(params: DirectoryParams) {
    return ApiGateway.get<ApiResponse<Dealership[]>, CustomAxiosResponse<ApiResponse<Dealership[]>>>(
        `${REACT_APP_AUTOUSER_URL}/directory/dealerships`,
        {
            params,
        },
    );
}
