import * as dayjs from 'dayjs';
import { Column } from 'primereact/column';
import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';
import usePermissions from '@app/hooks/usePermissions';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { getSort } from '@app/helpers/DataTableHelper';
import { routeTo } from '@app/helpers/RoutesHelper';

import ROUTES from '@app/router/Routes';
import { fetchQuotations } from '@app/store/smart-repair/quotations/smart-repair.thunk';

import CalendarRangeFilter from '@app/partials/content/CalendarRangeFilter';
import DropdownFilter from '@app/partials/content/DropdownFilter';
import FiltersProvider from '@app/partials/content/FiltersProvider';
import HoCDataTable from '@app/partials/content/HoCDataTable';
import InputFilter from '@app/partials/content/InputFilter';
import Label from '@app/partials/content/Label';
import RefreshTable from '@app/partials/content/RefreshTable';
import SearchInputFilter from '@app/partials/content/SearchInputFilter';
import { Filters } from '@app/partials/layout/Filters';

import { Intl } from '@src/_metronic/i18n/I18nProvider';
import { getStateBySlug } from '../Helpers/Status';

const QuotationTable = ({ orderType }) => {
    const { hasPermission } = usePermissions();
    const { orders, totalRecords, filtersContent, loading } = useSelector((state) => state.smartRepair.quotation);
    const { items, start, filters, sortField, sortOrder } = useFilters({
        name: `sr_quotations_${orderType}`,
    });
    const { handleFilter, handlePagination, handleSort, refresh } = useTableHandlers({
        filterStore: `sr_quotations_${orderType}`,
        fetchAction: fetchQuotations,
        paramsInterceptor: (params, filterStore) => {
            const sort = getSort(filterStore?.sortField, filterStore?.sortOrder, 'created', 'desc');

            return {
                ...params,
                start: filterStore.start,
                items: filterStore.items,
                orderType,
                orderBy: `${sort?.field}|${sort?.order}`,
            };
        },
    });

    const selectStatusDropdown = () => (
        <DropdownFilter
            optionLabel="name"
            filterBy="name"
            name="orderStatus"
            options={
                filtersContent?.orderStatus &&
                Object.values(filtersContent.orderStatus).map((elm) => ({
                    name: `${elm.name} ${elm.subName !== null ? `• ${elm.subName}` : ''}`,
                    key: elm.slug,
                }))
            }
            onChange={handleFilter}
        />
    );

    const selectDealershipDropdown = () => (
        <DropdownFilter
            optionLabel="name"
            filterBy="name"
            name="dealershipId"
            options={
                filtersContent?.listDealership &&
                Object.values(filtersContent.listDealership).map((elm) => ({
                    name: elm.refDealershipUsualname,
                    key: elm.id,
                }))
            }
            onChange={handleFilter}
        />
    );

    const inputFilter = (name) => <InputFilter name={name} onChange={handleFilter} />;

    const calendarRangeFilter = (startCalendar, end) => (
        <CalendarRangeFilter start={startCalendar} end={end} onChange={handleFilter} />
    );

    const vehicleBodyTemplate = (order) => (
        <>
            {order.make} {order.model}
            <br />
            {order.registration}
        </>
    );

    const totalBodyTemplate = (order) => (
        <NumberFormat
            value={order?.totalAmount !== null ? order?.totalAmount : '-'}
            thousandSeparator=" "
            decimalSeparator=","
            displayType="text"
            suffix=" €"
        />
    );

    const dealershipBodyTemplate = (order) => {
        const title = order?.dealership?.name || null;
        return <span className={`${title && 'mr-3'}`}>{title}</span>;
    };

    const statusBodyTemplate = (order) => (
        <>
            <Label className="font-weight-bold" variant={getStateBySlug(order)}>
                {order.orderStatus.name}
            </Label>
            <span className={`font-weight-bold text-${getStateBySlug(order)}`}>
                {order.orderStatus.subName !== null ? `• ${order.orderStatus.subName}` : ''}
            </span>
        </>
    );

    const creationDateBodyTemplate = (order) =>
        order?.created ? dayjs(order.created).format('DD/MM/YYYY [à] HH [h] mm') : '-';

    const appointmentBodyTemplate = (order) =>
        order?.appointmentDate ? dayjs(order.appointmentDate).format('DD/MM/YYYY [à] HH [h] mm') : '-';

    const defaultsTemplate = (order) => order.numberOrderRepairs;

    const detailsBodyTemplate = (order) => (
        <Link
            to={routeTo(ROUTES.QUOTATION.PATH, {
                orderType,
                id: order?.id,
            })}
        >
            <Button className="p-2 px-3" variant="primary">
                {order.orderStatus.slug === 'pending' || order.orderStatus.slug === 'new_complement'
                    ? Intl.formatMessage({ id: 'SMART.REPAIR.TABLE.HEAD.TO.DO' })
                    : Intl.formatMessage({ id: 'SMART.REPAIR.TABLE.HEAD.TO.SEE' })}
            </Button>
        </Link>
    );

    const cols = [
        {
            field: 'reference',
            header: Intl.formatMessage({ id: 'SMART.REPAIR.ORDER.TABLE.HEAD.REFERENCE' }),
            style: { width: '10%' },
        },
        {
            field: 'customer',
            header: Intl.formatMessage({ id: 'SMART.REPAIR.ORDER.TABLE.HEAD.CUSTOMER' }),
            style: { width: '10%' },
            bodyStyle: { fontWeight: '600', textTransform: 'capitalize' },
            filter: true,
            filterElement: inputFilter('customer[name]'),
            body: (order) => `${order?.customer?.firstName || ''} ${order?.customer?.lastName || ''}`,
        },
        {
            field: 'vehicleName',
            header: Intl.formatMessage({ id: 'SMART.REPAIR.ORDER.TABLE.HEAD.VEHICLE' }),
            style: { width: '12%' },
            filter: true,
            filterElement: inputFilter('model'),
            body: vehicleBodyTemplate,
        },
        {
            field: 'dealership',
            header: Intl.formatMessage({ id: 'SMART.REPAIR.ORDER.TABLE.HEAD.DEALERSHIP' }),
            style: { width: '15%' },
            filter: true,
            filterElement: selectDealershipDropdown(),
            body: dealershipBodyTemplate,
        },
        {
            field: 'status',
            header: Intl.formatMessage({ id: 'SMART.REPAIR.ORDER.TABLE.HEAD.STATUS' }),
            style: { width: '14%' },
            filter: true,
            filterElement: selectStatusDropdown(),
            body: statusBodyTemplate,
        },
        {
            field: 'totalAmount',
            header: Intl.formatMessage({ id: 'SMART.REPAIR.TABLE.HEAD.PRICE' }),
            style: { width: '8%' },
            filter: false,
            sortable: true,
            body: totalBodyTemplate,
        },
        {
            field: 'created',
            header: Intl.formatMessage({ id: 'SMART.REPAIR.TABLE.HEAD.REQUEST' }),
            style: { width: '10%' },
            filter: false,
            filterElement: calendarRangeFilter('dateStartAppointment', 'dateEndAppointment'),
            sortable: true,
            body: creationDateBodyTemplate,
        },
        {
            field: 'default',
            header: Intl.formatMessage({ id: 'SMART.REPAIR.TABLE.HEAD.DEFAULT' }),
            style: { width: '6%' },
            filter: false,
            bodyStyle: { textAlign: 'center', fontWeight: 'bold', fontSize: '20px' },
            sortable: true,
            body: defaultsTemplate,
        },
        {
            field: 'appointmentDate',
            header: Intl.formatMessage({ id: 'SMART.REPAIR.TABLE.HEAD.APPOINTMENT' }),
            style: { width: '10%' },
            filter: false,
            filterElement: calendarRangeFilter('dateStartOrder', 'dateEndOrder'),
            body: appointmentBodyTemplate,
            sortable: true,
        },
    ];

    if (hasPermission('DEV_DETAILS_ORDER')) {
        cols.push({
            field: 'details',
            header: Intl.formatMessage({ id: 'SMART.REPAIR.TABLE.HEAD.ACTION' }),
            style: { width: '10%', textAlign: 'center' },
            filter: false,
            body: detailsBodyTemplate,
        });
    }

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <Card className="p-4 position-relative">
            <FiltersProvider value={filters}>
                <Filters>
                    <Row>
                        <RefreshTable variant="primary" count={totalRecords[orderType]} onClick={refresh} />
                        <Col lg={5} offset={2}>
                            <SearchInputFilter
                                onChange={handleFilter}
                                name="q"
                                placeholder="Client, référence, concession…"
                            />
                        </Col>
                    </Row>
                </Filters>

                <HoCDataTable
                    value={orders}
                    totalRecords={totalRecords[orderType]}
                    lazy
                    rows={items}
                    start={start}
                    first={start}
                    loading={loading}
                    paginator
                    onFilter={handleFilter}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onPage={handlePagination}
                    onSort={handleSort}
                    paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                    currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                    removableSort
                    filterDisplay="row"
                    rowsPerPageOptions={[25, 50, 100]}
                    emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                >
                    {dynamicColumns}
                </HoCDataTable>
            </FiltersProvider>
        </Card>
    );
};

export default QuotationTable;
