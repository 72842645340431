import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './CustomersSearch.scss';
import { v4 as uuid } from 'uuid';

import { routeTo } from '@app/helpers/RoutesHelper';

import useFilters from '../../../hooks/useFilters';
import SearchInputFilter from '../../../partials/content/SearchInputFilter';
import ROUTES from '../../../router/Routes';

const CustomersSearch = ({ research, className, refresh, redirect, colLg }) => {
    const [keys, setKeys] = useState({
        research: uuid(),
        vehicle: uuid(),
    });
    const Intl = useIntl();
    const history = useHistory();
    const stateCustomers = useSelector((state) => state.customer.customers);
    const { updateFilters } = useFilters({
        name: research,
        path: ROUTES.CUSTOMERS.PATH,
        persisted: false,
        initialize: true,
    });
    const redirectOnChange = () => {
        history.push(routeTo(ROUTES.CUSTOMERS.PATH));
        setKeys({
            research: uuid(),
            vehicle: uuid(),
        });
    };

    const handleResearchChange = ({ research }) => {
        updateFilters({
            filters: {
                research: {
                    value: research?.value?.trim?.(),
                },
                vehicle: {
                    value: null,
                },
            },
            page: 1,
        });
        if (redirect) {
            redirectOnChange();
        }
    };
    const handleVehicleResearchChange = ({ vehicle }) => {
        updateFilters({
            filters: {
                research: {
                    value: null,
                },
                vehicle: {
                    value: vehicle?.value?.trim?.(),
                },
            },
            page: 1,
        });
        if (redirect) {
            redirectOnChange();
        }
    };

    return (
        <Row className={`customers-search ${className}`}>
            <Col lg={colLg}>
                <SearchInputFilter
                    name="research"
                    key={keys.research}
                    placeholder={Intl.formatMessage({ id: 'CUSTOMERS.TABLE.VIEW.RESEARCH.PLACEHOLDER' })}
                    onChange={handleResearchChange}
                    disabled={stateCustomers.loading}
                    refresh={refresh}
                />
            </Col>
            <div className="pl-6 pt-1 font-weight-bold align-self-center">
                <FormattedMessage id="TRANSLATOR.OR" />
            </div>
            <Col lg={colLg}>
                <SearchInputFilter
                    name="vehicle"
                    key={keys.vehicle}
                    placeholder={Intl.formatMessage({ id: 'CUSTOMERS.TABLE.VIEW.VEHICLE_RESEARCH.PLACEHOLDER' })}
                    onChange={handleVehicleResearchChange}
                    disabled={stateCustomers.loading}
                    refresh={refresh}
                />
            </Col>
        </Row>
    );
};

CustomersSearch.defaultProps = {
    research: 'customers_search',
    className: '',
    redirect: false,
    colLg: 4,
};

export default CustomersSearch;
