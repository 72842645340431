import ApiGateway, { ApiResponse, CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { ActionClient, ActionPayload, ActionRelatedTo, ActionResponse } from './base';

const { REACT_APP_LEAD_URL } = process.env;

export default class ConsignContactClient implements ActionClient<ConsignContactPayload, ConsignContactResponse> {
    public async execute(payload: ConsignContactPayload): Promise<ConsignContactResponse> {
        return ApiGateway.post<
            ApiResponse<ConsignContactResponse>,
            CustomAxiosResponse<ApiResponse<ConsignContactResponse>>
        >(`${REACT_APP_LEAD_URL}/action/consign-contact`, payload).then(
            (response: ApiResponse<ConsignContactResponse>) => {
                if (response.result?.error) {
                    throw new Error(response.result?.error);
                }

                return {
                    relatedToLead: response.result.id,
                    relatedToCustomer: response.result.contact.id,
                    ...response.result,
                };
            },
        );
    }

    public readonly action: string = 'consign-contact';

    public readonly relatedTo: ActionRelatedTo = 'lead';

    public reference(payload: ConsignContactPayload): number {
        return payload.lead;
    }
}

export interface ConsignContactPayload extends ActionPayload {
    lead: number;
    result: string;
}

export interface ConsignContactResponse extends ActionResponse {
    id: number;
    contact: {
        id: number;
    };
}
