import classNames from 'classnames';
import { Skeleton } from 'primereact/skeleton';
import React, { useContext, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import '@src/_metronic/_assets/sass/pages/dashboard/dashboard.scss';

import useFetch from '@app/hooks/useFetch';

import { routeTo } from '@app/helpers/RoutesHelper';

import { getIndicators, getVehiclesActionsToDo } from '@app/crud/stock/stock.crud';

import { usePermissions } from '@app/hooks';
import ROUTES from '@app/router/Routes';

import { publishableIndicesAutosphere } from '../../../stock/helpers/StockRules';
import { DashboardContext } from '../../DashboardProvider';

const DashboardStock = ({ className }) => {
    const publishableIndices = publishableIndicesAutosphere();
    const key = uuid();
    const { params } = useContext(DashboardContext);
    const selectedDealerships = params?.dealership;
    const isContextBernard = useSelector((state) => state.auth.userData.context === 'bernard');

    const { fetch, data, loading } = useFetch({
        fetchAction: getIndicators,
        resultInterceptor: (response) => response,
    });
    const { fetch: historyFetch, data: historyData } = useFetch({
        fetchAction: getVehiclesActionsToDo,
        resultInterceptor: (response) => response,
    });
    const { hasPermission } = usePermissions();

    useEffect(() => {
        if (hasPermission('STOCK_LIST_ACTION')) {
            historyFetch({ inactive: 1 });
        }
    }, []);

    useEffect(() => {
        fetch({
            indicators: [
                'autosphere_fr_published',
                'autosphere_fr_not_published',
                'autosphere_fr_vo_published',
                'autosphere_fr_vn_published',
                'online_lead_average_per_vehicle',
                'autosphere_fr_exposition_rate',
            ],
            ...(selectedDealerships?.refDealershipId && { dealershipId: selectedDealerships?.refDealershipId }),
            ...(selectedDealerships && { dealerIds: selectedDealerships.split(',') }),
        });
    }, [selectedDealerships]);

    const getRateColor = (rate) => {
        if (rate < 75) {
            return '#FF4D4D';
        } else if (rate > 80) {
            return '#0ABB87';
        }
        return '#FFB822';
    };

    if (loading) {
        return <DashboardStock.Skeleton />;
    }

    return (
        <div className={classNames('dashboard-stock', className)}>
            <div className="font-size-lg font-weight-bold d-flex align-items-center">
                <i className="dashboard-general-perf__icon las la-2x la-car mr-2" />
                <FormattedMessage id="DASHBOARD.STOCK.TITLE" />
            </div>
            <div className="d-flex flex-wrap">
                <Link to={{ pathname: routeTo(ROUTES.STOCK_VEHICLES.PATH) }} className="flex-fill">
                    <div className="mr-2 mt-3 dashboard-stock__card dashboard-stock__card--clickable">
                        <div className="text-gray-light dashboard-stock__number">{data.autosphere_fr_published}</div>
                        <div className="dashboard-stock__card--clickable__text text-nowrap line-height-md font-size-sm mt-1">
                            <FormattedHTMLMessage id="DASHBOARD.STOCK.PUBLISHED" />
                        </div>
                    </div>
                </Link>
                <Link
                    to={{ pathname: routeTo(ROUTES.STOCK_VEHICLES.PATH), state: { internal_type: { value: 'vo,vd' } } }}
                    className="flex-fill"
                >
                    <div className="mr-2 mt-3 dashboard-stock__card dashboard-stock__card--clickable">
                        <div className="text-primary dashboard-stock__number">{data.autosphere_fr_vo_published}</div>
                        <div className="text-nowrap dashboard-stock__card--clickable__text line-height-md font-size-sm mt-1">
                            <FormattedHTMLMessage id="DASHBOARD.STOCK.VO_PUBLISHED" />
                        </div>
                    </div>
                </Link>
                {!isContextBernard && (
                    <Link
                        to={{
                            pathname: routeTo(ROUTES.STOCK_VEHICLES.PATH),
                            state: { internal_type: { value: 'vn' } },
                        }}
                        className="flex-fill"
                    >
                        <div className="mr-2 mt-3 dashboard-stock__card dashboard-stock__card--clickable">
                            <div className="text-warning-gold dashboard-stock__number">
                                {data.autosphere_fr_vn_published}
                            </div>
                            <div className="text-nowrap dashboard-stock__card--clickable__text line-height-md font-size-sm mt-1">
                                <FormattedHTMLMessage id="DASHBOARD.STOCK.VN_PUBLISHED" />
                            </div>
                        </div>
                    </Link>
                )}
                {/* Disabled temporary waiting stats fix */}
                {/*<Link*/}
                {/*    to={{*/}
                {/*        pathname: routeTo(ROUTES.STOCK_VEHICLES.PATH),*/}
                {/*        state: {*/}
                {/*            publishable_in_indices: {*/}
                {/*                value: `${publishableIndices}:false`,*/}
                {/*                filterName: 'publication autosphere.fr',*/}
                {/*            },*/}
                {/*        },*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <div className="mr-2 mt-3 dashboard-stock__card dashboard-stock__card--clickable">*/}
                {/*        <div className="text-danger dashboard-stock__number">{data.autosphere_fr_not_published}</div>*/}
                {/*        <div className="text-nowrap dashboard-stock__card--clickable__text line-height-md font-size-sm mt-1">*/}
                {/*            <FormattedHTMLMessage id="DASHBOARD.STOCK.UNPUBLISHED_AFR" />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</Link>*/}
                {/*<div className="mr-2 mt-3 dashboard-stock__card">*/}
                {/*    <div className="text-danger dashboard-stock__number">{data.online_lead_average_per_vehicle}</div>*/}
                {/*    <div className="text-nowrap line-height-md font-size-sm mt-1">*/}
                {/*        <FormattedHTMLMessage id="DASHBOARD.STOCK.LEADS" />*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="mr-2 mt-3 dashboard-stock__card ">*/}
                {/*    <div className="text-nowrap font-size-sm line-height-md">*/}
                {/*        <FormattedHTMLMessage id="DASHBOARD.STOCK.EXPO" />*/}
                {/*    </div>*/}
                {/*    <div className="d-flex align-items-end" style={{ marginTop: '-6px' }}>*/}
                {/*        <div*/}
                {/*            className="dashboard-stock__number"*/}
                {/*            style={{ color: getRateColor(data.autosphere_fr_exposition_rate) }}*/}
                {/*        >*/}
                {/*            {data.autosphere_fr_exposition_rate}&nbsp;%*/}
                {/*        </div>*/}
                {/*        <div key={key}>*/}
                {/*            <Doughnut*/}
                {/*                className="ml-1"*/}
                {/*                type="doughnut"*/}
                {/*                width={35}*/}
                {/*                height={35}*/}
                {/*                options={{*/}
                {/*                    plugins: {*/}
                {/*                        datalabels: {*/}
                {/*                            display: false,*/}
                {/*                        },*/}
                {/*                        legend: {*/}
                {/*                            display: false,*/}
                {/*                        },*/}
                {/*                        tooltip: {*/}
                {/*                            enabled: false,*/}
                {/*                        },*/}
                {/*                    },*/}
                {/*                    aspectRatio: 1,*/}
                {/*                    hover: {*/}
                {/*                        mode: null,*/}
                {/*                    },*/}
                {/*                }}*/}
                {/*                data={{*/}
                {/*                    datasets: [*/}
                {/*                        {*/}
                {/*                            data: [*/}
                {/*                                data.autosphere_fr_exposition_rate,*/}
                {/*                                100 - data.autosphere_fr_exposition_rate,*/}
                {/*                            ],*/}
                {/*                            backgroundColor: [*/}
                {/*                                getRateColor(data.autosphere_fr_exposition_rate),*/}
                {/*                                '#cbcbcb',*/}
                {/*                            ],*/}
                {/*                        },*/}
                {/*                    ],*/}
                {/*                }}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {hasPermission('STOCK_LIST_ACTION') && (
                    <div className="flex-fill">
                        <div className="mr-2 mt-3 dashboard-stock__card">
                            <div className="text-gray-light dashboard-stock__number">{historyData.total}</div>
                            <div className="text-nowrap line-height-md font-size-sm mt-1">
                                <FormattedHTMLMessage id="DASHBOARD.STOCK.ACTION" />
                            </div>
                            <Link
                                className="text-primary text-nowrap"
                                to={{
                                    pathname: routeTo(ROUTES.STOCK_ACTION_TO_DO.PATH, {
                                        page: 'history',
                                    }),
                                }}
                            >
                                <u>
                                    <FormattedMessage id="DASHBOARD.STOCK.LINK.HISTORY" />
                                </u>
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

DashboardStock.Skeleton = () => <Skeleton borderRadius="10px" className="flex-grow-1 mt-lg-2 mt-6 p-20" />;
export default DashboardStock;
